import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from '../service/ledger.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';

import { BusinessDate } from '../model/business-date';
import { Observable } from 'rxjs';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-business-date",
  templateUrl: "./business-date.component.html",
  styleUrls: ["./business-date.component.scss"],
})
export class BusinessDateComponent implements OnInit {
  dateFormat: string = "";
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: any[] = [];
  model = new BusinessDate();
  statusList: any[] = [];

  BusinessDateList: any[] = [];
  currentPage = 1;
  itemsPerPage = 10;
  currentItems = 0;
  totalItems = 0;
  searchControl = "";

  constructor(
    private _LedgerService: LedgerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private location: Location,
    private datePipe: DatePipe
  ) {
    businessClosingDate: new FormControl();
    business_Date: new FormControl();
  }

  ngOnInit(): void {
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.dateFormat;
    this.showSaveForm = "saveList";

    //this.getLedgerList();
    this.GetAllStatus();
    this.BusinessDateForm();
    this.GetBusinessDate();
    this.GetBusinessDateList();
  }

  showSaveForm: string = "";
  BusinessDateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      BusinessDate: [""],
      BusinessClosingDate: [""],
      statusId: ["1"],
      ActiveStatus: [""],
      isCOB: [""],
      isMonthCOB: [""],
      Remarks: [""],
    });
  }
  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    //this.refresh();
  }
  GetBusinessDate() {
    this._LedgerService.GetBusinessDate().subscribe((res) => {
      // console.log(222);
      // console.log(res);

      this.list = res;
    });
  }

  GetBusinessDateList() {
    //alert(this.searchControl)
    this._LedgerService
      .GetBusinessDateWithSearchAndPagination(
        this.currentPage,
        this.itemsPerPage,
        this.searchControl
      )
      .subscribe((res: any) => {
        this.BusinessDateList = res.data;
        this.totalItems = res.totalItems;
        this.currentItems = res.currentItems;
      });
  }

  onsubmit() {
    //debugger
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.BusinessDate = this.frmGroup.value.BusinessDate;
    this.model.BusinessClosingDate = this.frmGroup.value.BusinessClosingDate;
    this.model.statusId = this.frmGroup.value.statusId;
    this.model.ActiveStatus = this.frmGroup.value.ActiveStatus;
    //this.model.IsCOB=this.frmGroup.value.IsCOB;
    this.model.isCOB = this.frmGroup.value.isCOB === "true" ? true : false;
    this.model.isMonthCOB =
      this.frmGroup.value.isMonthCOB === "1" ? true : false;
    //this.model.isMonthCOB=this.frmGroup.value.isMonthCOB;
    this.model.Remarks = this.frmGroup.value.Remarks;

    if (this.model.id) {
      this._LedgerService.PostBusinessDate(this.model).subscribe((res) => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = "saveList";
        this.GetBusinessDate();
        this.GetBusinessDateList();

        //this.refresh();
      });
    } else {
      this._LedgerService.PostBusinessDate(this.model).subscribe((res) => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.GetBusinessDate();
        this.GetBusinessDateList();
        this.isEdit = false;
        this.showSaveForm = "saveList";
        //this.refresh();
      });
    }
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = "saveForm";
    console.log(item.business_Date);
    this.frmGroup.patchValue({
      id: item.id,
      BusinessDate: this.datePipe.transform(item.business_Date, "yyyy-MM-dd"),
      BusinessClosingDate: this.datePipe.transform(
        item.businessClosingDate,
        "yyyy-MM-dd"
      ),
      statusId: item.statusId,
      isCOB: item.isCOB,
      isMonthCOB: item.isMonthCOB ? "1" : "0",
      Remarks: item.remarks,
      ActiveStatus: item.activeStatus,
    });

    this.changeDetectorRef.detectChanges();
  }

  delete(Id: any) {
    this._LedgerService.DeleteBusinessDateById(Id).subscribe((res) => {
      this.shared.ShowAlert("Success", "Data deleted successfully", "success");
    });
  }
  GetAllStatus() {
    this._LedgerService.GetAllStatus().subscribe((res) => {
      // console.log('fas');
      // console.log(res);
      this.statusList = res;
    });
  }

  onChangeForShow(value: any) {
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.GetBusinessDate();
    this.GetBusinessDateList();
  }

  onSearch() {
    this.currentPage = 1;
    this.GetBusinessDate();
    this.GetBusinessDateList();
  }

  onPageChange(pageNumber: number) {
    this.currentPage = 1;
    this.itemsPerPage = pageNumber;
    this.GetBusinessDate();
    this.GetBusinessDateList();
  }

  goBack() {
    this.location.back();
  }
}
