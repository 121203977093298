import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.baseUrl;
@Injectable({
  providedIn: "root",
})
export class CustommeraccountService {
  constructor(private http: HttpClient) {}
  getCustomerList(): Observable<any> {
    return this.http.get(apiUrl + "/api/CustomerAccount/GetCustomerByUserName");
  }
  save(model: any) {
    return this.http.post(
      apiUrl + "/api/CustomerAccount/SaveCustomerAccount",
      model
    );
  }

  getCustomerAccountInfoForCustomer(): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CustomerAccount/GetustomerAccountBalanceByCustomerId"
    );
  }

  saveDPSAccount(model: any) {
    return this.http.post(
      apiUrl + "/api/CustomerAccount/CreateDPSAccount",
      model
    );
  }

  getCustomerAccountList(): Observable<any> {
    return this.http.get(apiUrl + "/api/CustomerAccount/GetAllCustomerAccount");
  }
  GetAccountNo1(pId: number) {
    return this.http.get(
      apiUrl + "/api/CustomerAccount/GetAccountNoByProductId?pId=" + pId
    );
  }

  GetUserRole() {
    return this.http.get(apiUrl + "/api/CustomerAccount/GetUserRole");
  }

  CheckCustomerAccount(): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CustomerAccount/GetSavingDepositAccouontByUserId"
    );
  }

  // delete(model:any){
  //   return this.http.delete(apiUrl+'/api/CustomerAccount/DeleteCustomerAccount'+id);
  // }

  getCustomerAccountListByCustomerId(customerId: number): Observable<any> {
    return this.http.get(
      apiUrl +
        "/api/CustomerAccount/GetustomerAccountByCustomerId?customerId=" +
        customerId
    );
  }

  GetAllCustomersByMobileNo(MobileNo: string): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/CustomerAccount/GetAllCustomersByMobileNo?MobileNo=" +
        MobileNo
    );
  }

  GetDeclearationList(): Observable<any> {
    return this.http.get<any>(
      apiUrl + "/api/CustomerAccount/GetMoneyDeclearation"
    );
  }

  GetAllTransactionDeclearationList(): Observable<any> {
    return this.http.get<any>(
      apiUrl + "/api/CustomerAccount/GetAllMoneyDeclearationByAccountId"
    );
  }

  GetRiskGradingList(): Observable<any> {
    return this.http.get<any>(apiUrl + "/api/CustomerAccount/GetRiskGrading");
  }

  SaveDeclearation(model: any) {
    return this.http.post(
      apiUrl + "/api/CustomerAccount/EditMoneyDeclearation",
      model
    );
  }

  GetCustomerAccountDetalis(accId: number) {
    return this.http.get(
      apiUrl + "/api/CustomerAccount/SavingAccountDetails?id=" + accId
    );
  }

  GetMarurityAmountForDPS(
    productCode: any,
    OpeningBalance: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/CustomerAccount/GetMarurityAmountForDPS?productCode=" +
        productCode +
        "&OpeningBalance=" +
        OpeningBalance
    );
  }
}
