import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BatchService } from 'app/services/batch.service';

@Component({
  selector: 'app-search-cob-file',
  templateUrl: './search-cob-file.component.html',
  styleUrls: ['./search-cob-file.component.scss']
})
export class SearchCobFileComponent implements OnInit {

  fileData: any;
  files: { name: string }[] = [
    { name: 'file1.pdf' },
    { name: 'file2.docx' },
    { name: 'file3.txt' }
  ];

  filesFiltered: { name: string }[] = [];

  selectedReportType: number = 0;
  fromDate: Date;
  toDate: Date;
  fileSettings:any;

  constructor(private batchService: BatchService, private http: HttpClient) {
    this.filesFiltered = this.files;
  }

  ngOnInit(): void {
    this.getfileSettings();
  }

  getfileSettings(){
    this.batchService.getfileSettings()
    .subscribe(data => {
      this.fileSettings = data;

     
    });
  }

  search() {
    this.batchService.getFileLogs(this.selectedReportType, this.fromDate, this.toDate)
      .subscribe(data => {
       
        this.fileData = data;
      });

      
  }


  downloadFile(file: any) {
   

    var base = this.batchService.GetBaseUrl();

  
    window.open(base + '/' + file.filePath, '_blank');
  }


  downloadFilefromurl(filePath: string): void {
    this.http.get(filePath, { responseType: 'blob' }).subscribe((response: Blob) => {
      const fileName = this.getFileNameFromPath(filePath);
      this.saveFile(response, fileName);
    }, error => {
      console.error('Error downloading file:', error);
    });
  }

  private saveFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
  }

  private getFileNameFromPath(filePath: string): string {
    // Extracting file name from the file path
    const startIndex = filePath.lastIndexOf('/') + 1;
    const endIndex = filePath.lastIndexOf('.');
    return filePath.substring(startIndex, endIndex);
  }

}
