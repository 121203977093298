import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { InterestProcessService } from '../interest-process.service';
import { SharedService } from 'app/services/shared.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-process-account-balance',
  templateUrl: './process-account-balance.component.html',
  styleUrls: ['./process-account-balance.component.scss']
})
export class ProcessAccountBalanceComponent implements OnInit {
  types: string ;
  fromDate: any = Date;
  toDate: any = Date;
  interval: any;
  dateInfo: any = [];
  businessDate: string;
  selectedType: string = ""; 
  batchDetails: any;


  isBalanceFDRChecked: boolean = false;
  isBalanceDPSChecked: boolean = false;
  isBalanceDepositChecked: boolean = false;
  isBalanceLoanChecked: boolean = false;
  isProvisionFDRChecked: boolean = false;
  isProvisionDPSChecked: boolean = false;
  isProvisionDepositChecked: boolean = false;
  isProvisionLoanChecked: boolean = false;

  isAllProcessed:boolean = false;

  constructor(
    private ledgerService:LedgerService,
    private changeDetectorRef:ChangeDetectorRef,
    private interestProcess:InterestProcessService,
    private shared: SharedService,
    private datePipe: DatePipe
  ) 
  { 

  }

  ngOnInit(): void {    
    this.ledgerService.GetLastAciteBusinessDate().subscribe(res=>{
      this.dateInfo = res;
      this.fromDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
        this.changeDetectorRef.detectChanges();
     })

     this.shared.LastBusinessDate().subscribe(res => {
   
      
      const date = new Date(res.business_Date);
      this.businessDate = this.datePipe.transform(date, 'yyyy-MM-dd');
     })

     
  }


  ProcessAll(){
    this.interestProcess.processInterest(this.types,this.fromDate,this.toDate).subscribe(res => {
      if(res!=null)
      {
        this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
      } 
      else{
        this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
      } 
    })
  }



  ProcessByNameAndType(name:string, type: string){
    if(name == 'Balance'){
      this.interestProcess.ProcessBalance(type,this.fromDate,this.toDate).subscribe(res => {
        if(res!=null)
        {
          this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
        } 
        else{
          this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
        } 
      })
    }
    if(name == 'Provision'){
      this.interestProcess.ProcessProvision(type,this.fromDate,this.toDate).subscribe(res => {
        if(res!=null)
        {
          this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
        } 
        else{
          this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
        } 
      })
    }
    
    
  }



  onChangeCheckbox(name:string, type:string) {
    if(name == 'Balance'){
      if(type == 'FDR'){
        if(this.isBalanceFDRChecked){
          this.isBalanceFDRChecked = false;
        }
        else{
          this.isBalanceFDRChecked = true;
        }
      }
      else if(type == 'DPS'){
        if(this.isBalanceDPSChecked){
          this.isBalanceDPSChecked = false;
        }
        else{
          this.isBalanceDPSChecked = true;
        }
      }
      else if(type == 'Deposit'){
        if(this.isBalanceDepositChecked){
          this.isBalanceDepositChecked = false;
        }
        else{
          this.isBalanceDepositChecked = true;
        }
      }
      else if(type == 'Loan'){
        if(this.isBalanceLoanChecked){
          this.isBalanceLoanChecked = false;
        }
        else{
          this.isBalanceLoanChecked = true;
        }
      }
    }
    if(name == 'Provision'){
      if(type == 'FDR'){
        if(this.isProvisionFDRChecked){
          this.isProvisionFDRChecked = false;
        }
        else{
          this.isProvisionFDRChecked = true;
        }
      }
      else if(type == 'DPS'){
        if(this.isProvisionDPSChecked){
          this.isProvisionDPSChecked = false;
        }
        else{
          this.isProvisionDPSChecked = true;
        }
      }
      else if(type == 'Deposit'){
        if(this.isProvisionDepositChecked){
          this.isProvisionDepositChecked = false;
        }
        else{
          this.isProvisionDepositChecked = true;
        }
      }
      else if(type == 'Loan'){
        if(this.isProvisionLoanChecked){
          this.isProvisionLoanChecked = false;
        }
        else{
          this.isProvisionLoanChecked = true;
        }
      }
    }
    if(name == 'All'){
      if(this.isProvisionLoanChecked){
        this.isBalanceFDRChecked = false;
        this.isBalanceDPSChecked = false;
        this.isBalanceDepositChecked = false;
        this.isBalanceLoanChecked = false;
        this.isProvisionFDRChecked = false;
        this.isProvisionDPSChecked = false;
        this.isProvisionDepositChecked = false;
        this.isProvisionLoanChecked = false;
      }
      else{
        this.isBalanceFDRChecked = true;
        this.isBalanceDPSChecked = true;
        this.isBalanceDepositChecked = true;
        this.isBalanceLoanChecked = true;
        this.isProvisionFDRChecked = true;
        this.isProvisionDPSChecked = true;
        this.isProvisionDepositChecked = true;
        this.isProvisionLoanChecked = true;
      }
      
    }

    this.isAllProcessed = this.isBalanceFDRChecked && this.isBalanceDPSChecked && this.isBalanceDepositChecked && this.isBalanceLoanChecked &&
                          this.isProvisionFDRChecked && this.isProvisionDPSChecked && this.isProvisionDepositChecked && this.isProvisionLoanChecked;
  }

  
}
