import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GroupService } from '../service/group.service';
import { SharedService } from 'app/services/shared.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  list: any[] = [];
  GropupNatureList: any[] = [];
  showSaveForm:string='';

  isEdit: boolean = false;
  frmGroup: FormGroup;
  model: any = {};
  groupList: Observable<any[]>;
  ParentgroupList: Observable<any[]>;

  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";

  constructor(private _service: GroupService,private formBuilder: FormBuilder, private shared:SharedService,) { }

  ngOnInit(): void {
    this.populateForm();
    this.reset();
    this.getGroupList();
    this.GetAccountGroupList();
    this.GetGroupNatureList();
    this.showSaveForm='saveList';
    //this.GetAllAccountGroupSubGroupList();
  }
  
  populateForm(){
    this.frmGroup = this.formBuilder.group({
      id: [null],
      GroupCode: ['',],
      GroupName: ['',],
      NatureId: ['', ],
      ParentGroupId: [0],
      sortOrder: [0],     
    })
  }

  GetAccountGroupList(){
    this._service.GetAccountGroupList().subscribe(res =>{
      this.list = res; 
         
    })
  }

  GetAllAccountGroupSubGroupList()
  {
    this._service.GetAllAccountGroupSubGroupList().subscribe(res =>{
      this.ParentgroupList = res;    
    })
  }

  getGroupList(){
    this._service.getGroupListWithPaginationAndSearch(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.groupList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
      //console.log(res.data)
    })
  }

  GetGroupNatureList()
  {
    this._service.GetGroupNatureList().subscribe(res =>{
      this.GropupNatureList = res;      
    })
  }

  getGroupByNatureId(natureId: number) {
    //debugger
    if(this.isEdit==false)
    {
      this.GetGroupCode(natureId);
    }
   
    this._service.GetAccountMainGroupByNatureId(natureId).subscribe(res => {
      this.ParentgroupList = res;
    
    })
  }

  GetSubGroupCode(gId: any): void {

    if(gId=="" || gId==0)
    {
      this.model.NatureId = this.frmGroup.value.NatureId;
      this.GetGroupCode(this.model.NatureId);
    }
    if(this.isEdit==false)
    {
 
      this._service.GetSubGroupCode(gId).subscribe((res: any) => {
        this.frmGroup.patchValue({
          GroupCode: res.code,
        })
      });
    }
   
  }

  onClickShowSaveForm(value:string){
    this.showSaveForm=value
    this.reset();  
  }

  onsubmit(){
    debugger
    if(this.isEdit){
      this.model.id = this.frmGroup.value.id;
    }
    else{
      this.model.id = 0;
    }   
    this.model.GroupCode = this.frmGroup.value.GroupCode;
    this.model.GroupName = this.frmGroup.value.GroupName;
    this.model.NatureId = this.frmGroup.value.NatureId;
    this.model.ParentGroupId = this.frmGroup.value.ParentGroupId;
    this.model.sortOrder = (this.frmGroup.value.sortOrder===''?0:this.frmGroup.value.sortOrder);
    this._service.SaveAccountGroup(this.model).subscribe(res =>{
      if(this.isEdit){
        this.shared.ShowAlert('Thank you...', 'You updated succesfully!', 'success'); 
      }
      else{
        this.shared.ShowAlert('Thank you...', 'You save succesfully!', 'success');
      }    
      this.GetAccountGroupList(); 
      this.getGroupList();
      this.reset();  
      this.showSaveForm = 'saveList';    
    })
    
  }

  reset(){
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  edit(item: any){
    this.isEdit = true;
    // alert(item.parentGroupId)
    // alert(item.groupCode)
    // alert(item.parentGroup?.groupCode)
    this.getGroupByNatureId(item.natureId)
    this.showSaveForm='saveForm';
    this.frmGroup.patchValue({
      id: item.id,    
      // GroupCode: item.parentGroupId==null?item.groupCode:item.parentGroup?.groupCode,
      // GroupName: item.parentGroupId==null?item.groupName:item.parentGroup?.groupName,
      GroupCode: item.groupCode,
      GroupName: item.groupName,
      NatureId: item.natureId,
      ParentGroupId: item.parentGroupId==null?0:item.parentGroupId,
      sortOrder: item.sortOrder,
    });
  }



  delete(id : any){

     this._service.DeleteaccountGroupById(id).subscribe(res =>{
      this.shared.ShowAlert('Thank you...', 'Delete succesfully!', 'success');    
      this.GetAccountGroupList(); 
      this.getGroupList();
      this.showSaveForm='saveList';
    })
  }



  GetGroupCode(gId: any): void {

    this._service.GetGroupCode(gId).subscribe((res : any)=> {      
      this.frmGroup.patchValue({       
        GroupCode:res.code,       
      })   
    });
  }

  onChangeForShow(value:any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.getGroupList();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.getGroupList();
  }

  onSearch(){
    this.currentPage = 1;
    this.getGroupList();
  }
}
