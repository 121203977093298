import { Component, OnInit } from '@angular/core';
import { LedgerService } from '../service/ledger.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { Holiday } from '../model/holiday';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: any[] = [];

  holidayList: any [] =[];
  currentPage = 1;
  itemsPerPage = 10;
  totalItems = 0;
  currentItems = 0;
  searchControl = "";
  
  model = new Holiday();
  constructor(
    private _LedgerService: LedgerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private shared: SharedService,
    private datePipe: DatePipe,
  ) { 
    date: new FormControl()
  }

  ngOnInit(): void {
    this.showSaveForm = 'saveList';
    this.HolidayForm();
    this.GetHoliday();
    this.GetHolidayList();
  }

  showSaveForm: string = '';
  HolidayForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      Date: [''],
      IsActive: [''],
      HolidayDetails: [''],
      
    });
  }
  onClickShowSaveForm(value: string) {
    this.showSaveForm = value;
    //this.refresh();
  }
  GetHoliday() {
    this._LedgerService.GetHoliday().subscribe(res => {
    

      this.list = res;
    })
  }

  GetHolidayList(){
    this._LedgerService.GetHolidayListWithSearchAndPagination(this.currentPage, this.itemsPerPage, this.searchControl).subscribe((res:any)=>{
      this.holidayList = res.data;
      this.totalItems = res.totalItems;
      this.currentItems = res.currentItems;
    })
  }

  onsubmit() {
    
      // debugger
    if (this.isEdit) {
      this.model.id = this.frmGroup.value.id;
    }
    this.model.Date = this.frmGroup.value.Date;
    this.model.IsActive = this.frmGroup.value.IsActive;
    this.model.HolidayDetails = this.frmGroup.value.HolidayDetails;
    if (this.model.id) {
      this._LedgerService.PostHoliday(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        this.GetHoliday();
        this.GetHolidayList();
        //this.refresh();

      })
    }
    else {
      this._LedgerService.PostHoliday(this.model).subscribe(res => {
        this.shared.ShowAlert("Success", "Save Successfully", "success");
         this.GetHoliday();
         this.GetHolidayList();
        this.isEdit = false;
        this.showSaveForm = 'saveList';
        //this.refresh();
      })
    }
  }

  edit(item: any) {
    this.isEdit = true;
    this.showSaveForm = 'saveForm';
    this.frmGroup.patchValue({
      id: item.id,
      //Date: item.date,
      Date: this.datePipe.transform(item.date, 'yyyy-MM-dd'),
      IsActive: item.isActive,
      HolidayDetails: item.holidayDetails,
     

    })

  }


  delete(Id: any) {

    this._LedgerService.DeleteHolidayById(Id).subscribe(res => {
           this.shared.ShowAlert("Success", "Data deleted successfully", "success");
      
    })
  }

  onChangeForShow(value:any){
    this.currentPage = 1;
    this.itemsPerPage = value;
    this.GetHolidayList();
    this.GetHoliday();
  }

  onPageChange(pageNumber : number){
    this.currentPage = pageNumber;
    this.GetHolidayList();
  }

  onSearch(){
    this.currentPage = 1;
    this.GetHolidayList();
  }
}
