<!-- <br />
<h4 class="card-title page-heading" style="color: black">User Logs</h4>
<hr /> -->
<div class="card">

  <div class="card-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
            <h5 class="card-title page-heading" style="color: black">User Logs</h5>

        </div>
        <div class="col-md-2">


        </div>
        <div class="col-md-1">
          <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
            <i class="icon-arrow-left" style="font-size: 17px;"></i>
        </button>
        </div>
      </div>
      <hr style="height: 10%" />

      <div class="row">
        <label for="username" class="col-md-1">Username</label>
        <div class="col-md-2">
          <input type="text" class="form-control" [(ngModel)]="username">
        </div>
        <label for="fromDate" class="col-md-1">From Date</label>
        <div class="col-md-2">
          <input type="date" class="form-control" [(ngModel)]="fromDate">
        </div>
        <label for="toDate" class="col-md-1">To Date</label>
        <div class="col-md-2">
          <input type="date" class="form-control" [(ngModel)]="toDate">
        </div>
        <div class="col-md-1">
          <a href="javascript:void(0)" class="btn btn-success" (click)="FilterUserLogs()">Find</a>
        </div>
        <div class="col-md-2">
          <a href="javascript:void(0)" class="btn btn-info" (click)="LoadAllLogs()">Load All</a>
        </div>
      </div>
    </div>

  </div>
  <div class="card-body">
    <div class="container-fluid">
      <div class="row" style="overflow: scroll; max-height: 645px;">
        <table style="width: 100%" class="table table-bordered table-striped">
          <thead>
            <tr style="background-color: rgb(180, 179, 179)">
              <th class="text-center">Log Date</th>
              <th class="text-center">Username</th>
              <th class="text-center">Browser Name</th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr *ngFor="let item of logs">
              <td class="text-center">{{ formatDate(item.logTime) }}</td>
              <td class="text-center">{{ item.username }}</td>
              <td class="text-center">{{ item.browserName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>
