import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardCustomService } from "../dashboard-custom.service";
import { PdfService } from "app/pdf-reporting/pdf.service";
import { CustomerService } from "app/customer/service/customer.service";
import * as chartsData from "../../shared/data/chartjs";
import * as cashflowchartsData from "../../shared/data/cashflowdashboard";
import * as Chartist from "chartist";
import { ChartType, ChartEvent } from "ng-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import { LoanStatus } from "app/employee/employee-model/LoanStatus";
import { LayoutService } from "app/shared/services/layout.service";
import { DatePipe } from "@angular/common";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { ChartOptions } from "chart.js";
import { Color, Label, SingleDataSet } from "ng2-charts";

declare var require: any;
const data: any = require("../../shared/data/chartist.json");

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: "app-dps-dashboard",
  templateUrl: "./dps-dashboard.component.html",
  styleUrls: ["./dps-dashboard.component.scss"],
})
export class DpsDashboardComponent implements OnInit {
  waitingPaymentList: number;
  todayReceivable: number;
  todayOverdue: number;
  todayReceivableAmount: number;
  totaPayable: number;
  totalReceivable: number;
  totalRunningLoan: number;
  totalPayable: number;
  todayReceived: number;
  todaySettlement: number;
  advanceAmount: number;
  todaydpsnewaccount: number;
  LoanApplication: number;
  dpsAccount: number;
  todayEncashement: number;
  FdrAccount: number;
  savingAccount: number;
  registerCustomer: number;
  designationNames: any[] = [];
  tenureNames: any[] = [];
  designationChartColors: any[] = [];
  occupationList: any[] = [];
  countList: any[] = [];
  dpsmissedpayment: number;
  dpsmissedpaymentCount: number;
  encashmentalert: number;
  dpsclosedaccount: number;
  todayPayableDPS: number;
  todayTotalMaturity: any;

  customerList: any[] = [];
  accId: any;
  customerLoan: any[] = [];
  allLoanStatus: LoanStatus[] = [];

  title = "ng-chart";
  chart: any = [];

  leadNo: string = null;
  asOnDate: Date = new Date();
  mfsType: number = 0;
  businessDate: any;
  startDate: any;
  EndDate: Date;

  durationNames: any[] = [];
  durationCustomercount: any[] = [];
  durationAccBalance: any[] = [];
  pieChartColorsTe: any[] = [];

  // Doughnut
  public APdoughnutChartLabels = cashflowchartsData.APdoughnutChartLabels;
  public APdoughnutChartData = cashflowchartsData.APdoughnutChartData;
  public APdoughnutChartType = cashflowchartsData.APdoughnutChartType;
  public APdoughnutChartColors = cashflowchartsData.APdoughnutChartColors;
  public APdoughnutChartOptions = cashflowchartsData.APdoughnutChartOptions;

  // Doughnut
  public doughnutChartLabels = chartsData.doughnutChartLabels;
  public doughnutChartData: any[] = [];
  public doughnutChartType = chartsData.doughnutChartType;
  public doughnutChartColors = chartsData.doughnutChartColors;
  public doughnutChartOptions = chartsData.doughnutChartOptions;
  //

  public designationChartLabels = chartsData.designationChartLabels;
  public designationChartType = chartsData.designationChartType;
  public designationChartOptions = chartsData.designationChartOptions;
  //

  public tenureChartColors = chartsData.tenureChartColors;
  public tenureChartType = chartsData.tenureChartType;
  public tenureChartOptions = chartsData.tenureChartOptions;
  ///
  public LoanPaymentdoughnutChartLabels =
    chartsData.LoanPaymentdoughnutChartLabels;
  public LoanPaymentdoughnutChartData: any[] = [];
  public LoanPaymentdoughnutChartType = chartsData.LoanPaymentdoughnutChartType;
  public LoanPaymentdoughnutChartColors =
    chartsData.LoanPaymentdoughnutChartColors;
  public LoanPaymentdoughnutChartOptions =
    chartsData.LoanPaymentdoughnutChartOptions;

  public chartClicked(e: any): void {}

  public chartHovered(e: any): void {}

  /////////////Pie Channel Chart/////////////////////
  public channelName: string[] = [];
  public channelCustomercount: number[] = [];
  public channelAccBalance: number[] = [];
  public pieChartDataCh: number[] = [];
  public pieChartOptionsCh: any = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "top",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataIndex = tooltipItem.index;
          const channelName = data.labels[dataIndex];
          const customerCount = this.channelCustomercount[dataIndex];
          const accBalance = this.channelAccBalance[dataIndex];
          return `${channelName}:Account Count:${customerCount},Balance:${accBalance}`;
        },
      },
      borderColor: "rgba(0, 0, 0, 0)",
      displayColors: false,
      bodyFontSize: 11,
      bodySpacing: 10,
      xPadding: 10,
      yPadding: 10,
    },
    hover: {
      onHover: (event: any, chartElement: any) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
    },
  };
  public pieChartColorsCh: any[] = [
    {
      backgroundColor: [],
    },
  ];
  public pieChartLegendCh = true;

  //// Tenure Pie chart data setup
  public pieChartDataTe: number[] = [];
  public pieChartOptionsTe: any = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const index = tooltipItem.index;
          const durationName = this.durationNames[index];
          const customerCount = this.durationCustomercount[index];
          const accBalance = this.durationAccBalance[index];
          return `${durationName}: Account-Count: ${customerCount}, Balance: ${accBalance}`;
        },
      },

      borderColor: "rgba(0, 0, 0, 0)",
      displayColors: false,
      bodyFontSize: 11,
      bodySpacing: 10,
      xPadding: 10,
      yPadding: 10,
    },
    hover: {
      onHover: (event: any, chartElement: any) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
    },
  };
  public pieChartLegendTe = true;

  /////////////////////Receivable + Payable/////////////////////////////////////
  public barChartLabelsR: string[] = [];
  public barChartDataR: any[] = [];
  public barChartOptionsR: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: { beginAtZero: true },
          scaleLabel: { display: true, labelString: "Date" },
        },
      ],
      yAxes: [
        {
          ticks: { beginAtZero: true },
          scaleLabel: { display: true, labelString: "Amount" },
        },
      ],
    },
    hover: {
      onHover: (event: any, chartElement: any) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const label = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${label}: ${value}`;
        },
      },
    },
  };

  public rand1 = this.getRandomColor();
  public rand2 = this.getRandomColor();

  public barChartColorsR: Color[] = [
    {
      backgroundColor: this.rand1,
      borderColor: "rgba(63,81,181,1)",
    },
    {
      backgroundColor: this.rand2,
      borderColor: "rgba(97,97,97,1)",
    },
  ];
  public barChartLegendR = true;
  public barChartTypeR = "bar";

  ////////////////////////////////////////////

  // barChart for opening date vs closing date for 7 days
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: { beginAtZero: true },
          scaleLabel: { display: true, labelString: "Date" },
        },
      ],
      yAxes: [
        {
          ticks: { beginAtZero: true },
          scaleLabel: { display: true, labelString: "Amount" },
        },
      ],
    },

    hover: {
      onHover: (event: any, chartElement: any) => {
        event.target.style.cursor = chartElement[0] ? "pointer" : "default";
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const label = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${label}: ${value}`;
        },
      },
    },
  };

  public barChartLabels: string[] = [];
  public barChartData: any[] = [];
  public barChartType = chartsData.barChartTypeDPS;
  public barChartLegend = true;

  //public barChartColors = chartsData.barChartColorsDPS;
  public rand3 = this.getRandomColor();
  public rand4 = this.getRandomColor();
  public barChartColors: Color[] = [
    {
      backgroundColor: this.rand3,
      borderColor: "rgba(148,159,177,0.8)",
    },
    {
      backgroundColor: this.rand4,
      borderColor: "rgba(57,47,57,2)",
    },
  ];

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getCurrentBusinessDate();
    this.getCustomerList();
    this.getAccountCountForAdmin();
    this.getSevendaysPayableReceivableAmount();
    this.getSevendaysOpeningClosingAmount();
    this.getTodayOverdue();
    this.getTodayReceived();
    this.getTodaySettlement();
    this.getAdvanceAmount();
    this.getClList();
    this.GetDPSPayableList();
    this.GetTotalPayableList();
    this.getoccupationList();
    this.getTenureList();
    this.getTenureDataAndCountList();
    this.getChannelDataAndCountList();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;
      this.startDate = new Date("1970-01-01");
      this.EndDate = new Date(this.businessDate);
      this.getTotalReceivable();
      this.GetTodayCollectionList();
      this.GetAllDPSMissed();
      this.GetSumofEncashmentalert();
    });
  }

  getCustomerList() {
    this.customerService.getlist().subscribe((res) => {
      this.customerList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  getTenureDataAndCountList() {
    this._serviceDashboard.GetAllTenureAccountCount().subscribe(
      (res: any) => {
        this.durationNames = [];
        this.durationCustomercount = [];
        this.durationAccBalance = [];
        res.forEach((item: any) => {
          this.durationNames.push(item.durationName);
          this.durationCustomercount.push(item.totalCustomerAccount);
          this.durationAccBalance.push(item.totalOpeningBalance);
        });

        this.pieChartDataTe = this.durationCustomercount;
        this.pieChartColorsTe = [
          {
            backgroundColor: this.getRandomColors(this.durationNames.length),
          },
        ];
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  getChannelDataAndCountList() {
    this._serviceDashboard.GetChannelAccountCount().subscribe(
      (res: any) => {
        this.channelName = [];
        this.channelCustomercount = [];
        this.channelAccBalance = [];

        res.forEach((item: any) => {
          this.channelName.push(item.channelName);
          this.channelCustomercount.push(item.totalCustomerAccount);
          this.channelAccBalance.push(item.totalOpeningBalance);
        });

        this.pieChartDataCh = this.channelCustomercount;

        this.pieChartColorsCh[0].backgroundColor = this.getRandomColorsCh(
          this.channelName.length
        );
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  getRandomColorsCh(count: number): string[] {
    return Array.from(
      { length: count },
      () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
    );
  }

  getSevendaysPayableReceivableAmount() {
    this._serviceDashboard
      .GetSevendaysPayReceivableAmountForAdminDash()
      .subscribe(
        (res: any) => {
          const labels = res.map((item) =>
            new Date(item.calculationDate).toLocaleDateString()
          );
          const dataReceive = res.map((item) => item.totalReceiveAmount);
          const dataPay = res.map((item) => item.totalPayableAmount);

          this.barChartLabelsR = labels;
          this.barChartDataR = [
            { data: dataReceive, label: "Receivable" },
            { data: dataPay, label: "Payable" },
          ];
          this.changeDetectorRef.detectChanges();
        },
        (error) => {
          console.error("Error fetching data:", error);
        }
      );
  }

  getSevendaysOpeningClosingAmount() {
    this._serviceDashboard.GetSevendaysOpenCloseAmountForAdminDash().subscribe(
      (res: any) => {
        const labels = res.map((item) =>
          new Date(item.calculationDate).toLocaleDateString()
        );
        const dataOpening = res.map((item) => item.totalOpeningAmount);
        const dataClosing = res.map((item) => item.totalClosingAmount);

        this.barChartLabels = labels;
        this.barChartData = [
          { data: dataOpening, label: "Opening" },
          { data: dataClosing, label: "Closing" },
        ];
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  getoccupationList() {
    this._serviceDashboard.GetDesignationwiseCustomeraccoount().subscribe(
      (res: any) => {
        this.designationNames = [];
        this.occupationList = [];
        this.designationChartColors = [];

        res.forEach((item: any) => {
          this.designationNames.push(item.occupationName);
          this.occupationList.push(item.totalCustomerAccount);
        });

        this.designationChartColors = [
          {
            backgroundColor: this.getRandomColors(this.designationNames.length),
          },
        ];
      },
      (error) => {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    );
  }
  getTenureList() {
    this._serviceDashboard.GetTenureCount().subscribe(
      (res: any) => {
        this.tenureNames = res.openingBalance;
        this.countList = res.count;
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }
  getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getRandomColors(count: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < count; i++) {
      colors.push(this.getRandomColor());
    }
    return colors;
  }
  // GetCashFlowAllForAdminDashBoard() {
  //   this._serviceDashboard.GetCashFlowAllForAdminDashBoard().subscribe({
  //     next: (res: any) => {
  //       this.beginningcashonhand = res.beginningcashonhand;
  //       this.cashgoingon = res.cashgoingon;
  //       this.cashgoingout = res.cashgoingout;
  //       this.profitloss = res.profitloss;
  //       this.endingcashonhand = res.endingcashonhand;
  //     },
  //     error: (e) => {
  //       console.log(e);
  //     },
  //     complete: () => {},
  //   });
  // }

  getAccountCountForAdmin() {
    this._serviceDashboard.GetAccountCountAdminDeshBoard().subscribe({
      next: (res: any) => {
        this.registerCustomer = res.registerCustomer;
        this.savingAccount = res.savingAccount;
        this.FdrAccount = res.fdrAccount;
        this.dpsAccount = res.dpsAccount;
        this.todayEncashement = res.dpsEncashment;
        this.todaydpsnewaccount = res.dpsnewaccount;
        this.LoanApplication = res.loanApplication;

        this.dpsmissedpaymentCount = res.dpsmissedpayment;
        // this.encashmentalert = res.encashmentalert;
        this.dpsclosedaccount = res.dpsclosedaccount;
        this.todayPayableDPS = res.todayPayableDPS;
        this.todayTotalMaturity = res.todayTotalMaturity;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  getTotalReceivable() {
    const formattedStartDate = this.datePipe.transform(
      this.startDate,
      "yyyy-MM-dd"
    );
    const formattedEndDate = this.datePipe.transform(
      this.EndDate,
      "yyyy-MM-dd"
    );

    this.pdfService
      .LoanOutstandingDataByDateRange(formattedStartDate, formattedEndDate)
      .subscribe({
        next: (res: any) => {
          const sumloanAmount = Array.isArray(res)
            ? res.reduce(
                (accumulator, currentObject) =>
                  accumulator + parseFloat(currentObject.loanAmount),
                0
              )
            : 0;

          const sumCollectionAmount: number = Array.isArray(res)
            ? res.reduce(
                (accumulator, currentObject) =>
                  accumulator + parseFloat(currentObject.collectionAmount),
                0
              )
            : 0;

          this.totalReceivable = sumloanAmount - sumCollectionAmount;

          this.doughnutChartData = [this.totalReceivable, 35000, 24000];

          this.changeDetectorRef.detectChanges();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {},
      });
  }

  GetAllDPSMissed() {
    this._serviceDashboard
      .GetAllDPSMissedPaymentByBusinessDate(
        this.businessDate,
        this.businessDate,
        "",
        "",
        ""
      )
      .subscribe({
        next: (res: any) => {
          const sumMissedAmount: number = Array.isArray(res)
            ? res.reduce(
                (accumulator, currentObject) =>
                  accumulator + parseFloat(currentObject.payment),
                0
              )
            : 0;
          this.dpsmissedpayment = sumMissedAmount;
          this.changeDetectorRef.detectChanges();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {},
      });
  }

  GetSumofEncashmentalert() {
    this._serviceDashboard
      .GetAllDPSEncashmentAlertByBusinessDate(this.businessDate, "", "", "")
      .subscribe({
        next: (res: any) => {
          const sumencashmentalertAmount: number = Array.isArray(res)
            ? res.reduce(
                (accumulator, currentObject) =>
                  accumulator + parseFloat(currentObject.maturityAmount),
                0
              )
            : 0;
          this.encashmentalert = sumencashmentalertAmount;
          this.changeDetectorRef.detectChanges();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {},
      });
  }

  // getTotalReceivable() {
  //   this.pdfService
  //     .LoanOutstandingData(this.leadNo, this.asOnDate, this.mfsType)
  //     .subscribe({
  //       next: (res: any) => {

  //         const sumloanAmount = Array.isArray(res)
  //           ? res.reduce(
  //               (accumulator, currentObject) =>
  //                 accumulator + parseFloat(currentObject.loanAmount),
  //               0
  //             )
  //           : 0;

  //         const sumCollectionAmount: number = Array.isArray(res)
  //           ? res.reduce(
  //               (accumulator, currentObject) =>
  //                 accumulator + parseFloat(currentObject.collectionAmount),
  //               0
  //             )
  //           : 0;

  //         this.totalReceivable = sumloanAmount - sumCollectionAmount;

  //         this.changeDetectorRef.detectChanges();
  //       },
  //       error: (e) => {
  //         console.log(e);
  //       },
  //       complete: () => {},
  //     });
  // }
  getTodayOverdue() {
    this.pdfService.LoanOverDueDashboard().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return parseFloat(accumulator) + parseFloat(currentObject.overDue);
          //return currentObject.overDue;
        }, 0);
        this.todayOverdue = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  getTodayReceived() {
    this._serviceDashboard.TodayReceivedList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return (
            parseFloat(accumulator) +
            parseFloat(currentObject.lastCollectionAmount)
          );
          //return currentObject.overDue;
        }, 0);
        this.todayReceived = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }
  getTodaySettlement() {
    this._serviceDashboard.TodaySettlementList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return (
            parseFloat(accumulator) +
            parseFloat(currentObject.lastCollectionAmount)
          );
          //return currentObject.overDue;
        }, 0);
        this.todaySettlement = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }
  getAdvanceAmount() {
    this._serviceDashboard.AdvanceCollectionList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return (
            parseFloat(accumulator) +
            parseFloat(currentObject.lastCollectionAmount)
          );
          //return currentObject.overDue;
        }, 0);
        this.advanceAmount = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  GetDPSPayableList() {
    this.pdfService.GetDPSPayableList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return accumulator + parseFloat(currentObject.collectionAmount);
          //return currentObject.overDue;
        }, 0);
        this.totalPayable = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  OpenPDF(customerId: any) {
    this.pdfService.customerDetailsReport(customerId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  PrintKyc() {
    this.pdfService.OpenKycPdf(this.accId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  GetTodayCollectionList() {
    this._serviceDashboard
      .TodayDPSReceivableList(this.businessDate, this.businessDate, "", "", "")
      .subscribe((res: any[]) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return accumulator + parseFloat(currentObject.collectionAmount);
        }, 0);
        this.todayReceivableAmount = sum;
        this.changeDetectorRef.detectChanges();
      });
  }

  GetTotalPayableList() {
    this._serviceDashboard
      .TotalPayableDPSByTodayBusinessDate(
        "0001-01-01",
        "0001-01-01",
        "",
        "",
        ""
      )
      .subscribe((res: any[]) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return accumulator + parseFloat(currentObject.maturityAmount);
        }, 0);
        this.totaPayable = sum;
        this.changeDetectorRef.detectChanges();
      });
  }

  getClList() {
    this._serviceDashboard.getClProcessListByClTypeWise().subscribe((res) => {
      this.totalRunningLoan = res.length;
      this.changeDetectorRef.detectChanges();
    });
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }

  // Line area chart 2 configuration Starts
  lineArea2: Chart = {
    type: "Line",
    data: data["lineArea2"],
    options: {
      showArea: true,
      fullWidth: true,
      lineSmooth: Chartist.Interpolation.none(),
      axisX: {
        showGrid: false,
      },
      axisY: {
        low: 0,
        scaleMinSpace: 25,
      },
      plugins: [
        ChartistTooltip({
          appendToBody: true,
          pointClass: "ct-point-circle",
        }),
      ],
    },
    responsiveOptions: [
      [
        "screen and (max-width: 768px)",
        {
          axisX: {
            labelInterpolationFnc: (value, index) =>
              index % 2 === 0 ? value : null,
          },
        },
      ],
      [
        "screen and (max-width: 480px)",
        {
          axisX: {
            labelInterpolationFnc: (value, index) =>
              index % 3 === 0 ? value : null,
          },
        },
      ],
    ],
    events: {
      created(data: any): void {
        const defs = data.svg.elem("defs");
        const gradient2 = defs.elem("linearGradient", {
          id: "gradient2",
          x1: 0,
          y1: 1,
          x2: 0,
          y2: 0,
        });
        gradient2
          .elem("stop", {
            offset: 0,
            "stop-opacity": "0.2",
            "stop-color": "transparent",
          })
          .parent()
          .elem("stop", {
            offset: 1,
            "stop-opacity": "0.2",
            "stop-color": "#60AFF0",
          });

        const gradient3 = defs.elem("linearGradient", {
          id: "gradient3",
          x1: 0,
          y1: 1,
          x2: 0,
          y2: 0,
        });
        gradient3
          .elem("stop", {
            offset: 0.3,
            "stop-opacity": "0.2",
            "stop-color": "transparent",
          })
          .parent()
          .elem("stop", {
            offset: 1,
            "stop-opacity": "0.2",
            "stop-color": "#6CD975",
          });
      },
      draw(data: any): void {
        const circleRadius = 4;
        if (data.type === "point") {
          const circle = new Chartist.Svg("circle", {
            cx: data.x,
            cy: data.y,
            r: circleRadius,
            "ct:value": data.value.y,
            "ct:meta": data.meta,
            style: "pointer-events: all !important",
            class: "ct-point-circle",
          });
          data.element.replace(circle);
        } else if (data.type === "label") {
          const dX = data.width / 2 + (25 - data.width);
          data.element.attr({ x: data.element.attr("x") - dX });
        }
      },
    },
  };

  // Line area chart 2 configuration Ends

  // Donut chart configuration Starts
  DonutChart: Chart = {
    type: "Pie",
    data: {
      series: [
        {
          name: "regular",
          className: "ct-done",
          value: 25,
        },
        {
          name: "advance",
          className: "ct-progress",
          value: 25,
        },
        {
          name: "os",
          className: "ct-outstanding",
          value: 25,
        },
        {
          name: "od",
          className: "ct-started",
          value: 25,
        },
      ],
    },
    options: {
      donut: true,
      startAngle: 0,
      labelInterpolationFnc: function (value) {
        var total = data["donutDashboard"].series.reduce(function (
          prev,
          series
        ) {
          return prev + series.value;
        },
        0);
        return total + "%";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: data.element.root().height() / 2,
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Donut chart configuration Starts
  DonutChart1: Chart = {
    type: "Pie",
    data: data["DashboardDonut"],
    options: {
      donut: true,
      donutWidth: 3,
      startAngle: 0,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return "\ue8dd";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy:
                (data.element.root().height() + data.element.height() / 4) / 2,
              class: "ct-label",
              "font-family": "feather",
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Donut chart configuration Starts
  DonutChart2: Chart = {
    type: "Pie",
    data: data["DashboardDonut"],
    options: {
      donut: true,
      donutWidth: 3,
      startAngle: 90,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return "\ue8f8";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy:
                (data.element.root().height() + data.element.height() / 4) / 2,
              class: "ct-label",
              "font-family": "feather",
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Donut chart configuration Starts
  DonutChart3: Chart = {
    type: "Pie",
    data: data["DashboardDonut"],
    options: {
      donut: true,
      donutWidth: 3,
      startAngle: 270,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return "\ue879";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy:
                (data.element.root().height() + data.element.height() / 4) / 2,
              class: "ct-label",
              "font-family": "feather",
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Line chart configuration Starts
  WidgetlineChart: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  WidgetlineChart1: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart_1"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  WidgetlineChart2: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart_2"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  WidgetlineChart3: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart_3"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  // Line chart configuration Ends

  // Line Chart 3 Starts
  lineChart3: Chart = {
    type: "Line",
    data: data["Line3"],
    options: {
      axisX: { showGrid: false },
      axisY: {
        scaleMinSpace: 30,
      },
      fullWidth: true,
      chartPadding: { top: 0, right: 50, bottom: 0, left: 0 },
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px) and (min-width: 381px)",
        {
          axisX: {
            labelInterpolationFnc: function (value, index) {
              return index % 2 === 0 ? value : null;
            },
          },
        },
      ],
      [
        "screen and (max-width: 380px)",
        {
          axisX: {
            labelInterpolationFnc: function (value, index) {
              return index % 3 === 0 ? value : null;
            },
          },
        },
      ],
    ],
  };
  // Line Chart 3 Ends
}
