import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { SharedService } from 'app/services/shared.service';
import { InterestProcessService } from '../interest-process.service';

@Component({
  selector: 'app-monthly-close',
  templateUrl: './monthly-close.component.html',
  styleUrls: ['./monthly-close.component.scss']
})
export class MonthlyCloseComponent implements OnInit {

  year: any = '';
  interval: any;
  dateInfo: any = [];

  constructor(
    private changeDetectorRef:ChangeDetectorRef,
    private shared: SharedService,
    private datePipe: DatePipe,
    private ledgerService:LedgerService,
    private interestProcess:InterestProcessService,
  ) { }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

 
  // InterestProcess(){
  //   const year = this.businessDate.year;
  //   const month = ('0' + (this.businessDate.month + 1)).slice(-2);
  //   const day = ('0' + this.businessDate.day).slice(-2);

  //   this.businessDate = `${year}-${month}-${day}`;
  // }
  InterestProcess(){
    this.interestProcess.MonthlyProcessInterest(this.year,this.month).subscribe(res => {
    
      if(res!=null)
      {
        this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
      } 
      else{
        this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
      } 
    
     
    })
  }

  //year: string = 'select';
  onyearStatusChange(value: string) {
    this.year = value;
  }
  month: string = 'select';
  onmonthStatusChange(value: string) {
    this.month = value;
  }
}
