import { Component, OnInit } from '@angular/core';
import { CustommeraccountService } from '../custommeraccount.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';

@Component({
  selector: 'app-customer-account-details',
  templateUrl: './customer-account-details.component.html',
  styleUrls: ['./customer-account-details.component.scss']
})
export class CustomerAccountDetailsComponent implements OnInit {

  customerAccountlist : any;
  constructor(
    private _service: CustommeraccountService,
    private pdfService:PdfService
  ) { }

  ngOnInit(): void {
    this.getCustomerAccountListInfo();
  }

  getCustomerAccountListInfo() {
    this._service.getCustomerAccountInfoForCustomer().subscribe(res => {
     
      this.customerAccountlist = res; 
    })
    // this._service.getCustomerAccountList().subscribe(res => {
    //   this.customerAccountlist = res; 
    // })
  }

  PrintKyc(id:number){
    this.pdfService.OpenKycPdf(id).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
}
