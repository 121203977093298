
// const baseUrl = "https://localhost:44292"; //suza
//const baseUrl = "https://gq6msjjt-44292.asse.devtunnels.ms"; //suza
//const baseUrl = "https://bn7rs8js-44391.asse.devtunnels.ms";
//const baseUrl = "https://localhost:44391";

const sessionTimeout = 22000000;

//const baseUrl = "http://115.127.99.114:2025";

 //const baseUrl = "https://leancoreapi.opuserp.com";  ////uat_Lean_Core

// const baseUrl = "https://demoapi.opuserp.com";  //// DemoLean_Core

  const baseUrl = "https://lbflapi.opuserp.com";  ////Admin_Dana_Lean_Core
// const baseUrl = "https://opus.lankabangla.com";  ////Live


//const baseUrl = "https://115.127.99.114:444";

//const baseUrl = "http://115.127.99.114:2025";
const eKycUrl="https://gq6msjjt-7062.asse.devtunnels.ms";

export const environment = {
  production: true,
  baseUrl: baseUrl,
  sessionTimeout: sessionTimeout,

  registernurl: baseUrl + "/register",
  userurl: baseUrl + "/users",
  customerurl: baseUrl + "/users",
  eKycUrl:eKycUrl
};
