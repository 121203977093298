  <section>
    <div class="card" style="width: 100%;" *ngIf="showSaveForm==='saveForm'">
  
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <h5 class="card-title" style="color: black">Holiday Info</h5>
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
   (click)="onClickShowSaveForm('saveList')"
   style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">List View</button>
            </div>
  
          </div>
          <hr>
          <form [formGroup]="frmGroup">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="" class="col-sm-4 ">Date</label>
                  <div class="col-sm-8">
                    <input type="date" class="form-control" name="Date" formControlName="Date"
                      placeholder="Account Code">
                  </div>
                </div>
                
                <div class="form-group row">
                    <label for="" class="col-sm-4 ">Holiday Details</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" name="HolidayDetails" formControlName="HolidayDetails"
                        placeholder="HolidayDetails">
                    </div>
                  </div>
                
               
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label for="isCOB" class="col-sm-4 col-form-label">IsActive<span style="color:red; font-size: 15px; ">*</span></label>
                  <div class="col-sm-8">
                    
                  
                    <select class="form-control" formControlName="IsActive" placeholder="IsActive">
                      <option value="select">Select</option>
                      <option value="0">YES</option>
                      <option value="1">NO</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <button data-toggle="tooltip" (click)="onsubmit()" title="Save" type="button" class="btn btn-success"
              style="float:right; margin-top:5px;">Save </button>
  
          </form>
        </div>
  
      </div>
  
  
    </div>
    <div class="card" *ngIf="showSaveForm==='saveList'">
      <div class="card-body">
        <div class="container">
          <div class="row">
            <div class="col">
              <h5 class="card-title" style="color: black">Holiday List</h5>
              <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                (click)="onClickShowSaveForm('saveForm')"
                style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
            </div>
  
          </div>
          <hr>
          <div class="row">
            <div class="col-md-6" style="text-align: left;">
              <select type="number" class="form-control" style="width: 16%;" (change)="onChangeForShow($event.target.value)">
                <option value="5">5</option>
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="col-md-4 form-group" style="text-align: right;">
              <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl" (keyup)="onSearch()">
            </div>
          </div>
          <table id="example" class="table table-bordered table-sm" style="width: 100%;">
            <thead>
              <tr>
                <th>SL No.</th>
                <th>Date</th>
                <th>Is Active</th>
                <th>Holiday Details</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of holidayList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;">
                <td>{{ ( currentPage - 1 ) * itemsPerPage +  i + 1 }}</td>
                <td>{{item.date| date:'yyyy-MM-dd'}}</td>
                <td>{{item.isActive==1?'Yes':'NO'}}</td>
                <td>{{item.holidayDetails}}</td>
                  <td>
                  <button class="btn btn-primary btn-sm" (click)="edit(item)"><i class="fa fa-edit 3x"></i></button>
                  <button [disabled]="isEdit" class="btn btn-danger btn-sm" style="margin-left: 5px;"
                    (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-md-6" style="text-align: left;">
              <span>Showing {{currentItems}} of {{totalItems}} entries</span>
            </div>
            <div class="col-md-6" style="text-align: right;">
              <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
