import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { InterestProcessService } from '../interest-process.service';
import { SharedService } from 'app/services/shared.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-monthly-provision",
  templateUrl: "./monthly-provision.component.html",
  styleUrls: ["./monthly-provision.component.scss"],
})
export class MonthlyProvisionComponent implements OnInit {
  dateFormat: string = "";
  types: string;
  fromDate: any = Date;
  toDate: any = Date;
  interval: any;
  dateInfo: any = [];

  businessDate: string;
  businessYear: number;
  businessMonth: number;
  businessDay: number;

  selectedType: string = "";
  batchDetails: any;
  dailyInterests: any;
  isLoad: boolean = false;

  checkedIds: any = [];

  isBalanceFDRChecked: boolean = false;
  isBalanceDPSChecked: boolean = false;
  isBalanceDepositChecked: boolean = false;
  isBalanceLoanChecked: boolean = false;
  isProvisionFDRChecked: boolean = false;
  isProvisionDPSChecked: boolean = false;
  isProvisionDepositChecked: boolean = false;
  isProvisionLoanChecked: boolean = false;

  isAllProcessed: boolean = false;

  constructor(
    private ledgerService: LedgerService,
    private changeDetectorRef: ChangeDetectorRef,
    private interestProcess: InterestProcessService,
    private shared: SharedService,
    private datePipe: DatePipe,
    private location: Location,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
     var thumb = JSON.parse(localStorage.getItem("thumb"));
     this.dateFormat = thumb.dateFormat;
    this.ledgerService.GetLastAciteBusinessDate().subscribe((res) => {
      this.dateInfo = res;

      this.fromDate = {
        day: parseInt(res.business_Date.split("-")[2].split("T")[0]),
        month: parseInt(res.business_Date.split("-")[1]),
        year: parseInt(res.business_Date.split("-")[0]),
      }; //this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
      this.toDate = {
        day: parseInt(res.business_Date.split("-")[2].split("T")[0]),
        month: parseInt(res.business_Date.split("-")[1]),
        year: parseInt(res.business_Date.split("-")[0]),
      }; //this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
      this.businessDate = res.business_Date;

      this.businessYear = 2024; //parseInt(this.businessDate.toString().split('-')[0]);
      this.businessMonth = 3; //parseInt(this.businessDate.toString().split('-')[1]);
      this.businessDay = 1; //parseInt(this.businessDate.toString().split('-')[2].split('T')[0]);

      this.changeDetectorRef.detectChanges();
    });

    this.shared.LastBusinessDate().subscribe((res) => {
      const date = new Date(res.business_Date);
      this.businessDate = this.datePipe.transform(date, "yyyy-MM-dd");
    });

    this.LoadData();
  }

  LoadData() {
    console.log({ day: 12, month: 6, year: 2024 });
    var startDate =
      this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
    var endDate =
      this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;

    this.interestProcess
      .GetMonthlyProvisionList(startDate, endDate)
      .subscribe((res) => {
        this.dailyInterests = res;
        this.isLoad = true;
      });
  }

  OpenPDF(voucherId) {
    this.pdfService.OpenVoucherPdf(voucherId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  goBack() {
    this.location.back();
  }
}
