import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'app/customer/model/customer.model';
import { NidBasic } from 'app/customer/model/nid-basic.model';
import { NidData } from 'app/customer/model/nid-data.model';
import { NidDetails } from 'app/customer/model/nid-details.model';
import { RegisterService } from 'app/services/register.service';
import { SharedService } from 'app/services/shared.service';
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: 'app-loan-ekyc',
  templateUrl: './loan-ekyc.component.html',
  styleUrls: ['./loan-ekyc.component.scss']
})
export class LoanEkycComponent implements OnInit {
  mobileNumber :string=null;
  isNIDShow: boolean = false;
  isNIDOkShow: boolean = false;
  isNIDData: boolean = false;
  isNIDImageShow: boolean= false;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message='';

  frontPreview = '';
  backPreview = '';
  model = new Customer();
  nid = new NidDetails();

  constructor(private registerService: RegisterService,
              private changeDetectorRef: ChangeDetectorRef,
              private shared:SharedService,
              private route: ActivatedRoute,
              private layoutService: LayoutService,
              private router: Router
              ) { }

  ngOnInit(): void {
    // this.route.params.subscribe(params=>{
    //   this.mobileNumber=params['parameter'];
    //   alert(this.mobileNumber)
   
    // })

    this.layoutService.getCurrentUserName().subscribe(res=>{
      this.mobileNumber = res.phoneNumber;
      }
    );
  }


  
  selectNidFrontPic(event: any): void {
    this.message = '';
    this.frontPreview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.frontPreview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.frontPreview = e.target.result;
          this.changeDetectorRef.detectChanges();
          const div = document.getElementById('imageFrontPreview');
          div.style.width = 90 + '%';
          div.style.height = 90 + '%';
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }


  selectNidBackPic(event: any): void {
    this.message = '';
    this.backPreview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.backPreview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.backPreview = e.target.result;
          this.changeDetectorRef.detectChanges();
          const div = document.getElementById('imageBackPreview');
          div.style.width = 90 + '%';
          div.style.height = 90 + '%';
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  // onNidSubmit(form) {
  //   if (this.frontPreview != '' && this.backPreview != '') {
  //     this.model.frontImage=this.frontPreview;
  //     this.model.backImage=this.backPreview;
  //     this.model.NidNo=this.model.NidNo;
  //     this.model.DateOfBirth=this.model.DateOfBirth;
  //     this.model.isConfirm=false;
  //     this.model.MobileNo=this.mobileNumber;
  //     this.registerService.verifyNID(this.model).subscribe((res) => {
  //       // alert(res.status)
  //       // console.log(res)
  //       if (res.status === 'ok') {

  //         this.nid = res.nid.data.nid;
  //         this.shared.ShowAlert("Verified", res.message, "success");
  //         this.isNIDOkShow = true;
  //         this.isNIDData = true;
  //         this.isNIDImageShow =true;
  //         // this.nid = res.nid;
  //         // console.log("res.nid");
  //         //alert(this.nid.nationalIdNumber);
  //         this.changeDetectorRef.detectChanges();
  //       }
  //       else if(res.status === 'exist'){
  //         this.shared.ShowAlert("Exist", res.message, "warning");
  //       }
  //       else {
  //         this.shared.ShowAlert("Wrong", "Invalid NID.", "warning");
  //       }
  //     },
  //     (error) => {
  //       //console.error('Error submitting data:', error);
  //       this.isNIDShow = false;
  //       this.changeDetectorRef.detectChanges();
  //     });
  //   }
  //   else{
  //     this.shared.ShowAlert("Not found", "No Image Found", "warning");
  //   }

  //   console.log(this.nid);
  // }
  onNidSubmit(form) {
    
       
      this.model.NidNo=this.model.NidNo;
      this.model.DateOfBirth=this.model.DateOfBirth;
      this.model.isConfirm=false;
      this.model.MobileNo=this.mobileNumber;
      this.registerService.verifyNID(this.model).subscribe((res) => {
        //alert(res.status)
        //console.log(res)
        if (res.status === 'ok') {

          this.nid = res.nid.data.nid;
          this.shared.ShowAlert("Verified", res.message, "success");
          this.isNIDOkShow = true;
          this.isNIDData = true;
          this.isNIDImageShow =true;

          // this.nid = res.nid;
          // console.log("res.nid");
          //alert(this.nid.nationalIdNumber);
          this.changeDetectorRef.detectChanges();
        }
        else if(res.status === 'exist'){
          this.shared.ShowAlert("Exist", res.message, "warning");
        }
         
      },
      (error) => {
        //console.error('Error submitting data:', error);
        this.isNIDShow = false;
        this.changeDetectorRef.detectChanges();
      });
     

  
  }


  // onNidOK() {
  //   if (this.frontPreview != '' && this.backPreview != '') {
  //   this.isNIDShow = false;
  //   this.model.frontImage=this.frontPreview;
  //   this.model.backImage=this.backPreview;
  //   this.model.NidNo=this.model.NidNo;
  //   this.model.DateOfBirth=this.model.DateOfBirth;
  //   this.model.isConfirm=true;
  //   this.model.MobileNo=this.mobileNumber;
  //   this.registerService.verifyNID(this.model).subscribe((res) => {
  //     debugger;
  //     if (res.status === 'ok' || res.status === 'exist') {
  //        alert(res.status)
  //        this.router.navigate(['/e-kyc/customercreate/'+this.mobileNumber+'/'+this.model.NidNo]);
  //        //this.router.navigate(['/pages/registerInfo']);
  //      }
  //       else{
  //        alert(2)}
  //     //this.router.navigate(['/e-kyc/customercreate/'+this.mobileNumber+'/'+this.model.NidNo]);
  //   })
  // }
  //    else{
  //      this.shared.ShowAlert("Not found", "No Image Found", "warning");
  //    }
  // }


  onNidOK() {
    this.isNIDShow = false;
    this.model.frontImage=this.frontPreview;
    this.model.backImage=this.backPreview;
    this.model.NidNo=this.model.NidNo;
    this.model.DateOfBirth=this.model.DateOfBirth;
    this.model.isConfirm=true;
    this.model.MobileNo=this.mobileNumber;
    this.registerService.verifyNID(this.model).subscribe((res) => {

      if (res.status === 'ok' || res.status === 'exist') {
       
        this.router.navigate(['/e-kyc/customercreate/'+this.mobileNumber+'/'+this.model.NidNo]);
        //this.router.navigate(['/pages/registerInfo']);
      }
      else{
        alert(2)}
    })
  }



}
