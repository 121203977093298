import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { Observable, interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  private baseUrl = environment.baseUrl;
  private intervalSubscription: Subscription;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { 
    this.checkAndRenewToken();

    this.intervalSubscription = interval(60000).subscribe(() => {
      this.checkAndRenewToken();
      // console.log(111);
    });
  }

  ngOnDestroy() {
    // Unsubscribe from the interval to avoid memory leaks when the service is destroyed
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  checkTokenExpiration(){
    const token = localStorage.getItem('token');
    // console.log(token);
    //var decodeToken = this.jwtHelper.decodeToken(token);
    //var expTime = new Date(decodeToken.exp * 1000);
    //console.log('ex: ' + expTime);
    
    
    if (token) {
      const isTokenExpired = this.jwtHelper.isTokenExpired(token);
      
      return isTokenExpired;
    }
  }


  generateNewToken():Observable<any>{
    const token = localStorage.getItem('token');

    return this.http.get(this.baseUrl + '/GenerateNewToken?token='+ token );
  }



  private checkAndRenewToken() {
    var isExpired = this.checkTokenExpiration();
  
    if (isExpired) {
      this.generateNewToken().subscribe(res => {
        localStorage.setItem('token', res);
      });
    }
  }

  
}
