import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: 'app-api-log-history',
  templateUrl: './api-log-history.component.html',
  styleUrls: ['./api-log-history.component.scss']
})
export class ApiLogHistoryComponent implements OnInit {
  logs: any;
  username: string = '';
  businessDate: any;
  fromDate: string = new Date().toISOString().split('T')[0];
  toDate: string = new Date().toISOString().split('T')[0];

  constructor(private login: LoginService, private layoutService: LayoutService) { }

  ngOnInit(): void {
    // this.GetApiLogs();
    // this.getCurrentBusinessDate();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;

      this.fromDate = new Date(this.businessDate).toISOString().split('T')[0];
      this.toDate = new Date(this.businessDate).toISOString().split('T')[0];
    })
  }

  GetApiLogs() {
    this.login.GetApiLogs().subscribe(res => {
      this.logs = res;
     
    })
  }

  FilterApiLogs() {
    this.login.FilterApiLogs(this.username, this.fromDate, this.toDate).subscribe(res => {
      this.logs = res;
      
    })
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  LoadAllLogs(){
    this.GetApiLogs();
  }

}
