import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-b-kash-payment-success',
  templateUrl: './b-kash-payment-success.component.html',
  styleUrls: ['./b-kash-payment-success.component.scss']
})
export class BKashPaymentSuccessComponent implements OnInit {
  paymentID: string;
  paymentInfo:any = [];
  paymentStatus: any = '';

  constructor(private route: ActivatedRoute, private paymentService: PaymentService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.paymentID = params['paymentID'];
      
      this.getPaymentInfo(this.paymentID);
    });
  }



  getPaymentInfo(paymentId: string) {
    this.paymentService.getPaymentInfo(paymentId).subscribe(
      response => {
        this.paymentInfo = response;
   
    
        if(this.paymentInfo.status == "Pending"){
          this.executePayment(this.paymentID);
        
        }

      },
      error => {
        console.error('Error:', error);
      }
    );
  }


  executePayment(paymentId: string) {
    this.paymentService.executePayment(paymentId).subscribe(
      response => {
        this.paymentStatus = response;
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

}
