<br />
<div class="card">
  <div class="card-header">
    <h4>Password Policy</h4>
    <hr>
  </div>
  <div class="card-body">
    <form #passwordPolicyForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="isLowerCase" class="col-md-3">
              Include Lowercase:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="Is the password contains lowercase?">
                <i class="fa fa-info-circle"></i>
              </button>
            </label>
            <div class="col-md-3">
              <input type="hidden" [(ngModel)]="passwordPolicy.Id">
              <input type="checkbox" class="form-control" id="isLowerCase" name="isLowerCase"
                [(ngModel)]="passwordPolicy.isLowerCase" />
            </div>
            <label for="isUpperCase" class="col-md-3">Include Uppercase:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="Is the password contains uppercase?">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="checkbox" class="form-control" id="isUpperCase" name="isUpperCase"
                [(ngModel)]="passwordPolicy.isUpperCase" />
            </div>
          </div>

          <div class="form-group row">
            <label for="isNumber" class="col-md-3">Include Numbers:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="Is the password contains numbers?">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="checkbox" class="form-control" id="isNumber" name="isNumber"
                [(ngModel)]="passwordPolicy.isNumber" />
            </div>
            <label for="isSymbol" class="col-md-3">Include Symbols:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="Is the password contains symbol?">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="checkbox" class="form-control" id="isSymbol" name="isSymbol"
                [(ngModel)]="passwordPolicy.isSymbol" />
            </div>
          </div>

          <div class="form-group row">
            <label for="changePassAfterExpiry" class="col-md-3">Change Pass After Expiry:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="Show the change password dialog after it expired.">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="checkbox" class="form-control" style="text-align: right;" id="changePassAfterExpiry"
                name="changePassAfterExpiry" [(ngModel)]="passwordPolicy.changePassAfterExpiry" />
            </div>

            <label for="isTwoFactorEnabled" class="col-md-3">Two Factor Authorization:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="Is the users enabled 2FA">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="checkbox" class="form-control" style="text-align: right;" id="isTwoFactorEnabled"
                name="isTwoFactorEnabled" [(ngModel)]="passwordPolicy.isTwoFactorEnabled" />
            </div>
          </div>

          <div class="form-group row">
            <label for="minLength" class="col-md-3">Min Length:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="What is the min length of the password?">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="range" class="form-range" id="minLength" name="minLength"
                [(ngModel)]="passwordPolicy.minLength" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.minLength }}</span>
            </div>
            <label for="maxLength" class="col-md-3">Max Length:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="What is the max lenght of the password?">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="range" class="form-range" id="maxLength" name="maxLength"
                [(ngModel)]="passwordPolicy.maxLength" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.maxLength }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label for="specialCharacterMin" class="col-md-3">Special Character Min:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top"
                ngbTooltip="What is the min lenght of special character?">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="range" class="form-range" id="specialCharacterMin" name="specialCharacterMin"
                [(ngModel)]="passwordPolicy.specialCharacterMin" [disabled]="!passwordPolicy.isSymbol" min="0" max="10"
                step="1">
              <span *ngIf="passwordPolicy.isSymbol" class="form-text">{{ passwordPolicy.specialCharacterMin }}</span>
              <span *ngIf="!passwordPolicy.isSymbol" class="form-text">0</span>
            </div>
            <label for="passwordHistoryPeriod" class="col-md-3">Password History Period:
              <button type="button" class="btn btn-sm btn-outline-light" placement="top" ngbTooltip="Information">
                <i class="fa fa-info-circle"></i>
              </button></label>
            <div class="col-md-3">
              <input type="range" class="form-range" id="passwordHistoryPeriod" name="passwordHistoryPeriod"
                [(ngModel)]="passwordPolicy.passwordHistoryPeriod" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.passwordHistoryPeriod }}</span>
            </div>
          </div>
        </div>
      </div>



      <div class="form-group row">
        <label for="passwordReuseMax" class="col-md-3">Password Reuse Max:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="How many times a password can reuse?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="passwordReuseMax" name="passwordReuseMax"
                [(ngModel)]="passwordPolicy.passwordReuseMax" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.passwordReuseMax }}</span>
        </div>
        <label for="failedLoginAttemptsMax" class="col-md-3">Failed Login Attempts Max:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="What is the max login attempts?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="failedLoginAttemptsMax" name="failedLoginAttemptsMax"
                [(ngModel)]="passwordPolicy.failedLoginAttemptsMax" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.failedLoginAttemptsMax }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="expirationDays" class="col-md-3">Expiration Days:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="What is the expiration of the password (in days)?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="expirationDays" name="expirationDays"
                [(ngModel)]="passwordPolicy.expirationDays" min="0" max="90"
                step="1">
                <span class="form-text">{{ passwordPolicy.expirationDays }}</span>
        </div>
        <label for="expieryPriorAlert" class="col-md-3">Expiry Prior Alert:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="Before how many days user will get warning to change password?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="expieryPriorAlert" name="expieryPriorAlert"
                [(ngModel)]="passwordPolicy.expieryPriorAlert" min="0" max="60"
                step="1">
                <span class="form-text">{{ passwordPolicy.expieryPriorAlert }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="capitalLetterMin" class="col-md-3">Capital Letter Min:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="How many capital letter should the password contain?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="capitalLetterMin" name="capitalLetterMin"
          [(ngModel)]="passwordPolicy.capitalLetterMin" [disabled]="!passwordPolicy.isUpperCase" min="0" max="30"
          step="1">
          <span *ngIf="passwordPolicy.isUpperCase" class="form-text">{{ passwordPolicy.capitalLetterMin }}</span>
              <span *ngIf="!passwordPolicy.isUpperCase" class="form-text">0</span>
        </div>
        <label for="smallLetterMin" class="col-md-3">Small Letter Min:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="How many small letter should the password contain?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="smallLetterMin" name="smallLetterMin"
          [(ngModel)]="passwordPolicy.smallLetterMin" [disabled]="!passwordPolicy.isLowerCase" min="0" max="30"
          step="1">
          <span *ngIf="passwordPolicy.isLowerCase" class="form-text">{{ passwordPolicy.smallLetterMin }}</span>
              <span *ngIf="!passwordPolicy.isLowerCase" class="form-text">0</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="numericCharacterMin" class="col-md-3">Numeric Character Min:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="How many numbers should the password contain?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="numericCharacterMin" name="numericCharacterMin"
          [(ngModel)]="passwordPolicy.numericCharacterMin" [disabled]="!passwordPolicy.isNumber" min="0" max="30"
          step="1">
          <span *ngIf="passwordPolicy.isNumber" class="form-text">{{ passwordPolicy.numericCharacterMin }}</span>
              <span *ngIf="!passwordPolicy.isNumber" class="form-text">0</span>
        </div>
        <label for="consequtiveUsePass" class="col-md-3">Consecutive Use Pass:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top" ngbTooltip="Information">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="consequtiveUsePass" name="consequtiveUsePass"
                [(ngModel)]="passwordPolicy.consequtiveUsePass" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.consequtiveUsePass }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label for="samePassRepeat" class="col-md-3">Same Pass Repeat:
          <button type="button" class="btn btn-sm btn-outline-light" placement="top"
            ngbTooltip="How many times a password can be repeated?">
            <i class="fa fa-info-circle"></i>
          </button></label>
        <div class="col-md-3">
          <input type="range" class="form-range" id="samePassRepeat" name="samePassRepeat"
                [(ngModel)]="passwordPolicy.samePassRepeat" min="0" max="30"
                step="1">
                <span class="form-text">{{ passwordPolicy.samePassRepeat }}</span>
        </div>

      </div>



      <hr />
      <button type="button" (click)="onSubmit()" class="btn btn-success" [disabled]="passwordPolicyForm.invalid">
        Submit
      </button>
      <button type="button" class="btn btn-info ml-1" (click)="OpenPDF()">
        PDF
      </button>
    </form>
  </div>
</div>
