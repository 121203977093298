<div class="card w-100">
    <div class="card-body">
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <h5 class="card-title text-dark">Today's Receivable DPS List</h5>
                </div>
                <div class="col-auto">
                    <button class="btn btn-secondary" title="Go Back" (click)="goBack()">
                        <i class="icon-arrow-left" style="font-size: 17px;"></i>
                    </button>
                </div>
            </div>
            <hr>
            <div class="row mb-1">
                <div class="col-md-4">
                    <label for="fromDate">From Date</label>
                    <input type="date" id="fromDate" class="form-control" [(ngModel)]="startDate"
                        (ngModelChange)="onDateChange()" placeholder="Select From Date">
                </div>
                <div class="col-md-4">
                    <label for="toDate">To Date</label>
                    <input type="date" id="toDate" class="form-control" [(ngModel)]="EndDate"
                        (ngModelChange)="onDateChange()" placeholder="Select To Date">
                </div>
                <div class="col-4">
                    <label>Filter Type</label>
                    <select class="form-control" [(ngModel)]="typeId" (change)="onFilterTypeChange($event.target.value)"
                        (change)="SearchByParameter()">
                        <option value="0"> Select</option>
                        <option value="1">Customer Name</option>
                        <option value="3"> Mobile No</option>
                        <option value="4"> Account No</option>
                    </select>
                </div>
            </div>

            <div class="card mb-1">
                <div class="row g-4">
                    <div class="col-md-4" *ngIf="showCustomerNameField">
                        <label>Customer Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="customername"
                                (keyup.enter)="getCustomerDPSAccount()" placeholder="Search by customer name ...">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(1)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="showAccountField">
                        <label>Account No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="accountNo"
                                placeholder="Search by Account No...">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(4)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-md-4" *ngIf="showMobileNumberField">
                        <label>Mobile No</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="mobileNumber"
                                placeholder="Search by mobile number...">
                            <button class="btn btn-danger" (click)="deleteFilterTypeFields(3)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mb-1">
                <button class="btn btn-success  mr-2" (click)="GetAllReceivableDPS()">Search</button>
                <button class="btn btn-info" (click)="refreshFilter()">Refresh</button>
                
            </div>

            
        </div>
        <div class="card-body p-0" style="overflow: auto; max-height: 600px;">
            <table class="table table-striped table-bordered">
            <thead style="position: sticky; top:-25px; z-index: 1; background-color: #EEF5E9">
                    <tr>
                        <th>Sl.</th>
                        <th>Account No</th>
                        <th>Account Name</th>
                        <th>Mobile No</th>
                        <th class="text-end">Opening Balance</th>
                        <th>Collection Date</th>
                        <th class="text-end">Collection Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of receivablepsList; let i=index">
                        <td>{{i+1}}.</td>
                        <td>{{item.accountNo}}</td>
                        <td>{{item.accountTitle}}</td>
                        <td>{{item.mobileNo}}</td>
                        <td class="text-end">{{item.openingBalance | number}}</td>
                        <td>{{item.collectionDate | date: 'yyyy-MM-dd'}}</td>
                        <td class="text-end">{{item.collectionAmount | number}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>