import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from 'app/services/shared.service';
import { ScoreQueAnsService } from '../services/score-que-ans.service';

@Component({
  selector: 'app-score-question',
  templateUrl: './score-question.component.html',
  styleUrls: ['./score-question.component.scss']
})
export class ScoreQuestionComponent implements OnInit {

  questionList:any []=[];
  isEdit:boolean=false;
  queFromGrp: FormGroup;

  queModel:any={};

  showSaveForm:string='';

  parentNameId:any;

  constructor(private formBuilder: FormBuilder,
              private shared:SharedService,
              private _questionService:ScoreQueAnsService) { }

  ngOnInit(): void {
    this.questionForm();
    this.getQuesList();
    this.showSaveForm='saveForm';
  }

  questionForm(){
    this.queFromGrp=this.formBuilder.group({
      id:[0],
      question:[''],
      type:[''],
      totalMarks:[0],
      parentId:[0],
      sortOrder:[''],
      status:['']
    })
  }

  refresh() {
    this.isEdit = false;
    this.questionForm();
    this.queModel = {};
  }

  onClickShowSaveForm(value:string){
    this.showSaveForm=value;
    this.refresh();
  }

  onParentChange(value:any){
    if(value != 0){
    this.parentNameId=value;
    }
  }

  getQuesList(){
    this._questionService.getAllQuestionList().subscribe(res=>{
      this.questionList=res;
    })
  }

  onSubmit() {
    if (this.isEdit) {
      this.queModel.id = this.queFromGrp.value.id;
    }
    this.queModel.question = this.queFromGrp.value.question;
    this.queModel.type = this.queFromGrp.value.type;
    this.queModel.totalMarks = this.queFromGrp.value.totalMarks;
    this.queModel.parentId = this.queFromGrp.value.parentId;
    this.queModel.sortOrder = this.queFromGrp.value.sortOrder;
    this.queModel.status = this.queFromGrp.value.status;

    this._questionService.saveQuestion(this.queModel).subscribe(res => {
     
      this.shared.ShowAlert("Success","Save Successfully","success");
      this.getQuesList();      
      // this.showSaveForm='ListView';
      this.showSaveForm='saveForm';
      this.refresh();
    })
  }


}
