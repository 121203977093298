import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { DatePipe, Location } from "@angular/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from 'app/services/shared.service';
@Component({
  selector: "app-api-log-history",
  templateUrl: "./api-log-history.component.html",
  styleUrls: ["./api-log-history.component.scss"],
})
export class ApiLogHistoryComponent implements OnInit {
  modalRef: NgbModalRef | undefined;
  dateFormat: string = "";
  logs: any;
  username: string = "";
  businessDate: any;
  fromDate: string = new Date().toISOString().split("T")[0];
  toDate: string = new Date().toISOString().split("T")[0];
  requestbody: string;
  copied: boolean = false;
  constructor(
    private login: LoginService,
    private location: Location,
    private modalService: NgbModal,
    private layoutService: LayoutService,
    private shared: SharedService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.dateFormat;
    // this.GetApiLogs();
    // this.getCurrentBusinessDate();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;

      this.fromDate = new Date(this.businessDate).toISOString().split("T")[0];
      this.toDate = new Date(this.businessDate).toISOString().split("T")[0];
      this.changeDetectorRef.detectChanges();
    });
  }

  GetApiLogs() {
    this.login.GetApiLogs().subscribe((res) => {
      this.logs = res;
    });
  }

  FilterApiLogs() {
    this.login
      .FilterApiLogs(this.username, this.fromDate, this.toDate)
      .subscribe((res) => {
        this.logs = res;
        this.changeDetectorRef.detectChanges();
      });
  }

  OpenModal(requestBodyModal, item: any) {
    this.login.GetApiLogsReqBodyById(item.id).subscribe((res: any) => {
      this.requestbody = JSON.parse(res.reqBody);
      this.changeDetectorRef.detectChanges();
    });

    this.modalRef = this.modalService.open(requestBodyModal, {
      backdrop: "static",
      keyboard: false,
    });
  }

  copyToClipboard() {
    const jsonStr = JSON.stringify(this.requestbody, null, 2);
    navigator.clipboard
      .writeText(jsonStr)
      .then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1400);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  }

  // copyToClipboard() {
  //   const jsonStr = JSON.stringify(this.requestbody, null, 2);
  //   navigator.clipboard
  //     .writeText(jsonStr)
  //     .then(() => {
  //       this.shared.ShowAlert(
  //         "Success",
  //         "JSON copied to clipboard!",
  //         "success"
  //       );
  //     })
  //     .catch((err) => {
  //        this.shared.ShowAlert("Alert", "Failed to copy JSON.", "warning");
  //     });
  // }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  LoadAllLogs() {
    this.GetApiLogs();
  }
  goBack() {
    this.location.back();
  }

  formatDateFull(date: Date): string {
    const pad = (num: number) => String(num).padStart(2, "0");
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
