import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/services/shared.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-create-action-event',
  templateUrl: './create-action-event.component.html',
  styleUrls: ['./create-action-event.component.scss']
})
export class CreateActionEventComponent implements OnInit {
  roleList: any[] = [];
  eventList: any[] = [];
  selectedRoleId: string | null = null;

  constructor(
    private _userService: UserService,
    private shared: SharedService,
  ) {
  }

  ngOnInit(): void {
    this.GetALlRoles();
  }

  GetALlRoles() {
    this._userService
      .GetRoleList()
      .subscribe((res: any) => {
        this.roleList = res;

     
      });
  }

  onRoleChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedRoleId = selectElement.value;
    this._userService
      .GetActionsByRoleId(this.selectedRoleId)
      .subscribe((res: any) => {
        this.eventList = res;

      
      });
  }

  onCheckboxChange(item:any){
    item.status = item.status == true ? false : true;

  
  }


  onSubmit() {
    this._userService.SaveRoleEvents(this.eventList).subscribe(
      (res: any) => {
        if(res > 0){
          this.shared.ShowAlert("Success", "Updated Successfully", "success");
        }
        else{
          this.shared.ShowAlert("Warning", "Failed to Update.", "warning");
        }
      },
      (error) => {
        this.shared.ShowAlert("Warning", "Failed to Update.", "warning");
      }
    );
  }

}
