import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { CustomerService } from 'app/customer/service/customer.service';
import * as chartsData from '../../shared/data/chartjs';
import * as cashflowchartsData from '../../shared/data/cashflowdashboard';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from "ng-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import { LoanStatus } from 'app/employee/employee-model/LoanStatus';
import { LayoutService } from 'app/shared/services/layout.service';
import { DatePipe } from '@angular/common';

declare var require: any;
const data: any = require('../../shared/data/chartist.json');


export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: "app-new-admin-dashboard",
  templateUrl: "./new-admin-dashboard.component.html",
  styleUrls: ["./new-admin-dashboard.component.scss"],
})
export class NewAdminDashboardComponent implements OnInit {

  beginningcashonhand:number;
  cashgoingon:number;
  cashgoingout:number;
  profitloss:number;
  endingcashonhand:number;



  waitingApplication: number;
  forwardedApplication: number;
  approvedLoan: number;
  rejectedLoan: number;
  waitingForDisburse: number;
  disbursmentList: number;
  finalDisbursList: number;
  waitingPaymentList: number;
  todayReceivable: number;
  todayOverdue: number;
  todayReceivableAmount: number;
  totalReceivable: number;
  totalRunningLoan: number;
  totalPayable: number;
  todayReceived: number;
  todaySettlement: number;
  advanceAmount: number;

  LoanApplication: number;
  dpsAccount: number;
  FdrAccount: number;
  savingAccount: number;
  registerCustomer: number;

  customerList: any[] = [];
  accId: any;
  customerLoan: any[] = [];
  allLoanStatus: LoanStatus[] = [];

  title = "ng-chart";
  chart: any = [];

  leadNo: string = null;
  asOnDate: Date = new Date();
  mfsType: number = 0;
  businessDate: any;
  startDate: any;
  EndDate: Date;



  //
    // CashFlowbarChart
    public CashflowbarChartOptions = cashflowchartsData.CashflowbarChartOptions;
  


    public CashflowbarChartType = cashflowchartsData.CashflowbarChartType;
    public CashflowbarChartLegend = cashflowchartsData.CashflowbarChartLegend;
    public CashflowbarChartLabels: any[] = [];
    public CashflowbarChartData: any[] = [];
    public CashflowbarChartColors = cashflowchartsData.CashflowbarChartColors; 

        // ProfitLossbarChart
        public PLbarChartOptions = cashflowchartsData.PLbarChartOptions;
        public PLbarChartLabels: any[] = [];
        public PLbarChartData: any[] = [];
  
        public PLbarChartType = cashflowchartsData.PLbarChartType;
        public PLbarChartLegend = cashflowchartsData.PLbarChartLegend;
       
        public PLbarChartColors = cashflowchartsData.PLbarChartColors; 


  // Doughnut
  public APdoughnutChartLabels = cashflowchartsData.APdoughnutChartLabels;
  public APdoughnutChartData = cashflowchartsData.APdoughnutChartData;
  public APdoughnutChartType = cashflowchartsData.APdoughnutChartType;
  public APdoughnutChartColors = cashflowchartsData.APdoughnutChartColors;
  public APdoughnutChartOptions = cashflowchartsData.APdoughnutChartOptions;





  // barChart
  public barChartOptions = chartsData.barChartOptions;
  public barChartLabels = chartsData.barChartLabels;
  public barChartType = chartsData.barChartType;
  public barChartLegend = chartsData.barChartLegend;
  public barChartData = chartsData.barChartData;
  public barChartColors = chartsData.barChartColors;

  // Doughnut
  public doughnutChartLabels = chartsData.doughnutChartLabels;
  public doughnutChartData : any[] = [];
  public doughnutChartType = chartsData.doughnutChartType;
  public doughnutChartColors = chartsData.doughnutChartColors;
  public doughnutChartOptions = chartsData.doughnutChartOptions;




  //

  public  LoanPaymentdoughnutChartLabels = chartsData. LoanPaymentdoughnutChartLabels;
  //public  LoanPaymentdoughnutChartData = chartsData. LoanPaymentdoughnutChartData;
  public LoanPaymentdoughnutChartData: any[] = [];
  public  LoanPaymentdoughnutChartType = chartsData. LoanPaymentdoughnutChartType;
  public  LoanPaymentdoughnutChartColors = chartsData. LoanPaymentdoughnutChartColors;
  public  LoanPaymentdoughnutChartOptions = chartsData. LoanPaymentdoughnutChartOptions;

  // events
  public chartClicked(e: any): void {
    //your code here
  }

  public chartHovered(e: any): void {
    // Handle hover event here
  }

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.GetMonthlyProfitandLossData();
    this.getMonthlyCashData();
    this.GetCashFlowAllForAdminDashBoard();
    this.getLoanLeadInfoForAdmin();
    this.getAccountCountForAdmin();
    this.getCustomerList();
    //this.getTotalReceivable();
    this.getTodayOverdue();
    this.getTodayReceived();
    this.getTodaySettlement();
    this.getAdvanceAmount();
    this.getAllLoanStatus();
    this.GetTodayCollectionList();
    this.getClList();
    this.GetDPSPayableList();
    this.getCurrentBusinessDate();
    // this.chart =  new this.chart('canvas', {
    //   type: 'bar',
    //   data: {
    //     labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    //     datasets: [
    //       {
    //         label: '# of Votes',
    //         data: [12, 19, 3, 5, 2, 3],
    //         borderWidth: 1,
    //       },
    //     ],
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true,
    //       },
    //     },
    //   },
    // });
  }
  getMonthlyCashData(): void {
    this._serviceDashboard.GetMonthlyCashGoingandOut().subscribe((data) => {

      this.CashflowbarChartLabels = data.name;
        this.CashflowbarChartData = [
            // { data: [data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon,data.cashgoingon], label: 'Cash going in' },
            // { data: [data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout,data.cashgoingout], label: 'Cash going out' }
            { data: data.cashgoingon, label: 'Cash going in' },
            { data: data.cashgoingout, label: 'Cash going out' }
          ];

       
    });
}

GetMonthlyProfitandLossData(): void {
  this._serviceDashboard.GetMonthlyProfitandLoss().subscribe((data) => {

    this.PLbarChartLabels = data.name;
      this.PLbarChartData = [
          { data: data.profit, label: 'Profit' },
          { data: data.loss, label: 'Loss' }
        ];

     
  });
}














  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;
      this.startDate = new Date("1970-01-01");
      this.EndDate = new Date(this.businessDate);
      this.getTotalReceivable();
    });
  }

  getCustomerList() {
    this.customerService.getlist().subscribe((res) => {
      this.customerList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  getAllLoanStatus() {
    this._serviceDashboard.getAllLoanStatus().subscribe(
      (res: LoanStatus[]) => {
        this.allLoanStatus = res;

        this.LoanPaymentdoughnutChartData = [res[0].regularLoan,res[0].advanceLoan,res[0].osLoan,res[0].odLoan ];

      },
      (error) => {
        console.error("Error fetching loan status:", error);
      }
    );
  }

  getLoanLeadInfoForAdmin() {
    this._serviceDashboard.GetLoanInfoForAdminDeshBoard().subscribe({
      next: (res: any) => {
        
        this.waitingApplication = res.waitingApplication;
        this.forwardedApplication = res.forwardedApplication;
        this.approvedLoan = res.approvedLoan;
        this.rejectedLoan = res.rejectedLoan;
        this.waitingForDisburse = res.waitingForDisburse;
        this.disbursmentList = res.disbursmentList;
        this.finalDisbursList = res.finalDisbursmentList;
        this.waitingPaymentList = res.waitingPaymentList;
        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

GetCashFlowAllForAdminDashBoard(){
this._serviceDashboard.GetCashFlowAllForAdminDashBoard().subscribe({
next: (res:any) =>{
this.beginningcashonhand=res.beginningcashonhand;
this.cashgoingon=res.cashgoingon;
this.cashgoingout=res.cashgoingout;
this.profitloss=res.profitloss;
this.endingcashonhand=res.endingcashonhand;
},
error:(e)=>{
  console.log(e);
},
complete:()=>{},
});
}

  getAccountCountForAdmin() {
    this._serviceDashboard.GetAccountCountAdminDeshBoard().subscribe({
      next: (res: any) => {
        this.registerCustomer = res.registerCustomer;
        this.savingAccount = res.savingAccount;
        this.FdrAccount = res.fdrAccount;
        this.dpsAccount = res.dpsAccount;
        this.LoanApplication = res.loanApplication;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  getTotalReceivable() {
    const formattedStartDate = this.datePipe.transform(
      this.startDate,
      "yyyy-MM-dd"
    );
    const formattedEndDate = this.datePipe.transform(
      this.EndDate,
      "yyyy-MM-dd"
    );

    this.pdfService
      .LoanOutstandingDataByDateRange(formattedStartDate, formattedEndDate)
      .subscribe({
        next: (res: any) => {
          const sumloanAmount = Array.isArray(res)
            ? res.reduce(
                (accumulator, currentObject) =>
                  accumulator + parseFloat(currentObject.loanAmount),
                0
              )
            : 0;

          const sumCollectionAmount: number = Array.isArray(res)
            ? res.reduce(
                (accumulator, currentObject) =>
                  accumulator + parseFloat(currentObject.collectionAmount),
                0
              )
            : 0;

          this.totalReceivable = sumloanAmount - sumCollectionAmount;


          this.doughnutChartData = [this.totalReceivable,35000,24000 ];

          this.changeDetectorRef.detectChanges();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => {},
      });
  }

  // getTotalReceivable() {
  //   this.pdfService
  //     .LoanOutstandingData(this.leadNo, this.asOnDate, this.mfsType)
  //     .subscribe({
  //       next: (res: any) => {

  //         const sumloanAmount = Array.isArray(res)
  //           ? res.reduce(
  //               (accumulator, currentObject) =>
  //                 accumulator + parseFloat(currentObject.loanAmount),
  //               0
  //             )
  //           : 0;

  //         const sumCollectionAmount: number = Array.isArray(res)
  //           ? res.reduce(
  //               (accumulator, currentObject) =>
  //                 accumulator + parseFloat(currentObject.collectionAmount),
  //               0
  //             )
  //           : 0;

  //         this.totalReceivable = sumloanAmount - sumCollectionAmount;

  //         this.changeDetectorRef.detectChanges();
  //       },
  //       error: (e) => {
  //         console.log(e);
  //       },
  //       complete: () => {},
  //     });
  // }
  getTodayOverdue() {
    this.pdfService.LoanOverDueDashboard().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return parseFloat(accumulator) + parseFloat(currentObject.overDue);
          //return currentObject.overDue;
        }, 0);
        this.todayOverdue = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  getTodayReceived() {
    this._serviceDashboard.TodayReceivedList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return (
            parseFloat(accumulator) +
            parseFloat(currentObject.lastCollectionAmount)
          );
          //return currentObject.overDue;
        }, 0);
        this.todayReceived = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }
  getTodaySettlement() {
    this._serviceDashboard.TodaySettlementList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return (
            parseFloat(accumulator) +
            parseFloat(currentObject.lastCollectionAmount)
          );
          //return currentObject.overDue;
        }, 0);
        this.todaySettlement = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }
  getAdvanceAmount() {
    this._serviceDashboard.AdvanceCollectionList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return (
            parseFloat(accumulator) +
            parseFloat(currentObject.lastCollectionAmount)
          );
          //return currentObject.overDue;
        }, 0);
        this.advanceAmount = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  GetDPSPayableList() {
    this.pdfService.GetDPSPayableList().subscribe({
      next: (res: any) => {
        const sum: number = res.reduce((accumulator, currentObject) => {
          return accumulator + parseFloat(currentObject.collectionAmount);
          //return currentObject.overDue;
        }, 0);
        this.totalPayable = sum;

        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  OpenPDF(customerId: any) {
    this.pdfService.customerDetailsReport(customerId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  PrintKyc() {
    this.pdfService.OpenKycPdf(this.accId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  GetTodayCollectionList() {
    this._serviceDashboard.GetTodayCollectionList().subscribe((res: any[]) => {
      const sum: number = res.reduce((accumulator, currentObject) => {
        return accumulator + parseFloat(currentObject.installmentSize);
      }, 0);
      this.todayReceivableAmount = sum;
      this.changeDetectorRef.detectChanges();
    });
  }

  getClList() {
    this._serviceDashboard.getClProcessListByClTypeWise().subscribe((res) => {
      this.totalRunningLoan = res.length;
      this.changeDetectorRef.detectChanges();
    });
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }
  onUnderProClicked(type: number) {
    this.router.navigate(["/dashboard-custom/cutomer-lead"]);
    this.changeDetectorRef.detectChanges();
  }
  onWaiting(st: number) {
    this.router.navigate(["/dashboard/waiting-application"]);
  }
  onForwarded(st: number) {
    this.router.navigate(["/dashboard/forward-loan-app"]);
  }
  onApproved(st: number) {
    this.router.navigate(["/dashboard/approved-loan-app"]);
  }
  onRejected(st: number) {
    this.router.navigate(["/dashboard/rejected-loan-app"]);
  }
  onDisburse(st: number) {
    this.router.navigate(["/dashboard/application-disburse-list"]);
  }
  onReceivable() {
    this.router.navigate(["/dashboard/app-receivable-list"]);
  }
  TotalPayable() {
    this.router.navigate(["/dashboard/app-payable-loan-list"]);
  }
  TotalOverdue() {
    this.router.navigate(["/dashboard/app-overdue-loan-list"]);
  }
  ApprovedDisburse(st: number) {
    this.router.navigate(["/dashboard/app-approve-disburse"]);
  }
  FinalDisburse(st: number) {
    this.router.navigate(["/dashboard/final-disburse-list"]);
  }
  waitingPayment(st: number) {
    this.router.navigate(["/dashboard/waiting-payment"]);
  }
  TodayCollection() {
    this.router.navigate(["/dashboard/today-collection-list"]);
  }
  ClProcess() {
    this.router.navigate(["/dashboard/clcalculation-info"]);
  }

  TodayReceived() {
    this.router.navigate(["/dashboard/today-received-list"]);
  }

  AdvaceReceived() {
    this.router.navigate(["/dashboard/advance-received-list"]);
  }

  TodaySettlement() {
    this.router.navigate(["/dashboard/today-settlement-list"]);
  }

  // Line area chart 2 configuration Starts
  lineArea2: Chart = {
    type: "Line",
    data: data["lineArea2"],
    options: {
      showArea: true,
      fullWidth: true,
      lineSmooth: Chartist.Interpolation.none(),
      axisX: {
        showGrid: false,
      },
      axisY: {
        low: 0,
        scaleMinSpace: 25,
      },
      plugins: [
        ChartistTooltip({
          appendToBody: true,
          pointClass: "ct-point-circle",
        }),
      ],
    },
    responsiveOptions: [
      ["screen and (max-width: 768px)", { axisX: { labelInterpolationFnc: (value, index) => index % 2 === 0 ? value : null } }],
      ["screen and (max-width: 480px)", { axisX: { labelInterpolationFnc: (value, index) => index % 3 === 0 ? value : null } }],
    ],
    events: {
      created(data: any): void {
        const defs = data.svg.elem("defs");
        const gradient2 = defs.elem("linearGradient", { id: "gradient2", x1: 0, y1: 1, x2: 0, y2: 0 });
        gradient2.elem("stop", { offset: 0, "stop-opacity": "0.2", "stop-color": "transparent" }).parent()
          .elem("stop", { offset: 1, "stop-opacity": "0.2", "stop-color": "#60AFF0" });
  
        const gradient3 = defs.elem("linearGradient", { id: "gradient3", x1: 0, y1: 1, x2: 0, y2: 0 });
        gradient3.elem("stop", { offset: 0.3, "stop-opacity": "0.2", "stop-color": "transparent" }).parent()
          .elem("stop", { offset: 1, "stop-opacity": "0.2", "stop-color": "#6CD975" });
      },
      draw(data: any): void {
        const circleRadius = 4;
        if (data.type === "point") {
          const circle = new Chartist.Svg("circle", {
            cx: data.x,
            cy: data.y,
            r: circleRadius,
            "ct:value": data.value.y,
            "ct:meta": data.meta,
            style: "pointer-events: all !important",
            class: "ct-point-circle",
          });
          data.element.replace(circle);
        } else if (data.type === "label") {
          const dX = data.width / 2 + (25 - data.width);
          data.element.attr({ x: data.element.attr("x") - dX });
        }
      },
    },
  };
  
  
  // Line area chart 2 configuration Ends

  // Donut chart configuration Starts
  DonutChart: Chart = {
    type: "Pie",
    data: {
      series: [
        {
          name: "regular",
          className: "ct-done",
          value: 25,
        },
        {
          name: "advance",
          className: "ct-progress",
          value: 25,
        },
        {
          name: "os",
          className: "ct-outstanding",
          value: 25,
        },
        {
          name: "od",
          className: "ct-started",
          value: 25,
        },
      ],
    },
    options: {
      donut: true,
      startAngle: 0,
      labelInterpolationFnc: function (value) {
        var total = data["donutDashboard"].series.reduce(function (
          prev,
          series
        ) {
          return prev + series.value;
        },
        0);
        return total + "%";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy: data.element.root().height() / 2,
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Donut chart configuration Starts
  DonutChart1: Chart = {
    type: "Pie",
    data: data["DashboardDonut"],
    options: {
      donut: true,
      donutWidth: 3,
      startAngle: 0,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return "\ue8dd";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy:
                (data.element.root().height() + data.element.height() / 4) / 2,
              class: "ct-label",
              "font-family": "feather",
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Donut chart configuration Starts
  DonutChart2: Chart = {
    type: "Pie",
    data: data["DashboardDonut"],
    options: {
      donut: true,
      donutWidth: 3,
      startAngle: 90,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return "\ue8f8";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy:
                (data.element.root().height() + data.element.height() / 4) / 2,
              class: "ct-label",
              "font-family": "feather",
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Donut chart configuration Starts
  DonutChart3: Chart = {
    type: "Pie",
    data: data["DashboardDonut"],
    options: {
      donut: true,
      donutWidth: 3,
      startAngle: 270,
      chartPadding: 25,
      labelInterpolationFnc: function (value) {
        return "\ue879";
      },
    },
    events: {
      draw(data: any): void {
        if (data.type === "label") {
          if (data.index === 0) {
            data.element.attr({
              dx: data.element.root().width() / 2,
              dy:
                (data.element.root().height() + data.element.height() / 4) / 2,
              class: "ct-label",
              "font-family": "feather",
            });
          } else {
            data.element.remove();
          }
        }
      },
    },
  };
  // Donut chart configuration Ends

  // Line chart configuration Starts
  WidgetlineChart: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  WidgetlineChart1: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart_1"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  WidgetlineChart2: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart_2"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  WidgetlineChart3: Chart = {
    type: "Line",
    data: data["WidgetlineDashboard2Chart_3"],
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 50,
        showLabel: false,
        offset: 0,
      },
      fullWidth: true,
    },
  };
  // Line chart configuration Ends

  // Line Chart 3 Starts
  lineChart3: Chart = {
    type: "Line",
    data: data["Line3"],
    options: {
      axisX: { showGrid: false },
      axisY: {
        scaleMinSpace: 30,
      },
      fullWidth: true,
      chartPadding: { top: 0, right: 50, bottom: 0, left: 0 },
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px) and (min-width: 381px)",
        {
          axisX: {
            labelInterpolationFnc: function (value, index) {
              return index % 2 === 0 ? value : null;
            },
          },
        },
      ],
      [
        "screen and (max-width: 380px)",
        {
          axisX: {
            labelInterpolationFnc: function (value, index) {
              return index % 3 === 0 ? value : null;
            },
          },
        },
      ],
    ],
  };
  // Line Chart 3 Ends
}
