import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardCustomService } from '../dashboard-custom.service';
import { SharedService } from 'app/services/shared.service';
import swal from 'sweetalert';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { DatePipe, Location } from "@angular/common";
@Component({
  selector: "app-application-disburse-list",
  templateUrl: "./application-disburse-list.component.html",
  styleUrls: ["./application-disburse-list.component.scss"],
})
export class ApplicationDisburseListComponent implements OnInit {
  startDate: Date = new Date();
  EndDate: Date = new Date();

  formattedStartDate: string;
  formattedEndDate: string;

  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private shared: SharedService,
    private datePipe: DatePipe
  ) {}
  LeadList: any[] = [];
  status: number;
  userRoles: string[];

  ngOnInit(): void {
    this.startDate = this.startDate || new Date();
    this.EndDate = this.EndDate || new Date();
    // this.getLeadList();
    this.getUserRoles();
  }

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }

  //   getForwardApplication(){
  //   this._serviceDashboard.getLoanLeadInfoForAdmin().subscribe((res) =>{
  //     this.dataList =res.filter(item => item.statusInfoId ===2);
  //     this.changeDetectorRef.detectChanges();
  //   })
  // }

  formatDates() {
    this.formattedStartDate = this.datePipe.transform(
      this.startDate,
      "dd-MM-yyyy"
    );
    this.formattedEndDate = this.datePipe.transform(this.EndDate, "dd-MM-yyyy");
  }
  onDateChange() {
    this.formatDates();
  }

  getUserRoles() {
    this.shared.UserRoles().subscribe(
      (response) => {
        this.userRoles = response;
        this.getLeadList(); // Call getLeadList() after userRoles are fetched
      },
      (error) => {
        console.error("Error fetching user roles:", error);
      }
    );
  }

  getLeadList() {
    const formattedStartDate = this.datePipe.transform(
      this.startDate,
      "yyyy-MM-dd"
    );
    const formattedEndDate = this.datePipe.transform(
      this.EndDate,
      "yyyy-MM-dd"
    );

    this._serviceDashboard
      //.getAppliedLoanLeadInfoForDisburse(formattedStartDate, formattedEndDate)
      .getAppliedLoanLeadInfoForDisburseBYDate(
        formattedStartDate,
        formattedEndDate
      )

      .subscribe((res) => {
        //this.LeadList =res.filter(item => item.statusInfoId ===3);
        if (this.userRoles && this.userRoles.includes("UnderWriting")) {
          this.LeadList = res.filter((x) => x.statusInfoId == 10);
        } else {
          this.LeadList = res.filter((x) => x.statusInfoId == 3);
        }
        this.changeDetectorRef.detectChanges();
      });

    // this._serviceDashboard.getDisbursLoan(this.status).subscribe((res: any[]) =>{
   
    //   this.LeadList=res.sort((a,b)=>
    //   {
    //     return b.id-a.id;
    //   })
    //   this.changeDetectorRef.detectChanges();
    // })
  }
  // loanDisburse(id){
  //   swal('Confirm First', 'Do you want to disburse this loan', 'warning').then(x=>{
  //     this._serviceDashboard.disbursLoan(id).subscribe((res: any[]) =>{
  //       this.LeadList =res;
  //       if(res != null)
  //       {
  //         this.shared.ShowAlert('Thank you...', 'Saved succesfully!', 'success');
  //         this.getLeadList();
  //         this.changeDetectorRef.detectChanges();
  //       }
  //       else
  //       {
  //         this.shared.ShowAlert('Error', 'Failed to save.', 'error');
  //       }

  //     })
  //   });
  // }

  goDisburse(leadId: any, customersId: any, loanProductsId: any) {
    //this.router.navigate(['/loan-application/loan-application', leadId]);
    this.router.navigate([
      "/loan-application/disburse-lead-info/" +
        leadId +
        "/" +
        customersId +
        "/" +
        loanProductsId,
    ]);
  }

  UnderwritingApprove(leadId: any, customersId: any, loanProductsId: any) {
    this._serviceDashboard
      .ApproveLeadByUnderwriting(leadId)
      .subscribe((res) => {
        this.shared.ShowAlert("Approved", "Approved Successfully", "success");
        this.changeDetectorRef.detectChanges();

        this.getLeadList();
      });
  }

  goDetailsView(leadId: any) {
    this.router.navigate([
      "/loan-application/app-loan-application-full-view/" + leadId,
    ]);
  }
  goBack() {
    this.location.back();
  }
}
