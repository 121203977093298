import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CashApprovalService } from 'app/accounting/service/cash-approval.service';
import { GroupService } from 'app/accounting/service/group.service';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { CountryInfo } from 'app/master-data/basic/basic-model/country-info';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { Excise_duty } from 'app/master-data/products/model/Excise_Duty';
import { Accured } from 'app/master-data/products/model/accured';
import { Balancetype } from 'app/master-data/products/model/balancetype';
import { CapitalizationRule } from 'app/master-data/products/model/capitalizationRule';
import { CapitalizeFrequency } from 'app/master-data/products/model/capitalize-frequency';
import { Charge } from 'app/master-data/products/model/charge';
import { Commission } from 'app/master-data/products/model/commission';
import { DaySize } from 'app/master-data/products/model/day-size';
import { Durations } from 'app/master-data/products/model/durations';
import { InterestCalculationFrequency } from 'app/master-data/products/model/interest-calculation-frequency';
import { AccuredService } from 'app/master-data/products/service/accured.service';
import { BalancetypeService } from 'app/master-data/products/service/balancetype.service';
import { CapitalizationRuleService } from 'app/master-data/products/service/capitalization-rule.service';
import { CapitalizeFrequencyService } from 'app/master-data/products/service/capitalize-frequency.service';
import { ChargeService } from 'app/master-data/products/service/charge.service';
import { CommissionService } from 'app/master-data/products/service/commission.service';
import { DaySizeService } from 'app/master-data/products/service/day-size.service';
import { ExciseDutyService } from 'app/master-data/products/service/excise-duty.service';
import { InterestCalculationService } from 'app/master-data/products/service/interest-calculation.service';
import { LoanTypeService } from 'app/master-data/products/service/loan-type.service';
import { PaymentTypeService } from 'app/master-data/products/service/payment-type.service';
import { SlabService } from 'app/master-data/products/service/slab.service';
import { TaxKeyService } from 'app/master-data/products/service/tax-key.service';
import { BnplProductService } from 'app/product/service/bnpl-product.service';
import { CustomValidatorsService } from 'app/shared/directives/custom-validators.service';
import { Location } from "@angular/common";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { UserRoleAssignService } from 'app/user/user-management/user-service/user-role-assign.service';
interface Approver {
  order: number;
  userId: string;
  fullName: string;
}


@Component({
  selector: 'app-bnpl-product-entry',
  templateUrl: './bnpl-product-entry.component.html',
  styleUrls: ['./bnpl-product-entry.component.scss']
})
export class BnplProductEntryComponent implements OnInit {

  ledgerList: any;
  incomeExpenseGroupList: any;
  ledgerListAcc: any = [];
  ledgerIntProList: any = [];
  accGroupList: any[] = [];
  productDetails: any;
  balanceTypeList: Balancetype[] = [];
  exciseDutyList: Excise_duty[] = [];
  exciseDutyDetails: any[] = [];
  sortExcDutyDetails: any[] = [];
  exciseDuty: any;
  capRuleList: CapitalizationRule[] = [];
  capRuleSelectList: CapitalizationRule[] = [];
  capFrequencyList: CapitalizeFrequency[] = [];
  intCalculationTypeList: any[] = [];
  intCalTypeDetails: any[] = [];
  storeIntCalTypeDetails: any[] = [];
  storeIntCalType: any;
  intCalculationFreqList: [InterestCalculationFrequency][] = [];
  accuredList: Accured[] = [];
  daySizeList: DaySize[] = [];
  currencyList: CountryInfo[] = [];
  taxKeyList: any[] = [];
  taxKey: any;
  taxKeyDetails: any[] = [];
  allSlab: any[] = [];
  allLoanType: any[] = [];
  allPaymentType: any[] = [];
  instCalTypeSlab: any[] = [];
  isEdit: boolean = false;
  intCalTypeDiv: boolean = true;
  intCalTypeMultipleDiv: boolean = false;
  chargeList: Charge[] = [];
  selectedChargeList: Charge[] = [];
  charge: any;
  capRule: any;
  selectedDurationList: Durations[] = [];
  commissionList: Commission[] = [];
  selectedCommissionList: Commission[] = [];
  commission: any;
  ReducingMethodList: any[] = [];
  loandurationList: any[] = [];
  showEntry = false;
  frmGroup: FormGroup;
  submitted = false;
  model: any = {};
  closeResult: string;
  productCode: string;
  allbankusers: [];
  allbankusers2: [];
  isReadOnly: boolean;
  editId: number;

  selectedUserId: string;
  selectedUsername: string;

  selectedUserId2: string;
  selectedUsername2: string;

  addedApprovers: Approver[] = [];
  addedApprovers2: any[] = [];

  constructor(
    private _loanProductService: BnplProductService,
    private _CommissionService: CommissionService,
    private _blanaceTypeService: BalancetypeService,
    private _exciseDutyService: ExciseDutyService,
    private _capitaliseRuleService: CapitalizationRuleService,
    private _intCalculationService: InterestCalculationService,
    private _accuredService: AccuredService,
    private _capitaliseFrequencyService: CapitalizeFrequencyService,
    private _daySizeService: DaySizeService,
    private _taxKeyService: TaxKeyService,
    private _countryService: CountryInfoService,
    private _slabService: SlabService,
    private _chargeService: ChargeService,
    private _loanTypeService: LoanTypeService,
    private _paymentTypeService: PaymentTypeService,
    private _groupService: GroupService,
    private customValidatorsService: CustomValidatorsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private _ledgerService: LedgerService,
    private _cashApprovalService: CashApprovalService,
    private changeref: ChangeDetectorRef,
    private location: Location,
    private _service:UserRoleAssignService,
    private router: Router
  ) {}

  // getGroupList() {
  //   this._groupService.GetAccountGroupListByNature(this.frmGroup.value.productType).subscribe(res => {
  //     this.accGroupList = res;
  //   });
  // }
  ngOnInit() {
    this.activeRoute.paramMap.subscribe((params) => {
      const ProductBuilderId = +params.get("id");
      if (ProductBuilderId > 0) {
        this.getProductBuilderById(ProductBuilderId);
      }
    });
    this.populateForm();
    this.getExciseDuty(2);
    this.getDaySize(2);
    // this.getTaxKey();
    this.getInterestCalculationType(2);
    this.getInterestCalculationFrequency(2);
    this.getCapitalizationRule(2);
    this.getCapitalizationFrequency(2);
    this.getBalanceType(2);
    //this.getAccured();
    this.GetAllCommissionList(2);
    this.GetAllChargeList(2);
    this.getCountryList();
    this.getSlabList(2);
    //this.getGroupList();
    this.getAllLoanType();
    this.getAllPaymentType();
    this.getAllReducingMethodList();
    this.getAllLoandurationList();
    this.getIncomeExpenseGroup();
    // this.activeRoute.paramMap.subscribe(params => {
    //   const ProductBuilderId = +params.get('id');
    //   if (ProductBuilderId > 0) {
    //     this.getProductBuilderById(ProductBuilderId);
    //   }
    // });
    this.getGroupByNatureId(1);
    this.getAllBankUsers();
    this.getAllUserRole();
  }

  onGroupChange(gId: number, typeId: number) {
    if (typeId == 3) {
      if (gId != 0 || gId != null) {
        this.getLedgerListByGroupIdForProfit(gId);
      }
    } else if (typeId == 2) {
      if (gId != 0 || gId != null) {
        this.loadIntProLedgerListByGroupId(gId);
      }
    } else if (typeId == 1) {
      if (gId != 0 || gId != null) {
        this.loadLedgerListByGroupId(gId);
      }
    }
  }
  getAllUserRole(){
    this._service.GetAllRole().subscribe((res) => {
      this.allbankusers2 = res;
    });
  }
  getAllBankUsers() {
    this._cashApprovalService.getUserlist().subscribe((res) => {
      // console.log(res);
      this.allbankusers = res;
    });
  }

  ChangeApprover(target) {
  
    this.selectedUserId = target.value;
    this.selectedUsername = target.selectedOptions[0].innerText;
  }

  ChangeApprover2(target) {
    this.selectedUserId2 = target.value;
    this.selectedUsername2 = target.selectedOptions[0].innerText;
  }
  AddApprover() {
    const newObject: Approver = {
      order: this.addedApprovers.length + 1,
      userId: this.selectedUserId,
      fullName: this.selectedUsername,
    };
    this.addedApprovers.push(newObject);
  }
  AddApprover2() {
    const newObject: any = {
      order: this.addedApprovers2.length + 1,
      roleId: this.selectedUserId2,
      roleName: this.selectedUsername2,
    };
    this.addedApprovers2.push(newObject);
  }
  getLoanProductCode(typeId: number) {
    if (typeId > 0 && !this.isEdit) {
      this._loanProductService
        .getLoanProductCodeByType(typeId)
        .subscribe((res) => {
          this.productCode = res;
        });
    }
  }

  loadLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerListAcc = res;
    });
  }
  getLedgerListByGroupIdForProfit(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerList = res;
    });
  }
  loadIntProLedgerListByGroupId(gId: number) {
    this._ledgerService.GetLedgerListWithGLByNatureId(gId).subscribe((res) => {
      this.ledgerIntProList = res;
    });
  }

  getGroupByNatureId(natureId: number) {
    this._groupService.ChildAccountGroupByNature(1).subscribe((res) => {
      this.accGroupList = res;
    });
  }

  getIncomeExpenseGroup() {
    this._groupService.GetIncomeExpenseAccountGroup().subscribe((res) => {
      this.incomeExpenseGroupList = res;
      this.changeref.detectChanges();
    });
  }
  trackByFn(index, item) {
    return item.id;
  }

  getProductDetails(id: any) {
    this._loanProductService.getLoanProductBuilderDetailsById(id).subscribe(
      (data: any) => {
        this.productDetails = data;
        this.frmGroup = this.formBuilder.group({
          id: [id],
          Code: [data?.productInfo?.code],
          Name: [data?.productInfo?.name],
          Version: [data?.productInfo?.version],
          loanTypeId:[data?.productInfo?.loanTypeId>0?data?.productInfo?.loanTypeId:0],
          paymentTypeId:[data?.productInfo?.paymentTypeId],
          Description:[data?.productInfo?.description],
          overDueCalculationAfterDays:[data?.productInfo?.overDueCalculationAfterDays],
          gracePeriodDays:[data?.productInfo?.gracePeriodDays],
          InterestRateMin:[data?.productInfo?.interestRateMin],
          InterestRateMax:[data?.productInfo?.interestRateMax],
          interestRate:[data?.productInfo?.interestRate],
          Currency: [data?.productInfo?.currencyId],
          DaySizeId: [data?.productInfo?.daySizeId],
          //taxKeyId: [data?.productInfo?.taxKeyId],
          exciseDutyId: [data?.productInfo?.exciseDutyId],
          InterestCalculationTypeId: [data?.productInfo?.interestCalculationTypeId>0?data?.productInfo?.interestCalculationTypeId:0],
          InterestCalculationTypeSlabId: [""],
          interestCalculationTypeSelect: [data?.productInfo?.interestCalculationTypeId>0?0:1],
          InterestCalculationFrequencyId: [data?.productInfo?.interestCalculationFrequencyId],
          CapitalizationRuleId: [""],
          capitalizationFrequencyId: [data?.productInfo?.capitalizationFrequencyId>0?data?.productInfo?.capitalizationFrequencyId:''],
          BalanceTypeId: [data?.productInfo?.balanceTypeId],
          commissionId: [""],
          chargeId: [""],
          durationId: [""],
          reducingMethodId:[data?.productInfo?.reducingMethodId],
          ledgerRelationId: [data?.productInfo?.accountGroupId],
          ledgerId: [data?.productInfo?.ledgerId],
          plInterestGroupId:[data?.productInfo?.plInterestLedger?.groupId],
          plInterestLedgerId:[data?.productInfo?.plInterestLedgerId],
          interestProvisionGroupId: [data?.productInfo?.interestProvisionLedger?.groupId],
          interestProvisionLedgerId: [data?.productInfo?.interestProvisionLedgerId],
          status:[data?.productInfo?.statuss== true || data?.productInfo?.statuss== 'true'|| data?.productInfo?.statuss== 'null'|| data?.productInfo?.statuss== null ? 1 : 0]
        });
        this.exciseDuty = null;
        this.sortExcDutyDetails = [];
        for (const dt of this.exciseDutyDetails) {
          if (this.frmGroup.value.exciseDutyId == dt.exciseDutyId) {
            this.sortExcDutyDetails.push(dt);
            this.exciseDuty = dt.exciseDuty;
          }
        }
        this.storeIntCalTypeDetails = [];
        this.storeIntCalType = null;
        for (const ty of this.intCalTypeDetails) {
          if (
            this.frmGroup.value.InterestCalculationTypeId ==
            ty.interestCalculationTypeId
          ) {
            this.storeIntCalTypeDetails.push(ty);
            this.storeIntCalType = ty.interestCalculationType;
          }
        }
        if(data?.productInfo?.interestCalculationTypeId==null){
          this.intCalTypeDiv=false;
          this.intCalTypeMultipleDiv=true;
        }
        data?.productCommission?.forEach(e => {
          if(e!=null && e!='null'){
            this.selectedCommissionList.push(
              e?.commission
            );
          }
        });
        data?.charges?.forEach(e => {
          if(e!=null && e!='null'){
            this.selectedChargeList.push(
              e
            );
          }
        });
        data?.productRules?.forEach(e => {
          if(e!=null && e!='null'){
            this.capRuleSelectList.push(
              e?.rule
            );
          }
        });
        data?.productInstCalTypes?.forEach(e => {
          if(e!=null && e!='null'){
            this.instCalTypeSlab.push(
              e?.slab
            );
          }
        });
        data?.productTenures?.forEach(e => {
          if(e!=null && e!='null'){
            this.selectedDurationList.push(
              e?.duration
            );
          }
        });
        // data?.productApprover?.forEach(e => {
        //   if(e!=null && e!='null'){
        //     const newObject: Approver = {
        //       order: e.order,
        //       userId: e.userInfoId,
        //       fullName: e.userInfo.fullName
        //     };
        //     this.addedApprovers.push(newObject);
        //   }
        // });
        data?.productApprover?.forEach(e => {
          if(e!=null && e!='null'){
            const newObject: any = {
              order: e.order,
              roleId: e.roleId,
              roleName: e.role?.name
            };
            this.addedApprovers2.push(newObject);
            
          }
        });
        if(data?.productInfo?.exciseDutyId>0){
          this.exciseDuty = null;
          this.sortExcDutyDetails = [];
          for (const dt of this.exciseDutyDetails) {
            if (data?.productInfo?.exciseDutyId == dt.exciseDutyId) {
              this.sortExcDutyDetails.push(dt);
              this.exciseDuty = dt.exciseDuty;
            }
          }
        }
        if(data?.productInfo?.accountGroupId>0){
          this.onGroupChange(data?.productInfo?.accountGroupId,1);
        }
        if(data?.productInfo?.plInterestLedgerId>0){
          this.onGroupChange(data?.productInfo?.plInterestLedger?.groupId,3);
        }
        if(data?.productInfo?.interestProvisionLedgerId>0){
          this.onGroupChange(data?.productInfo?.interestProvisionLedger?.groupId,2);
        }
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }
  getProductBuilderById(id: number) {
    this.isEdit = true;
    this.isReadOnly = true;
    this.editId=id;
    this.getProductDetails(id);
  }
  // validateVersionCode(){
  //   const code=this.frmGroup.get('Code').value;
  //   this.customValidatorsService.DuplicateProductBuilderVersionCode()
  // }
  // validateCode(){
  //   const check=this.customValidatorsService.DuplicateProductBuilderCode();
  //   console.log(check);
  // }
  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      Code: [
        null,
        [Validators.required, Validators.minLength(2)],
        [this.customValidatorsService.DuplicateProductBuilderCode()],
        { updateOn: "blur" },
      ],
      Name: [null, [Validators.required, Validators.minLength(2)]],
      Version: [null,
        [Validators.required],
        //[this.isEdit && this.customValidatorsService.DuplicateProductBuilderVersionCode(this.productDetails?.productInfo?.code)],
        //{ updateOn: "blur" },
      ],
      Description: [""],
      InterestRateMin: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      InterestRateMax: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      interestRate: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      Currency: [""],
      DaySizeId: [""],
      //taxKeyId: [''],
      exciseDutyId: [""],
      InterestCalculationTypeId: [0],
      InterestCalculationTypeSlabId: [""],
      interestCalculationTypeSelect: [0],
      InterestCalculationFrequencyId: [""],
      CapitalizationRuleId: [""],
      capitalizationFrequencyId: [""],
      BalanceTypeId: [0],
      loanTypeId: [0, [Validators.required]],
      paymentTypeId: ["", [Validators.required]],
      inputorId: [""],
      authorizerId: [""],
      //AccuredId: [''],
      status: ["1"],
      chargeId: [""],
      commissionId: [0],
      overDueCalculationAfterDays: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      gracePeriodDays: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      ledgerRelationId: [""],
      productType: [1],
      reducingMethodId: [""],
      loanDurationId: [""],
      durationId: [""],
      plInterestGroupId: [""],
      interestProvisionGroupId: [""],
      ledgerId: [""],
      plInterestLedgerId: [""],
      ipLedgerId: [""],
      interestProvisionLedgerId: [""],
      // loanInstallmentId: [''],
    });
  }

  onsubmit() {
    this.submitted = true;
    if (this.frmGroup.valid) {
      if (this.isEdit) {
        this.model.id = this.frmGroup.value.id;
      }
      this.model.Code = this.frmGroup.value.Code;
      this.model.Name = this.frmGroup.value.Name;
      this.model.Version = this.frmGroup.value.Version;
      this.model.Description = this.frmGroup.value.Description;
      this.model.InterestRateMin = this.frmGroup.value.InterestRateMin;
      this.model.InterestRateMax = this.frmGroup.value.InterestRateMax;
      this.model.Currency = this.frmGroup.value.Currency;
      this.model.DaySizeId = this.frmGroup.value.DaySizeId;
      this.model.taxKeyId = this.frmGroup.value.taxKeyId;
      this.model.exciseDutyId = this.frmGroup.value.exciseDutyId;
      this.model.InterestCalculationTypeId =
        this.frmGroup.value.InterestCalculationTypeId;
      this.model.InterestCalculationFrequencyId =
        this.frmGroup.value.InterestCalculationFrequencyId;
      // this.model.CapitalizationRuleId = this.frmGroup.value.CapitalizationRuleId,
      this.model.capitalizationFrequencyId = this.frmGroup.value.capitalizationFrequencyId,
      (this.model.BalanceTypeId = this.frmGroup.value.BalanceTypeId),
        //this.model.inputorId = this.frmGroup.value.inputorId,
        //this.model.authorizerId = this.frmGroup.value.authorizerId,
        //this.model.AccuredId = this.frmGroup.value.AccuredId,
        (this.model.status = this.frmGroup.value.status === 1 ? true : false);
      this.model.InterestCalculationTypeSlabId = this.instCalTypeSlab;
      this.model.selectedCapitalizationRuleId = this.capRuleSelectList;
      this.model.selectedChargeId = this.selectedChargeList;
      this.model.selectedCommissionId = this.selectedCommissionList;
      //this.model.durationInfoList = this.selectedDurationList;
      this.model.selectedDurationId = this.selectedDurationList;

      this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId;
      this.model.loanTypeId = this.frmGroup.value.loanTypeId;
      this.model.paymentTypeId = this.frmGroup.value.paymentTypeId;
      this.model.overDueCalculationAfterDays =
        this.frmGroup.value.overDueCalculationAfterDays;
      this.model.gracePeriodDays = this.frmGroup.value.gracePeriodDays;
      this.model.reducingMethodId = this.frmGroup.value.reducingMethodId;

      this.model.interestRate = this.frmGroup.value.interestRate;
      this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId;
      this.model.ledgerId = this.frmGroup.value.ledgerId;
      this.model.plInterestLedgerId = this.frmGroup.value.plInterestLedgerId;
      this.model.interestProvisionLedgerId = this.frmGroup.value.interestProvisionLedgerId;

      this.model.loanDurationId = this.frmGroup.value.loanDurationId;
      this.model.approvers = this.addedApprovers2;
      //this.model.approvers = this.addedApprovers;
      // this.model.durations=this.frmGroup.value.durations;
      //this.model.loanInstallmentId = this.frmGroup.value.loanInstallmentId
      Swal.fire({
        title: 'Are you sure?',
        text: this.isEdit?'You want to update!':'You want to save!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.isEdit?'Update!':'Save!',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._loanProductService
          .saveLoanProduct(this.model)
          .pipe(
            catchError((error) => {  
              if(error.error.message.indexOf("UNIQUE KEY") !== -1){
                Swal.fire({
                  title: 'Failed?',
                  text: 'Version are already exist.',
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: 'Ok',
                  //cancelButtonText: 'No'
                })
              }            
              return throwError(error);
            })
          )
          .subscribe((res) => {
            Swal.fire({
              title: 'Success?',
              text: this.isEdit?'Update success!':'Save success!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((e) =>{
              this.router.navigate(["/product/bnpl-product-list"]);
            })
          })
        }
      });
      // if (this.model.id) {
      //   this._loanProductService
      //     .saveLoanProduct(this.model)
      //     .subscribe((res) => {
      //       console.log(res)
      //       this.router.navigate(["/product/loan-product-list"]);
      //     });
      // } else {
      //   this._loanProductService
      //     .saveLoanProduct(this.model)
      //     .subscribe((res) => {
      //       console.log(res)
      //       //this.router.navigate(["/product/loan-product-list"]);
      //     });
      // }
    }
  }

  reset() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  edit(item: any) {
    this.isEdit = true;
    //this.showArea = 2;
    this.frmGroup.patchValue({
      id: item.id,
      Code: item.code,
      Name: item.name,
      Version: item.version,
      Description: item.description,
      InterestRateMin: item.interestRateMin,
      InterestRateMax: item.interestRateMax,
      Currency: item.currency,
      DaySizeId: item.daySizeId,
      //taxKeyId: item.taxKeyId,
      exciseDutyId: item.exciseDutyId,
      InterestCalculationTypeId: item.interestCalculationTypeId,
      InterestCalculationFrequencyId: item.interestCalculationFrequencyId,
      CapitalizationRuleId: item.iapitalizationRuleId,
      BalanceTypeId: item.balanceTypeId,
      inputorId: item.inputorId,
      authorizerId: item.authorizerId,
      // AccuredId: item.accuredId,
      status: item.status,
      reducingMethodId: item.reducingMethodId,
      loanDurationId: item.loanDurationId,
      //loanInstallmentId:item.loanInstallmentId
    });
  }

  // showArea = 1;
  // areaView(areaNo): void {
  //   this.showArea = areaNo;
  //   this.getExciseDuty();
  //   this.getDaySize();
  //   //this.getTaxKey();
  //   this.getInterestCalculationType();
  //   this.getInterestCalculationFrequency();
  //   this.getCapitalizationRule();
  //   this.getCapitalizationFrequency();
  //   this.getBalanceType();
  //   //this.getAccured();
  //   this.GetAllCommissionList();
  //   this.GetAllChargeList();
  //   this.getCountryList();
  //   this.getSlabList();
  //   //this.getGroupList();
  //   this.getAllLoanType();
  //   this.getAllPaymentType();
  // }

  //   delete(id : any){
  //      this._service.delete(id).subscribe(res =>{
  //       this.goProductBuilderList();
  //     })
  //   }
  //   goProductBuilderList() : void{

  //     this.router.navigate(['/loan/loan-type-list'])
  //  }
  //#region
  //#region
  getSlabList(id) {
    //this._slabService.getlist().subscribe((res) => {
    this._slabService.getlistByProBuilderType(id).subscribe((res) => {
      this.allSlab = res;
    });
  }
  getAllLoanType() {
    this._loanTypeService.getBNPLLoanTypeList().subscribe((res) => {
      this.allLoanType = res;
    });
  }
  getAllPaymentType() {
    this._paymentTypeService.getPaymentTypeList().subscribe((res) => {
      this.allPaymentType = res;
    });
  }
  getBalanceType(id:number) {
    this._blanaceTypeService.getlistByProBulderType(id).subscribe((res) => {
      this.balanceTypeList = res;
    });
  }
  getExciseDuty(id:number) {
    this._exciseDutyService.getExciseDutyListByType(id).subscribe((res) => {
      for (const exDuty of res) {
        this.exciseDutyList.push(exDuty["exciseDuty"]);
        if (exDuty["exciseDutyDetails"].length > 0) {
          for (const dtt of exDuty["exciseDutyDetails"]) {
            this.exciseDutyDetails.push(dtt);
          }
        }
      }
    });
  }
  onExciseDutyChange() {
    this.exciseDuty = null;
    this.sortExcDutyDetails = [];
    for (const dt of this.exciseDutyDetails) {
      if (this.frmGroup.value.exciseDutyId == dt.exciseDutyId) {
        this.sortExcDutyDetails.push(dt);
        this.exciseDuty = dt.exciseDuty;
      }
    }
  }
  getCapitalizationRule(id:number) {
    this._capitaliseRuleService.getlistByProBuilderType(id).subscribe((res) => {
      this.capRuleList = res;
    });
  }
  addMultipleCapRuleMulti() {
    const check = this.capRuleSelectList.find(
      (obj) => obj.id == this.frmGroup.value.capitalizeRuleSlabId
    );
    if (this.frmGroup.value.CapitalizationRuleId > 0 && check == undefined) {
      this.capRuleSelectList.push(
        this.capRuleList.find(
          (obj) => obj.id == this.frmGroup.value.CapitalizationRuleId
        )
      );
    }
  }
  getCapitalizationFrequency(id:number) {
    this._capitaliseFrequencyService
      .getCapFreqListByProBuildeType(id)
      .subscribe((res) => {
        this.capFrequencyList = res;
      });
  }

  onCalTypeSelect() {
    if (this.frmGroup.value.interestCalculationTypeSelect == "0") {
      this.intCalTypeDiv = true;
      this.intCalTypeMultipleDiv = false;
    } else {
      this.intCalTypeDiv = false;
      this.intCalTypeMultipleDiv = true;
    }
  }
  addSlabForIntCalTypeMulti() {
    const check = this.instCalTypeSlab.find(
      (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
    );
    if (
      this.frmGroup.value.InterestCalculationTypeSlabId > 0 &&
      check == undefined
    ) {
      this.instCalTypeSlab.push(
        this.allSlab.find(
          (obj) => obj.id == this.frmGroup.value.InterestCalculationTypeSlabId
        )
      );
    }
  }
  getInterestCalculationType(id:number) {
    this.intCalculationTypeList = [];
    this.intCalTypeDetails = [];
    this._intCalculationService
      .GetAllInterestCalculationTypeByProductType(id)
      .subscribe((res) => {
        for (const type of res) {
          this.intCalculationTypeList.push(type["instCalType"]);
          if (type["instCalTypeDetail"].length > 0) {
            for (const dtt of type["instCalTypeDetail"]) {
              this.intCalTypeDetails.push(dtt);
            }
          }
        }
      });
  }
  onCalTypeChange() {
    this.storeIntCalTypeDetails = [];
    this.storeIntCalType = null;
    for (const ty of this.intCalTypeDetails) {
      if (
        this.frmGroup.value.InterestCalculationTypeId ==
        ty.interestCalculationTypeId
      ) {
        this.storeIntCalTypeDetails.push(ty);
        this.storeIntCalType = ty.interestCalculationType;
      }
    }
  }
  getAccured() {
    this._accuredService.getAccuredList().subscribe((res) => {
      this.accuredList = res;
    });
  }
  GetAllCommissionList(id:number) {
    this._CommissionService.GetAllCommissionListByProBuilderType(id).subscribe((res) => {
      this.commissionList = res;
    });
  }
  addMultipleCommission() {
    const check = this.selectedCommissionList.find(
      (obj) => obj.id == this.frmGroup.value.commissionId
    );
    if (this.frmGroup.value.chargeId > 0 && check == undefined) {
      this.selectedCommissionList.push(
        this.commissionList.find(
          (obj) => obj.id == this.frmGroup.value.commissionId
        )
      );
    }
  }

  addMultipleDuration() {
    const check = this.selectedDurationList.find(
      (obj) => obj.id == this.frmGroup.value.durationId
    );
    if (this.frmGroup.value.durationId > 0 && check == undefined) {
      this.selectedDurationList.push(
        this.loandurationList.find(
          (obj) => obj.id == this.frmGroup.value.durationId
        )
      );
    }
  }

  onDurationRemove(id: number): void {
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    this.selectedDurationList = this.selectedDurationList.filter(
      (item) => item.id !== id
    );
  }

  GetAllChargeList(id:number) {
    this._chargeService.GetAllChargeListByProBuilderType(id).subscribe((res) => {
      this.chargeList = res;
    });
  }
  addMultipleCharge() {
    const check = this.selectedChargeList.find(
      (obj) => obj.id == this.frmGroup.value.chargeId
    );
    if (this.frmGroup.value.chargeId > 0 && check == undefined) {
      this.selectedChargeList.push(
        this.chargeList.find((obj) => obj.id == this.frmGroup.value.chargeId)
      );
    }
  }
  getInterestCalculationFrequency(id:number) {
    this._intCalculationService.getFrequencyListByProductType(id).subscribe((res) => {
      this.intCalculationFreqList = res;
    });
  }
  getDaySize(id:number) {
    this._daySizeService.getDaySizeListByProductId(id).subscribe((res) => {
      this.daySizeList = res;
    });
  }
  getCountryList() {
    this._countryService.getAllCurrency().subscribe((res) => {
      this.currencyList = res;
    });
  }

  // getTaxKey(){
  //   this._taxKeyService.getList().subscribe(res => {
  //     this.taxKeyList = res;
  //   })
  // }
  // onTaxChange(){
  //   this.taxKeyDetails=[];
  //   this.taxKey=null;
  //   for (const dt of this.taxKeyList) {
  //     if(this.frmGroup.value.taxKeyId==dt.taxKey.id){
  //       this.taxKeyDetails=dt.taxDetails;
  //       this.taxKey=dt.taxDetails[0].taxKey;
  //     }
  //   }
  // }

  //#endregion
  //#region Detail

  getAllReducingMethodList() {
    this._loanProductService.getAllReducingMethod().subscribe((res) => {
      this.ReducingMethodList = res;
    });
  }

  getAllLoandurationList() {
    this._loanProductService.getAllLoanDuration().subscribe((res) => {
      this.loandurationList = res;
    });
  }

  openModal(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  onCommissionChange() {
    this.commission = null;
    this.commission= this.commissionList.find(
        (obj) => obj.id == this.frmGroup.value.commissionId
      )
  }
  onCapitalizeRuleChange() {
    this.capRule = null;
    this.capRule= this.capRuleList.find(
        (obj) => obj.id == this.frmGroup.value.CapitalizationRuleId
      )
  }
  onChargeChange() {
    this.charge = null;
    this.charge= this.chargeList.find(
        (obj) => obj.id == this.frmGroup.value.chargeId
      )
  }
  goBack() {
    this.location.back();
  }

}
