import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleService } from '../../user-service/role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NavbarsService } from 'app/navbars/navbars.service';
import { SharedService } from 'app/services/shared.service';
import { Role } from '../../user-model/role';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.scss']
})
export class RoleCreateComponent implements OnInit {
  acc: any;
  checkedUserMenu: any[] = [];
  userTypeId: string = "";
  userRoles: any[];
  userMenu: any[];
  openTab: string;
  roleName: string;
  
  // Prevent panel toggle code
  public beforeChange($event: NgbPanelChangeEvent) {
    if ($event.panelId === 'preventchange-2') {
      $event.preventDefault();
    }
    if ($event.panelId === 'preventchange-3' && $event.nextState === false) {
      $event.preventDefault();
    }
  };



  model = new Role();
  isEdit: boolean = false;
  roleForm: FormGroup;
  list:Role[]=[];

  constructor(
    private _service:RoleService,
    private changeDetectorRef: ChangeDetectorRef,
    private router:Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
     private _navbarService:NavbarsService,
     private activeRoute: ActivatedRoute,
     private location: Location,
     private shared: SharedService
  )
   {
   
   this.getRoleList()
    this.buildForm();
    }

  ngOnInit(): void {
    //this.getRoleList();

    this.getUserMenuAccess();
    this.changeDetectorRef.detectChanges();
   
  }

  UpdateUserTypeId(userTypeId: string){
    this.userTypeId = userTypeId;
    this.openTab = userTypeId;
    
  }

  getUserMenuAccess() {
    this._navbarService.getAllUserMenu()
      .subscribe((data) => {
        
        this.userMenu = data;
        data.forEach(menu => {
          if(menu.isActive==1){
            this.checkedUserMenu.push(menu);
          }
          menu.submenu.forEach(submenu => {
            if(submenu.isActive==1){
              this.checkedUserMenu.push(submenu);
            }
              submenu.submenu.forEach(child => {
                if(child.isActive==1){
                  this.checkedUserMenu.push(child);
                }
              });
          });
          
        });
        
    });

  }

  getRoleList(){
    this._service.getlist().subscribe(res=>
    {
      
     this.list=res;
    })
  }



  saveUserMenuAccess() {
    
    if (this.checkedUserMenu.length === 0) {
      alert('Please select at least one menu item.');
      return;
    }
  
    const selectedMenuIds = this.checkedUserMenu.map((menu) => menu.id);
    this._navbarService.CreateRoleWithUserAssign(this.userTypeId, selectedMenuIds, this.model.name).subscribe(
      () => {
        this.shared.ShowAlert('Success', 'Save Successfully', 'success');
        this.getUserMenuAccess();
        this.changeDetectorRef.detectChanges();
        location.reload();
      },
      (error) => {
        this.shared.ShowAlert('Warning', 'Unable to Save. Please Try Again', 'warning');
      }
    );
  }



  onCheckboxChange(event: Event, id) {
    const checkedMenu = event.target as HTMLInputElement;
    const menuItem = this.findMenuItemById(this.userMenu, id);
    

    if(checkedMenu.checked){
      menuItem.submenu.forEach(item => {
        item.isActive = true;
        this.checkedUserMenu.push(item);
      });
    }
    else{
      menuItem.submenu.forEach(item => {
        item.isActive = false;
        
        const indexToRemove = this.checkedUserMenu.indexOf(item);

        if (indexToRemove !== -1) {
          this.checkedUserMenu.splice(indexToRemove, 1);
        }
      });
    }
    

    if (checkedMenu.checked) {
      if (menuItem && !this.checkedUserMenu.some((checkedItem) => checkedItem.id === id)) {
        this.checkedUserMenu.push(menuItem);
      }
    } 
    else {
      const index = this.checkedUserMenu.findIndex((checkedItem) => checkedItem.id === id);
        if (index !== -1) {
          this.checkedUserMenu.splice(index, 1);
        }
    }
  }
     
  findMenuItemById(menuItems, itemId) {
    for (const menuItem of menuItems) {
      if (menuItem.id === itemId) {
        return menuItem;
      }
      if (menuItem.submenu && menuItem.submenu.length > 0) {
        const foundItem = this.findMenuItemById(menuItem.submenu, itemId);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return null;
  }




  buildForm() {
    this.roleForm = this.formBuilder.group({
      name: ['', Validators.required],
    
      
    });
  }

  onsubmit() {
 
    
    this._service.save(this.model).subscribe(res => {
     
      if(res!=''){
        alert('Save successfully');
        this.getRoleList();
      }


    })
    this.changeDetectorRef.detectChanges();
  }

  goBack() {
    this.location.back();
  }
}
