import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardCustomService } from "../dashboard-custom.service";
import { PdfService } from "app/pdf-reporting/pdf.service";
import { CustomerService } from "app/customer/customer.service";
import { LoanProductService } from "app/product/service/loan-product.service";
import swal from "sweetalert";
//import { PdfService } from 'app/pdf-reporting/pdf.service';
//import * as Chartist from 'chartist';
// import { ChartType, ChartEvent } from "ng-chartist";
// import ChartistTooltip from 'chartist-plugin-tooltips-updated';

//declare var require: any;

//const data: any = require('../../shared/data/chartist.json');

// export interface Chart {
//   //type: Chart;
//  //data: Chartist.LineChart;
//   //options?: any;
//  // responsiveOptions?: any;
//   //events?: Chartist.ChartRect;
//   // plugins?: any;
// }

@Component({
  selector: "app-dashboard-new",
  templateUrl: "./dashboard-new.component.html",
  styleUrls: ["./dashboard-new.component.scss"],
})
export class DashboardNewComponent implements OnInit {
  UserName: string;

  underProcerssCount: number;
  appliedLoanCount: number;
  approvedLoanCount: number;
  rejectedLoanCount: number;
  finalApproveCount: number;
  dpsPay: number;
  customerList: any[] = [];
  accId: any;
  customerLoan: any[] = [];
  profileUpdate: any;
  productList: any[] = [];
  productLoanList: any[] = [];
  loanCollectionList: any[] = [];
  noDataAvailable: boolean = false;
  constructor(
    private router: Router,
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private pdfService: PdfService,
    private customerService: CustomerService,
    private _loanProductService: LoanProductService
  ) {}

  ngOnInit(): void {
    this.getloanCountInfo();
    this.getCustomerAccount();
    this.getCustomerLoan();
    this.getProfileUpdateInfo();
    this.getLoanProductBuilderInfoList();
    this.getProductBuilderInfoList();
    this.getLoanCollectionList();
  }

  getloanCountInfo() {
    this._serviceDashboard.getCountLoanInfo().subscribe({
      next: (res: any) => {
        this.underProcerssCount = res.leadProcess;
        this.appliedLoanCount = res.appliedLoan;
        this.approvedLoanCount = res.approvedLoan;
        this.rejectedLoanCount = res.rejectedLoan;
        this.finalApproveCount = res.finalApprove;
        this.dpsPay = res.dpsPay;
        this.changeDetectorRef.detectChanges();
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => {},
    });
  }

  getDashboardListByType(type: number) {

    this.router.navigate(["/dashboard/cutomer-lead/" + type]);
  }

  getEMIPayBillList() {
    this.router.navigate(["/dashboard/emi-amount-pay"]);
  }

  getCustomerAccount() {
    this._serviceDashboard.getCustomerAccountByCustomerId().subscribe((res) => {
      if (res.length > 0) {
        this.customerList = res;
        this.changeDetectorRef.detectChanges();
      } else {
        this.noDataAvailable = true;
      }
    });
  }

  getCustomerLoan() {
    this._serviceDashboard.getCustomerLoanAccountInfo().subscribe((res) => {
      this.customerLoan = res;
    });
  }

  getProfileUpdateInfo() {
    this.customerService.getProfileUpdatePercentage().subscribe((res) => {
      this.profileUpdate = res;
    });
    this.changeDetectorRef.detectChanges();
  }

  getLoanProductBuilderInfoList() {
    this._loanProductService.getLoanProductList().subscribe((res) => {
      this.productLoanList = res;
      this.changeDetectorRef.detectChanges();
    });
  }

  getProductBuilderInfoList() {
    this._loanProductService.getProductBuilderList().subscribe((res) => {
      this.productList = res;
    });
  }

  getLoanCollectionList() {
    this._serviceDashboard.getLoanCollection().subscribe((res) => {
      this.loanCollectionList = res;
    });
  }

  PrintKyc(id: number) {
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  // onResized(event: any) {
  //   setTimeout(() => {
  //     this.fireRefreshEventOnWindow();
  //   }, 300);
  // }
  fireRefreshEventOnWindow() {
    throw new Error("Method not implemented.");
  }

  CollentionPDF(leadId: number) {
    this.pdfService.OpenCollectionPdf(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  SchedulePDF(leadId: number) {
    this.pdfService.OpenSchedulePdfForCustomer(leadId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  DPSSchedulePDF(accountId: number) {
    this.pdfService.DPSOpenSchedulePdf(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  // lineArea: Chart = {
  //   type: 'Line',
  //   data: data['lineAreaDashboard'],
  //   options: {
  //     low: 0,
  //     showArea: true,
  //     fullWidth: true,
  //     onlyInteger: true,
  //     axisY: {
  //       low: 0,
  //       scaleMinSpace: 50,
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         pointClass: 'ct-point-regular'
  //       })
  //     ],
  //     axisX: {
  //       showGrid: false
  //     }
  //   },
  //   events: {
  //     created(data: any): void {
  //       var defs = data.svg.elem('defs');
  //       defs.elem('linearGradient', {
  //         id: 'gradient',
  //         x1: 0,
  //         y1: 1,
  //         x2: 1,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': 'rgba(0, 201, 255, 1)'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': 'rgba(146, 254, 157, 1)'
  //       });

  //       defs.elem('linearGradient', {
  //         id: 'gradient1',
  //         x1: 0,
  //         y1: 1,
  //         x2: 1,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': 'rgba(132, 60, 247, 1)'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': 'rgba(56, 184, 242, 1)'
  //       });
  //     },
  //     draw(data: any): void {
  //       if (data.type === 'point') {
  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: 4,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-regular'
  //         });
  //         data.element.replace(circle);
  //       }
  //     }
  //   },
  // };
  // // Line area chart configuration Ends

  // // Stacked Bar chart configuration Starts
  // Stackbarchart: Chart = {
  //   type: 'Bar',
  //   data: data['Stackbarchart'],
  //   options: {
  //     stackBars: true,
  //     fullWidth: true,
  //     axisX: {
  //       showGrid: false,
  //     },
  //     axisY: {
  //       showGrid: false,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     chartPadding: 30
  //   },
  //   events: {
  //     created(data: any): void {
  //       var defs = data.svg.elem('defs');
  //       defs.elem('linearGradient', {
  //         id: 'linear',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': '#7441DB'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': '#C89CFF'
  //       });
  //     },
  //     draw(data: any): void {
  //       if (data.type === 'bar') {
  //         data.element.attr({
  //           style: 'stroke-width: 5px',
  //           x1: data.x1 + 0.001
  //         });

  //       }
  //       else if (data.type === 'label') {
  //         data.element.attr({
  //           y: 270
  //         })
  //       }
  //     }
  //   },
  // };
  // // Stacked Bar chart configuration Ends

  // // Line area chart 2 configuration Starts
  // lineArea2: Chart = {
  //   type: 'Line',
  //   data: data['lineArea2'],
  //   options: {
  //     showArea: true,
  //     fullWidth: true,
  //     lineSmooth: Chartist.Interpolation.none(),
  //     axisX: {
  //       showGrid: false,
  //     },
  //     axisY: {
  //       low: 0,
  //       scaleMinSpace: 50
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         pointClass: 'ct-point-circle'
  //       })
  //     ],
  //   },
  //   responsiveOptions: [
  //     ['screen and (max-width: 640px) and (min-width: 381px)', {
  //       axisX: {
  //         labelInterpolationFnc: function (value, index) {
  //           return index % 2 === 0 ? value : null;
  //         }
  //       }
  //     }],
  //     ['screen and (max-width: 380px)', {
  //       axisX: {
  //         labelInterpolationFnc: function (value, index) {
  //           return index % 3 === 0 ? value : null;
  //         }
  //       }
  //     }]
  //   ],
  //   events: {
  //     created(data: any): void {
  //       var defs = data.svg.elem('defs');
  //       defs.elem('linearGradient', {
  //         id: 'gradient2',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-opacity': '0.2',
  //         'stop-color': 'transparent'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-opacity': '0.2',
  //         'stop-color': '#60AFF0'
  //       });

  //       defs.elem('linearGradient', {
  //         id: 'gradient3',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0.3,
  //         'stop-opacity': '0.2',
  //         'stop-color': 'transparent'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-opacity': '0.2',
  //         'stop-color': '#6CD975'
  //       });
  //     },
  //     draw(data: any): void {
  //       var circleRadius = 4;
  //       if (data.type === 'point') {

  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: circleRadius,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-circle'
  //         });
  //         data.element.replace(circle);
  //       }
  //       else if (data.type === 'label') {
  //         // adjust label position for rotation
  //         const dX = data.width / 2 + (30 - data.width)
  //         data.element.attr({ x: data.element.attr('x') - dX })
  //       }
  //     }
  //   },
  // };
  // // Line area chart 2 configuration Ends

  // // Line chart configuration Starts
  // lineChart: Chart = {
  //   type: 'Line', data: data['LineDashboard'],
  //   options: {
  //     axisX: {
  //       showGrid: false
  //     },
  //     axisY: {
  //       showGrid: false,
  //       showLabel: false,
  //       low: 0,
  //       high: 100,
  //       offset: 0,
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         currency: '$',
  //         pointClass: 'ct-point-circle'
  //       })
  //     ],
  //     fullWidth: true,
  //     offset: 0,
  //   },
  //   events: {
  //     draw(data: any): void {
  //       var circleRadius = 4;
  //       if (data.type === 'point') {
  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: circleRadius,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-circle'
  //         });

  //         data.element.replace(circle);
  //       }
  //       else if (data.type === 'label') {
  //         // adjust label position for rotation
  //         const dX = data.width / 2 + (30 - data.width)
  //         data.element.attr({ x: data.element.attr('x') - dX })
  //       }
  //     }
  //   },

  // };
  // // Line chart configuration Ends

  // // Donut chart configuration Starts
  // DonutChart: Chart = {
  //   type: 'Pie',
  //   data: data['donutDashboard'],
  //   options: {
  //     donut: true,
  //     startAngle: 0,
  //     labelInterpolationFnc: function (value) {
  //       var total = data['donutDashboard'].series.reduce(function (prev, series) {
  //         return prev + series.value;
  //       }, 0);
  //       return total + '%';
  //     }
  //   },
  //   events: {
  //     draw(data: any): void {
  //       if (data.type === 'label') {
  //         if (data.index === 0) {
  //           data.element.attr({
  //             dx: data.element.root().width() / 2,
  //             dy: data.element.root().height() / 2
  //           });
  //         } else {
  //           data.element.remove();
  //         }
  //       }

  //     }
  //   }
  // };
  // // Donut chart configuration Ends

  // //  Bar chart configuration Starts
  // BarChart: Chart = {
  //   type: 'Bar', data: data['DashboardBar'], options: {
  //     axisX: {
  //       showGrid: false,
  //     },
  //     axisY: {
  //       showGrid: false,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     low: 0,
  //     high: 60, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
  //   },
  //   responsiveOptions: [
  //     ['screen and (max-width: 640px)', {
  //       seriesBarDistance: 5,
  //       axisX: {
  //         labelInterpolationFnc: function (value) {
  //           return value[0];
  //         }
  //       }
  //     }]
  //   ],
  //   events: {
  //     created(data: any): void {
  //       var defs = data.svg.elem('defs');
  //       defs.elem('linearGradient', {
  //         id: 'gradient4',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': '#8E1A38'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': '#FAA750'
  //       });
  //       defs.elem('linearGradient', {
  //         id: 'gradient5',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': '#1750A5'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': '#40C057'
  //       });

  //       defs.elem('linearGradient', {
  //         id: 'gradient6',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': '#3B1C93'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': '#60AFF0'
  //       });
  //       defs.elem('linearGradient', {
  //         id: 'gradient7',
  //         x1: 0,
  //         y1: 1,
  //         x2: 0,
  //         y2: 0
  //       }).elem('stop', {
  //         offset: 0,
  //         'stop-color': '#562DB7'
  //       }).parent().elem('stop', {
  //         offset: 1,
  //         'stop-color': '#F55252'
  //       });

  //     },
  //     draw(data: any): void {
  //       var barHorizontalCenter, barVerticalCenter, label, value;
  //       if (data.type === 'bar') {

  //         data.element.attr({
  //           y1: 195,
  //           x1: data.x1 + 0.001
  //         });

  //       }
  //     }
  //   },

  // };
  // // Bar chart configuration Ends

  // // line chart configuration Starts
  // WidgetlineChart: Chart = {
  //   type: 'Line', data: data['Dashboard1_WidgetlineChart'],
  //   options: {
  //     axisX: {
  //       showGrid: false,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     axisY: {
  //       showGrid: false,
  //       low: 40,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         currency: '$',
  //         pointClass: 'ct-point-regular'
  //       })
  //     ],
  //     lineSmooth: Chartist.Interpolation.cardinal({
  //       tension: 0
  //     }),
  //     fullWidth: true
  //   },
  //   events: {
  //     draw(data: any): void {
  //       if (data.type === 'point') {
  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: 4,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-regular'
  //         });
  //         data.element.replace(circle);
  //       }
  //     }
  //   }
  // };
  // // Line chart configuration Ends

  // // line chart configuration Starts
  // WidgetlineChart1: Chart = {
  //   type: 'Line', data: data['Dashboard1_WidgetlineChart1'],
  //   options: {
  //     axisX: {
  //       showGrid: false,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     axisY: {
  //       showGrid: false,
  //       low: 40,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         currency: '$',
  //         pointClass: 'ct-point-regular'
  //       })
  //     ],
  //     lineSmooth: Chartist.Interpolation.cardinal({
  //       tension: 0
  //     }),
  //     fullWidth: true
  //   },
  //   events: {
  //     draw(data: any): void {
  //       if (data.type === 'point') {
  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: 4,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-regular'
  //         });
  //         data.element.replace(circle);
  //       }
  //     }
  //   }
  // };
  // // Line chart configuration Ends

  // // line chart configuration Starts
  // WidgetlineChart2: Chart = {
  //   type: 'Line', data: data['Dashboard1_WidgetlineChart2'],
  //   options: {
  //     axisX: {
  //       showGrid: false,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     axisY: {
  //       showGrid: false,
  //       low: 40,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         currency: '$',
  //         pointClass: 'ct-point-regular'
  //       })
  //     ],
  //     lineSmooth: Chartist.Interpolation.cardinal({
  //       tension: 0
  //     }),
  //     fullWidth: true
  //   },
  //   events: {
  //     draw(data: any): void {
  //       if (data.type === 'point') {
  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: 4,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-regular'
  //         });
  //         data.element.replace(circle);
  //       }
  //     }
  //   }
  // };
  // // Line chart configuration Ends

  // // line chart configuration Starts
  // WidgetlineChart3: Chart = {
  //   type: 'Line', data: data['Dashboard1_WidgetlineChart3'],
  //   options: {
  //     axisX: {
  //       showGrid: false,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     axisY: {
  //       showGrid: false,
  //       low: 40,
  //       showLabel: false,
  //       offset: 0
  //     },
  //     plugins: [
  //       ChartistTooltip({
  //         appendToBody: true,
  //         currency: '$',
  //         pointClass: 'ct-point-regular'
  //       })
  //     ],
  //     lineSmooth: Chartist.Interpolation.cardinal({
  //       tension: 0
  //     }),
  //     fullWidth: true
  //   },
  //   events: {
  //     draw(data: any): void {
  //       if (data.type === 'point') {
  //         var circle = new Chartist.Svg('circle', {
  //           cx: data.x,
  //           cy: data.y,
  //           r: 4,
  //           'ct:value': data.value.y,
  //           'ct:meta': data.meta,
  //           style: 'pointer-events: all !important',
  //           class: 'ct-point-regular'
  //         });
  //         data.element.replace(circle);
  //       }
  //     }
  //   }
  // };
  // Line chart configuration Ends

  onResized(event: any) {
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }
}
