import { Component, OnInit } from '@angular/core';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { RegisterService } from 'app/services/register.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: "app-user-log-history",
  templateUrl: "./user-log-history.component.html",
  styleUrls: ["./user-log-history.component.scss"],
})
export class UserLogHistoryComponent implements OnInit {
  dateFormat: string = "";
  logs: any;
  username: string = "";
  businessDate: any;
  fromDate: string = new Date().toISOString().split("T")[0];
  toDate: string = new Date().toISOString().split("T")[0];

  constructor(
    private login: LoginService,
    private location: Location,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
     var thumb = JSON.parse(localStorage.getItem("thumb"));
     this.dateFormat = thumb.dateFormat;
    // this.GetUserLogs();
    // this.getCurrentBusinessDate();
  }

  getCurrentBusinessDate() {
    this.layoutService.getCurrentBusinessDate().subscribe((res: any) => {
      this.businessDate = res.business_Date;

      this.fromDate = new Date(this.businessDate).toISOString().split("T")[0];
      this.toDate = new Date(this.businessDate).toISOString().split("T")[0];
    });
  }

  GetUserLogs() {
    this.login.GetUserLogs().subscribe((res) => {
      this.logs = res;
    });
  }

  FilterUserLogs() {
    this.login
      .FilterUserLogs(this.username, this.fromDate, this.toDate)
      .subscribe((res) => {
        this.logs = res;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  LoadAllLogs() {
    this.GetUserLogs();
  }
  goBack() {
    this.location.back();
  }


  formatDateFull(date: Date): string {
    const pad = (num: number) => String(num).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
}
