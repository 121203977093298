import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from './payment-cancelled/payment-cancelled.component';
import { TransactionLogListComponent } from './transaction-log-list/transaction-log-list.component';
import { FormsModule } from '@angular/forms';
import { BKashPaymentSuccessComponent } from './b-kash-payment-success/b-kash-payment-success.component';
import { BKashPaymentFailedComponent } from './b-kash-payment-failed/b-kash-payment-failed.component';
import { SMSEmailFormatComponent } from './smsemail-format/smsemail-format.component';
import { SmsFormatDpsComponent } from './sms-format-dps/sms-format-dps.component';
import { ThumbRuleInfoComponent } from './thumb-rule-info/thumb-rule-info.component';


@NgModule({
  declarations: [
    PaymentSuccessComponent,
    PaymentFailedComponent,
    PaymentCancelledComponent,
    TransactionLogListComponent,
    BKashPaymentSuccessComponent,
    BKashPaymentFailedComponent,
    SMSEmailFormatComponent,
    SmsFormatDpsComponent,
    ThumbRuleInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PaymentRoutingModule
  ]
})
export class PaymentModule { }
