import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";

@Component({
  selector: "app-day-book",
  templateUrl: "./day-book.component.html",
  styleUrls: ["./day-book.component.scss"],
})
export class DayBookComponent implements OnInit {
  currentDate = new Date();
  fromDate: string = this.currentDate.toISOString().split('T')[0];
  toDate: string = this.currentDate.toISOString().split('T')[0];
  reportBy: number;
  constructor(private pdfService: PdfService, private location: Location) {}

  ngOnInit(): void {}
  goBack() {
    this.location.back();
  }

  OpenPDF() {
    if(this.reportBy >= 0){
      this.pdfService
      .DayBookReport(this.fromDate, this.toDate, this.reportBy)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
    }
    else{
      alert('Please choose a report type');
    }
  }
}

