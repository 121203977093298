import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { InterestProcessService } from '../interest-process.service';
import { SharedService } from 'app/services/shared.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-daily-close',
  templateUrl: './daily-close.component.html',
  styleUrls: ['./daily-close.component.scss']
})
export class DailyCloseComponent implements OnInit {
  types: string ;
  fromDate: any = Date;
  toDate: any = Date;
  interval: any;
  dateInfo: any = [];
  businessDate: string;

  constructor(
    private ledgerService:LedgerService,
    private changeDetectorRef:ChangeDetectorRef,
    private interestProcess:InterestProcessService,
    private shared: SharedService,
    private datePipe: DatePipe
  ) 
  { 

  }

  ngOnInit(): void {
    this.ledgerService.GetLastAciteBusinessDate().subscribe(res=>{
      this.dateInfo = res;
      this.fromDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(this.dateInfo.business_Date, 'yyyy-MM-dd');
        this.changeDetectorRef.detectChanges();
     })


     this.shared.LastBusinessDate().subscribe(res => {
    
      
      const date = new Date(res.business_Date);
      this.businessDate = this.datePipe.transform(date, 'yyyy-MM-dd');
     })
  }

  InterestProcess(){
    alert(this.types)
    this.interestProcess.processInterest(this.types,this.fromDate,this.toDate).subscribe(res => {
      if(res!=null)
      {
        this.shared.ShowAlert('Thank you...', 'Process succesfully!', 'success');
      } 
      else{
        this.shared.ShowAlert('warning', 'Something went wrong!', 'Try again.');
      } 
    
     
    })
  }

  
  
  
  
  
 
 
}
