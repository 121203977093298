import { Component, OnInit } from '@angular/core';
import { SmsEmailFormat } from '../models/sms-email-format';
import { PaymentService } from '../payment.service';
import { ThumbRule } from '../models/thumb-rule';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-thumb-rule-info',
  templateUrl: './thumb-rule-info.component.html',
  styleUrls: ['./thumb-rule-info.component.scss']
})
export class ThumbRuleInfoComponent implements OnInit {
  selectedFile: File | null = null;

  thumbRUle: ThumbRule = {
     id: null,
     orgName: "",
     orgAddress: "",
     orgPhone: "",
     orgEmail: "",
     sessionTimeMin: 0,
     enableBanglaSMS: 0,
     enableEnglishSMS: 0,
     enableBanglaEmail: 0,
     enableEnglishEmail: 0,
     isEODAutoProcess: 0,
     autoProcessTime: null,
     allowNewTab:false,
     allowMultipleLogin: false,
     reportType: "",
     dateFormat: "dd-MM-yyyy",
     dpsAccountPrefix: "",
     dpsAccountMid:"",
     dpsAccountSuffix: ""
    };

  dateFormats: string[] = [
    "dd-MM-yyyy",    // Example: 14-09-2024
    "dd-MMM-yyyy",   // Example: 14-Sep-2024
    "MM/dd/yyyy",    // Example: 09/14/2024
    "MM-dd-yyyy",    // Example: 09-14-2024
    "yyyy-MM-dd",    // Example: 2024-09-14
    "MMM d, yyyy",   // Example: Sep 14, 2024
    "MMMM d, yyyy",  // Example: September 14, 2024
    "yyyy/MM/dd",    // Example: 2024/09/14
    "dd/MM/yyyy",    // Example: 14/09/2024
    "yyyy.MM.dd",    // Example: 2024.09.14
    "d MMM yyyy",    // Example: 14 Sep 2024
  ]

  constructor(private formatService: PaymentService, private pdfService: PdfService, private http: HttpClient) { }

  ngOnInit() {
    this.GetThumRule();
  }

  GetThumRule() {
    this.formatService.GetThumbRule().subscribe(res => {
      this.thumbRUle =  {
        id: res.id,
        orgName: res.orgName,
        orgAddress: res.orgAddress,
        orgPhone: res.orgPhone,
        orgEmail: res.orgEmail,
        sessionTimeMin: res.sessionTimeMin,
        enableBanglaSMS: res.enableBanglaSMS,
        enableEnglishSMS: res.enableEnglishSMS,
        enableBanglaEmail: res.enableBanglaEmail,
        enableEnglishEmail: res.enableEnglishEmail,
        isEODAutoProcess: res.isEODAutoProcess,
        autoProcessTime: res.autoProcessTime,
        allowNewTab:res.allowNewTab,
        allowMultipleLogin: res.allowMultipleLogin,
        reportType: res.reportType,
        dateFormat: res.dateFormat,
        dpsAccountPrefix: res.dpsAccountPrefix,
        dpsAccountMid: res.dpsAccountMid,
        dpsAccountSuffix: "00000001"
      };
    });
  }

  Submit(): void {
    if (this.ValidateForm()) {
      // Create FormData object to handle both file and form data
      const formData = new FormData();
      formData.append('id', this.thumbRUle.id);
      formData.append('orgName', this.thumbRUle.orgName || '');
      formData.append('orgAddress', this.thumbRUle.orgAddress || '');
      formData.append('orgPhone', this.thumbRUle.orgPhone || '');
      formData.append('orgEmail', this.thumbRUle.orgEmail || '');
      formData.append('sessionTimeMin', String(this.thumbRUle.sessionTimeMin) || '');
      formData.append('enableBanglaSMS', String(this.thumbRUle.enableBanglaSMS) || '');
      formData.append('enableEnglishSMS', String(this.thumbRUle.enableEnglishSMS) || '');
      formData.append('enableBanglaEmail', String(this.thumbRUle.enableBanglaEmail) || '');
      formData.append('enableEnglishEmail', String(this.thumbRUle.enableEnglishEmail) || '');
      formData.append('isEODAutoProcess', String(this.thumbRUle.isEODAutoProcess) || '');
      formData.append('autoProcessTime', String(this.thumbRUle.autoProcessTime) || '');
      formData.append('allowNewTab', String(this.thumbRUle.allowNewTab) || '');
      formData.append('allowMultipleLogin', String(this.thumbRUle.allowMultipleLogin) || '');
      formData.append('reportType', this.thumbRUle.reportType || '');
      formData.append('dateFormat', this.thumbRUle.dateFormat || '');
      formData.append('dpsAccountPrefix', this.thumbRUle.dpsAccountPrefix || '');
      formData.append('dpsAccountMid', this.thumbRUle.dpsAccountMid || '');
      formData.append('dpsAccountSuffix', this.thumbRUle.dpsAccountSuffix || '');

      if (this.selectedFile) {
        formData.append('manualFile', this.selectedFile);
      }

      // this.formatService.SaveThumbRule(this.thumbRUle).subscribe(
      //   () => {
      //     Swal.fire({
      //       title: "Success",
      //       text: "Thumb Rule Updated Successfully!",
      //       icon: "success",
      //       showCancelButton: false,
      //       confirmButtonText: "Ok",
      //     })
      //     this.GetThumRule();
      //   },
      //   (error) => {
      //     Swal.fire({
      //       title: "Warning",
      //       text: "Failed to update Rule!",
      //       icon: "warning",
      //       showCancelButton: false,
      //       confirmButtonText: "Ok",
      //     })
      //   }
      // );
      // Replace 'YOUR_API_URL' with the actual URL of your API endpoint
      this.http.post(environment.baseUrl + '/api/SaveThumbRule', formData).subscribe(
        () => {
          Swal.fire({
            title: "Success",
            text: "Thumb Rule Updated Successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
          this.GetThumRule(); // Assuming this method refreshes the data
        },
        (error) => {
          Swal.fire({
            title: "Warning",
            text: "Failed to update Rule!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        }
      );
    }
  }



  OpenPDF() {
    this.pdfService
      .GenerateThumbPdf()
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }



  ValidateForm(): boolean {
    if(this.thumbRUle.dpsAccountPrefix == null || this.thumbRUle.dpsAccountPrefix == ''){
      Swal.fire({
        title: "Warning",
        text: "Account Prefix is mandatory!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      return false;
    }
    else if(this.thumbRUle.dpsAccountMid == null || this.thumbRUle.dpsAccountMid == ''){
      Swal.fire({
        title: "Warning",
        text: "Account Mid is mandatory!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      return false;
    }
    else if(this.thumbRUle.dpsAccountSuffix == null || this.thumbRUle.dpsAccountSuffix == ''){
      Swal.fire({
        title: "Warning",
        text: "Account Suffix is mandatory!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      return false;
    }
    else{
      return true;
    }
  }


  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  onAutoCOBChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;

    console.log('Selected value:', selectedValue);
    if (selectedValue === '1') {
      this.thumbRUle.isEODAutoProcess = 1;
    } else if (selectedValue === '0') {
      this.thumbRUle.isEODAutoProcess = 0;
    }
  }


}
