import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl;
@Injectable({
  providedIn: "root",
})
export class VoucherInfoService {
  constructor(private http: HttpClient) {}
  getVoucherMasterWithDetailsList(): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CashTransaction/GetVouchermasterInfoWithDetails"
    );
  }

  getVoucherMasterList(): Observable<any> {
    return this.http.get(apiUrl + "/api/CashTransaction/GetVouchermasterInfo");
  }

  getVoucherMasterListWithSearchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string,
    fromDate: any,
    toDate: any
  ) {
    return this.http.get(
      apiUrl +
        "/api/CashTransaction/GetVoucherMasterListWithSearchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
  }

  getVoucherMasterListFromDateToDate(fromDate: Date, toDate: Date) {
    return this.http.get(
      apiUrl +
        "/api/CashTransaction/GetVoucherMasterListFromDateToDate?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate
    );
  }

  getVoucherDetailsByMasterId(id: any): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CashTransaction/GetVoucherDetailsInfoByMasterId?id=" + id
    );
  }


  getCustomerWiseBreakDown(vId: any): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CashTransaction/getCustomerWiseBreakDown?vId=" + vId
    );
  }


  getDailyBreakDown(vId: any, accId: number): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CashTransaction/getDailyBreakDown?vId=" + vId + "&accId=" + accId
    );
  }

  approveVoucherMasterById(id: any): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CashTransaction/AppvoveVoucherMasterByMasterId?id=" + id
    );
  }

  SaveVoucher(model: any) {
   
    return this.http.post(apiUrl + "/api/CashTransaction/SaveVoucher", model);
  }
  SaveJournalVoucher(model: any) {
   
    return this.http.post(
      apiUrl + "/api/CashTransaction/SaveJournalVoucher",
      model
    );
  }
}
