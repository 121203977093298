<div class="card" style="width: 100%;">
    <div class="card-body">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <h5 class="card-title" style="color: black">Product List</h5>
                </div>

            <div class="col-3" style="text-align: right;">
                <button type="button" data-toggle="tooltip" [routerLink]="['/product/product-builder/0']" class="btn btn-info"
                    style="float:right;margin-right:5px;margin-left: 8px;">Product Entry</button>
            </div>
            <div class="col-1" style="text-align: right;">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>

            </div>
            <hr>
            <div class="card-body p-0" style="overflow: auto; max-height: 700px;">
            <table  id="example" class="table table-striped table-bordered scrollable-table"
                style="width:100%">
                <thead style="position: sticky; top:-2px; z-index: 1; background-color: #EEF5E9">
                    <tr>
                        <th>SL No.</th>
                        <th style="width: 20%;">Action</th>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th>Version</th>
                        <th>Effective Date</th>
                        <th>Status</th>
                        <th>Approve Status</th>
                       
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of list; let i = index;">
                        <td>{{i + 1}} .</td>
                        <td style="width: 20%;">
                            <button class="btn btn-info btn-sm mb-1" style="margin-left: 5px;" (click)="openBuilderDetailsModal(productBuilderDetails)"
                                (click)="getProductDetails(item.id)"><i class="fa fa-list"></i></button>
                            <button
                                *ngIf="item.createdBy == userName && item.productStatusId != 6 && item.productStatusId != 2 && item.productStatusId !=3"
                                class="btn btn-primary btn-sm mb-1" style="margin-left: 5px;" (click)="edit(item)"><i class="fa fa-edit"></i></button>
                            <button *ngIf="item.createdBy == userName && item.productStatusId == 3 && item.status ==0" [disabled]="isEdit"
                                class="btn btn-secondary mb-1 btn-sm" style="margin-left: 5px;"
                                (click)="delete(item.id,item.status === true|| item.status===null? false : true)"><i
                                    class="fa fa-times"></i></button>
                        </td>
                        <td>{{item.code}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.version}}</td>
                        <td style="white-space: nowrap;">{{item.effectiveDate | date:'dd MMM yyyy' }}</td>
                        <td style="width: 8%; text-align: center;font-size: 10px;">
                          <span class="badge badge-{{item.status == 1 ? 'success' : 'danger'}}">{{item.status === true|| item.status===null? 'Active' : "In-Active"}}</span>
                        </td>
                        <td style="width: 8%;">
                          <!-- <span class="{{item.productStatusId == 4 ? 'red-underline' : ''}}">{{item.productStatus?.statusName}}</span> -->

                            <span [ngClass]="{ 'red-underline': item.productStatusId == 4 }"
                                [ngStyle]="{ 'color': item.productStatusId == 3 ? 'green' : 'inherit' }">
                                {{ item.productStatus?.statusName }}
                            </span>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</div>



<ng-template #productBuilderDetails let-c="close" let-d="dismiss">
    <div class="modal-dialog">
        <!-- Modal content goes here -->
        <div class="modal-content" style="margin-left: -8%; width: 110%;">
            <div class="modal-header">
                <h4 class="modal-title">Product Builder Details</h4>
                <button type="button" class="close" style="size: 150%;" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <table class="table-bordered">
                <th class="col-3">Product Name </th>
                <td class="col-3">{{ productDetails?.productInfo?.name }}</td>

                <th class="col-3">Product Category </th>
                <td class="col-3">{{ productDetails?.productInfo?.categoryName}}</td>



            </table>
            <table class="table-bordered">

                <th class="col-3">Version: </th>
                <td class="col-3">{{ productDetails?.productInfo?.version }}</td>
                <th class="col-3">Product Code: </th>
                <td class="col-3">{{ productDetails?.productInfo?.code }}</td>

            </table>

            <table class="table-bordered">
                <th class="col-3">Description: </th>
                <td class="col-3">{{ productDetails?.productInfo?.description }}</td>
                <th class="col-3">Interest Rate: </th>
                <td class="col-3">{{productDetails?.productInfo?.interestRate}} <span>%</span> </td>
            </table>

            <table class="table-bordered">
                <th class="col-3">Min Int. Rate:</th>
                <td class="col-3">{{ productDetails?.productInfo?.interestRateMin }} <span>%</span></td>
                <th class="col-3">Max Int. Rate:</th>
                <td class="col-3">{{productDetails?.productInfo?.interestRateMax}} <span>%</span> </td>
            </table>


            <table class="table-bordered">
                <th class="col-3">Minimum Amount:</th>
                <td class="col-3">{{ productDetails?.productInfo?.minAmount}}</td>
                <th class="col-3">Maximum Amount:</th>
                <td class="col-3">{{productDetails?.productInfo?.maxAmount}}</td>
            </table>





            <table class="table-bordered">
                <th class="col-3">Currency: </th>
                <td class="col-3">{{ productDetails?.productInfo?.currency?.currency }}</td>
                <th class="col-3">Day Size: </th>
                <td class="col-3">{{ productDetails?.productInfo.daySize?.name }}</td>
            </table>
            <table class="table-bordered">
                <th class="col-3">Tax Key: </th>
                <td class="col-3">{{ productDetails?.productInfo.taxKey?.taxKeyName }}</td>
                <th class="col-3">Excise Duty: </th>
                 <td class="col-3">{{ productDetails?.productInfo?.exciseDuty?.exciseDutyName }}</td>

            <!-- <td class="col-3">
                <ul>
                    <li *ngFor="let item of productDetails?.exciseDutyDetails">
                       <span>Excise Amount: {{ item.dutyAmount }}</span> <br>
                       <span>Amount Range: {{ item.minAmountRange }} -({{ item.maxAmountRange }})</span>

                    </li>
                </ul>
            </td> -->
            </table>
            <table class="table-bordered">
                <th class="col-3">Interest Calculation Type</th>
                <td class="col-3">{{ productDetails?.productInfo?.interestCalculationType?.name }}</td>
                <!-- <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productInstCalTypes">
                            {{item.slab.min}}-{{item.slab.max}} ({{item.slab.amountParcent}})%
                        </li>
                    </ul>
                </td> -->


                <th class="col-3">Interest Calculation Frequency: </th>
                <td class="col-3">{{productDetails?.productInfo?.interestCalculationFrequency?.name }} -
                    {{productDetails?.productInfo?.interestCalculationFrequency?.days }}
                    {{productDetails?.productInfo?.interestCalculationFrequency?.days !=null? "days" : "" }}</td>
            </table>
            <!-- <table class="table-bordered">
                <th class="col-3">Capitalize Rule:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productRules"> {{item.rule?.code}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Capitalization Frequency:</th>
                <td class="col-3">
                    {{productDetails?.productInfo?.capitalizationFrequency?.name }} - {{
                    productDetails?.productInfo?.capitalizationFrequency?.days }} {{
                    productDetails?.productInfo?.capitalizationFrequency?.days !=null? "days" :
                    ""}}
                </td>
            </table> -->
            <table class="table-bordered">
                <th class="col-3">Balance Type:</th>
                <td class="col-3">{{ productDetails?.productInfo?.balanceType?.name }}</td>
                <th class="col-3">Status:</th>
                <td class="col-3">
                    {{ productDetails?.productInfo?.status? "Active" :
                    "In-Active"}}
                </td>
            </table>
            <!-- <table class="table-bordered">
                <th class="col-3">Charge:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productCharge">
                            {{item.charge?.code}}-{{item.charge?.name}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Commission:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.productCommission">
                            {{item.commission?.code}}-{{item.commission?.name}}
                        </li>
                    </ul>
                </td>
            </table> -->

            <table class="table-bordered">
                <th class="col-3">Commission:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.commissions">
                            {{item.commission?.code}}-{{item.commission?.name}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Premature Encashment Rate:</th>
                <td class="col-3">
                    {{productDetails?.productInfo?.bankRate}}
                    <span>%</span>
                    <!-- <ul>
                        <li *ngFor="let item of productDetails?.productCharge">
                            {{item.charge?.code}}-{{item.charge?.name}}
                        </li>
                    </ul> -->
                </td>

            </table>


            <table class="table-bordered">
                <th class="col-3">Tenures:</th>
                <td class="col-3">
                    <ul>
                        <li *ngFor="let item of productDetails?.dpsTenures">
                            {{item.duration?.monthName}}
                        </li>
                    </ul>
                </td>
                <th class="col-3">Effective Date:</th>
                <td class="col-3">
                    {{productDetails?.productInfo?.effectiveDate | date}}

                </td>

            </table>

            <table class="table-bordered">

              <th class="col-3">Created Date:</th>
              <td class="col-3">
                {{productDetails?.productInfo?.productCreateDate | date}}
              </td>
              <th class="col-3">Modification Date:</th>
              <td class="col-3">
                {{productDetails?.productInfo?.modificationDate | date}}
              </td>
          </table>
          <br>
          <br>
          <hr>
          <br>
          <br>
    <table class="table table-bordered" style="border-color:black; font-size: 13px;" >
        <thead style="background-color:lightgray;border-bottom:groove;">
            <tr>
                <th style="text-align:left;vertical-align:middle">Event Name</th>
                <th style="text-align:left;vertical-align:middle">GL Name</th>
                <th style="text-align:left;vertical-align:middle">Type</th>
            </tr>
        </thead>
        <tbody style="color:black;border-color:black;">

            <tr *ngFor="let item of productDetails?.productCharges">
                <td style="text-align: left; vertical-align:middle">{{item?.productEvent?.eventName}}</td>
                <td style="text-align: left; vertical-align:middle">{{item?.ledgerRelation?.ledger?.accountName}}</td>
                <td style="text-align: left; vertical-align:middle">{{item?.glType == 'DR' ? 'Debit' : 'Credit'}}</td>
            </tr>

        </tbody>
    </table>



            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="download()">Download</button>
                <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
            </div>
        </div>
    </div>
</ng-template>
