<div class="container">
  <div class="card">
    <div class="card-body">
      <!-- Form for adding/editing -->
      <div class="mt-4">
        <h3>Add New Format</h3>
        <form (ngSubmit)="saveFormat()">
          <div class="form-group row">
            <div class="col-md-6">
              <label for="particular">Particular:</label>
              <select id="particular" [(ngModel)]="newFormat.particular" (change)="changeFormats($event.target.value)" name="particular" class="form-control" required>
                <option value="">Choose</option>
                <option value="CUSTOMER REG">CUSTOMER REG</option>
                <option value="LOAN DISBURSEMENT">LOAN DISBURSEMENT</option>
                <option value="LOAN PAYMENT">LOAN PAYMENT</option>
                <option value="LOAN CLOSE">LOAN CLOSE</option>
                <option value="OVERDUE REMINDER">OVERDUE REMINDER</option>
                <option value="REPAYMENT REMINDER">REPAYMENT REMINDER</option>
                <option value="APPLICATION APPROVAL">APPLICATION APPROVAL</option>
                <option value="APPLICATION REJECTED">APPLICATION REJECTED</option>
                <option value="APPLICATION SUBMISSION">APPLICATION SUBMISSION</option>
              </select>
            </div>
            <div class="col-md-6">
              <label for="subject">Subject:</label>
              <input type="text" id="subject" [(ngModel)]="newFormat.subject" name="subject" class="form-control" required>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="type">Type:</label>
            <input type="text" id="type" [(ngModel)]="newFormat.type" name="type" class="form-control" required>
            </div>
            <div class="col-md-6">
              <label for="status">Status:</label>
            <select id="status" [(ngModel)]="newFormat.status" name="status" class="form-control" required>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            </div>
          </div>
          <div class="form-group">
            <label for="subject">Body:</label>
            <textarea id="body" [(ngModel)]="newFormat.body" name="body" class="form-control" required cols="30" rows="5"></textarea>
            <p style="color: darkred; margin: 0; font-weight: bold; font-size: 11px;">{{placeholder}}</p>
            <small style="margin: 0; font-size: 12px;">Replace parameters with <span [innerHTML]="'{0}'"></span>, <span [innerHTML]="'{1}'"></span> or <span [innerHTML]="'{2}'"></span> </small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'{0}'"></span> = 1<sup>st</sup> Parameter</small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'{1}'"></span> = 2<sup>nd</sup> Parameter</small><br>
            <small style="margin: 0; font-size: 12px;"><span [innerHTML]="'{2}'"></span> = 3<sup>rd</sup> Parameter</small>
          </div>
          <div class="form-check">
            <input type="checkbox" id="isHTML" [(ngModel)]="newFormat.isHTML" name="isHTML" class="form-check-input">
            <label class="form-check-label" for="isHTML">Is HTML</label>
          </div>
          <button type="submit" class="btn btn-success">Save</button>
        </form>
      </div>
    </div>

    <div class="card-body">
      <!-- List of formats -->
      <div class="mt-5">
        <h3>Existing Formats</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Particular</th>
              <th>Subject</th>
              <th>Body</th>
              <th>Type</th>
              <th>Module</th>
              <th>EN/BN</th>
              <th>Is HTML</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let format of formats">
              <td><b>{{ format.particular }}</b></td>
              <td>{{ format.subject }}</td>
              <td style="font-size: 12px;">{{ format.body }}</td>
              <td><b>{{ format.type }}</b></td>
              <td><b>{{ format.module }}</b></td>
              <td><b>{{ format.lnCode }}</b></td>
              <td>{{ format.isHTML ? 'Yes' : 'No' }}</td>
              <td>{{ format.status === 1 ? 'Active' : 'Inactive' }}</td>
              <td>
                <button (click)="editFormat(format)" class="btn btn-sm btn-primary">Edit</button>
                <button (click)="deleteFormat(format.id)" class="btn btn-sm btn-danger">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
