import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-trial-balance-report",
  templateUrl: "./trial-balance-report.component.html",
  styleUrls: ["./trial-balance-report.component.scss"],
})
export class TrialBalanceReportComponent implements OnInit {
  fromDate: Date = new Date();
  toDate: Date = new Date();
  reportBy: number=2;
  selectedOption: number;

  constructor(private pdfService: PdfService, private location: Location) {
    this.selectedOption = 1;
  }

  ngOnInit(): void { 


  }

  handleOptionChange(event: any) {
   // console.log('Selected option:', this.selectedOption);
    // Perform any other actions based on the selected option
  }

  OpenPDF() {
    if (this.selectedOption == 1) {
      this.pdfService
        .TrialBalanceReport(this.fromDate, this.toDate, 1, 2, this.reportBy)
        .subscribe((res) => {
          let blob: Blob = res.body as Blob;
          let url = window.URL.createObjectURL(blob);

          window.open(url);
        });
    }
    if (this.selectedOption == 2) {
      this.pdfService
        .TrialBalanceReport2(this.fromDate, this.toDate, 1, 2, this.reportBy)
        .subscribe((res) => {
          let blob: Blob = res.body as Blob;
          let url = window.URL.createObjectURL(blob);

          window.open(url);
        });
    }

  }
  goBack() {
    this.location.back();
  }
}
