import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchService } from 'app/services/batch.service';
import { BatchDetailAddComponent } from '../batch-detail-add/batch-detail-add.component';

@Component({
  selector: 'app-batch-detail-modal',
  templateUrl: './batch-detail-modal.component.html',
  styleUrls: ['./batch-detail-modal.component.scss']
})
export class BatchDetailModalComponent implements OnInit {

  @Input() data: any;
  @Input() master: any;

  myForm: FormGroup;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private batchService: BatchService, private modalService: NgbModal) { }

  ngOnInit(): void {
   

    // this.myForm = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    // });
  }

  closeModal(): void {
    this.activeModal.close('Modal closed');
  }

  onSubmit(): void {
    if (this.myForm.valid) {
      this.activeModal.close('Form submitted');
    } else {
    }
  }

  handleButtonClick(action: string): void {
    if (action === 'save') {
      this.onSubmit();
    } else if (action === 'cancel') {
      this.closeModal();
    }
  }

  ngOnDestroy(): void {
  }

  removeItem(item: any): void {
    this.batchService.removeBatchDetail(item.id).subscribe(
      (data: any) => {
        alert('Deleted Successfully!');
        this.getBatchDetailByMasterId(this.master.id);
      },
      error => {
        console.error('Error fetching batch list:', error);
      }
    );
  }


  AddItem() {
    this.openModal(this.master.id);
  }

  openModal(id: number) {
    const modalRef = this.modalService.open(BatchDetailAddComponent, { centered: true });
    modalRef.componentInstance.masterId = id;

    modalRef.result.then(
      (result) => {
        this.getBatchDetailByMasterId(id);
      },
      (reason) => {
        console.log('Modal dismissed with reason:', reason);
      }
    );
  }


  getBatchDetailByMasterId(id:number){
    this.batchService.getBatchDetailByMasterId(id).subscribe(
      (res: any) => {
         this.data = res;
      },
      error => {
        console.error('Error fetching batch list:', error);
      }
    );
  }



}



