import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
const apiUrl=environment.baseUrl + '/api/CapitalizationRule/'
@Injectable({
  providedIn: "root",
})
export class CapitalizationRuleService {
  constructor(private http: HttpClient) {}

  //#region Capitalization
  getlist(): Observable<any> {
    return this.http.get(apiUrl + "GetAllCapitalizationRuleList");
  }
  getlistByProBuilderType(id: number): Observable<any> {
    return this.http.get(apiUrl + "GetAllCapRuleListByProBuilderType?id=" + id);
  }

  GetCapitalizationById(id: number) {
    return this.http.get(apiUrl + "GetCapitalizationRuleById");
  }
  GetProductWiseIntaCalculationRuleId(id: number) {
    return this.http.get(apiUrl + "GetProductWiseIntaCalculationRuleId/" + id);
  }

  SaveCapitalization(model: any) {
    return this.http.post(apiUrl + "SaveCapitalizationRule", model);
  }
  getListWithSerchAndPagination(
    currentPage: any,
    itemsPerPage: any,
    searchValue: string
  ) {
    return this.http.get(
      apiUrl +
        "GetCapitalListWithSerchAndPagination?page=" +
        currentPage +
        "&pageSize=" +
        itemsPerPage +
        "&search=" +
        searchValue
    );
  }
  DeleteCapitalization(id: any) {
    return this.http.delete(apiUrl + "DeleteCapitalizationRule/" + id);
  }

  GetCapitalizationRuleById(id: number) {
    return this.http.get(apiUrl + "GetCapitalizationRuleById");
  }
  //#endregion

  //Tenure

  getAutoCodeForDuration() {
    return this.http.get(apiUrl + "GetAutoCodeForDuration");
  }
  SaveDuration(model: any) {
    return this.http.post(apiUrl + "SaveDurationinfo", model);
  }

  DeleteDuration(id: any) {
    return this.http.delete(apiUrl + "DeleteDurationById/" + id);
  }

  GetDurationById(id: number) {
    return this.http.get(apiUrl + "GetDurationInfoById/" + id);
  }

  getAllDurationlist(): Observable<any> {
    return this.http.get(apiUrl + "GetAllDurationListInfo");
  }
  //

  //MasterBank
  SaveMasterBank(model: any) {
    return this.http.post(apiUrl + "SaveMasterBankInfo", model);
  }

  DeleteMasterBank(id: any) {
    return this.http.delete(apiUrl + "DeleteMasterBankById/" + id);
  }

  GetMasterBanknById(id: number) {
    return this.http.get(apiUrl + "GetMasterBankInfoById/" + id);
  }

  getAllMasterBanklist(): Observable<any> {
    return this.http.get(apiUrl + "GetAllMasterBankListInfo");
  }
  //

  //BankBranch
  SaveBankBranchesInfo(model: any) {
    return this.http.post(apiUrl + "SaveBankBranchesInfo", model);
  }

  DeleteBankBranchById(id: any) {
    return this.http.delete(apiUrl + "DeleteBankBranchById/" + id);
  }

  GetBankBranchesInfoById(id: number) {
    return this.http.get(apiUrl + "GetBankBranchesInfoById/" + id);
  }

  GetAllBankBranchList(): Observable<any> {
    return this.http.get(apiUrl + "GetAllBankBranchListInfo");
  }
  //
}
