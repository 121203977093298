import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { FdrdetailsService } from 'app/fdr-account/fdrdetails.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';

import { Location } from "@angular/common";

@Component({
  selector: "app-dps-list",
  templateUrl: "./dps-list.component.html",
  styleUrls: ["./dps-list.component.scss"],
})
export class DpsListComponent implements OnInit {
  customerList: any[] = [];
  constructor( private location: Location,
    private _service: CustommeraccountService,
    private pdfService: PdfService,
    private _fdrService: FdrdetailsService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this .getCustomerAccount();
  }

  DPSAccountBreakdownPDF(accountId: number) {
    this.pdfService.DPSAccountBreakdownPDF(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  getCustomerAccount() {
    this._serviceDashboard.getCustomerAccountByCustomerId().subscribe((res) => {
      
      if (res.length > 0) {
    
        this.customerList = res.filter(
          (customer) => customer.accountType === "DPS"
        );
        this.changeDetect.detectChanges();
      }
    });
  }

  PrintKyc(id: number) {
 
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  DPSSchedulePDF(accountId: number) {
    this.pdfService.DPSOpenSchedulePdf(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  goBack() {
    this.location.back();
  }
}
