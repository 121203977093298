<div class="card" style="width: 100%;">
  <div class="card-body">
    <div class="container">
      <div class="row">
        <label style="color: black;" class="col-md-1">ROLE</label>
        <div class="col-md-6">
          <!-- <h5 class="card-title" style="color: black">User Customer List</h5> -->
          <select class="form-control" (change)="onRoleChange($event)">
            <option value="">Choose Role</option>
            <option *ngFor="let item of roleList" value="{{item.id}}">{{item.name}}</option>
          </select>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-1">
          <button class="btn" style="background-color: #8888;"> <a routerLink="/employeeregister/employeeregister" style="color: white;font-weight: 700;">REGISTER</a></button>
        </div>
      </div>


      <hr>

      <div class="row" style="margin-bottom: 20px;">
        <div class="col-md-6" style="text-align: left;">
          <select type="number" (change)="onChangeForShow($event.target.value)" class="form-control" style="width:16%">
            <option value="5" selected>5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="col-md-6" style="text-align: right;">
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl" (keyup)="onSearch()">
        </div>
      </div>
      <table id="example" class="table table-striped table-bordered table-sm" style="width:100%">
        <thead>
          <tr>
            <th>SL No.</th>
            <th>Image</th>
            <th>Full Name</th>
            <th>User Name</th>
            <th>Mobile No</th>
            <th>Email Address</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of users | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index"
            [ngStyle]="{'background-color': item.isActive === 0 ? 'burlywood' : ''}">
            <td>{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>
            <td><img class="avatar" src="data:image/jpeg;base64,{{item.baseString}}" alt="avatar" height="35"
                width="35" /></td>

            <td>{{item.fullName }}</td>
            <td>{{item.userName}}</td>
            <td>{{item.phoneNumber}}</td>
            <td>{{item.email}}</td>
            <!-- <td>{{ item.isActive === 0 ? 'InActive User' : 'Active User' }}</td> -->
            <td>{{ item.isActive == 0 ? 'InActive User': 'Active User' }}</td>
            <td>
              <!-- <button class="btn btn-info btn-sm mb-1"  [ngStyle]="{'background-color': item.isActive === 0 ? 'red' : 'green'}" type="button" (click)="openInactive(inactivecontent, item)" (click)="toggleActiveStatus(item)" style="margin-left: 5px;" >{{ item.isActive === 0 ? 'InActive User' : 'Active User' }}</button> -->
              <button class="btn btn-primary btn-sm mb-1" title="Assign Roles" type="button"
                (click)="OpenRoleModal(assignRoleModal,item)" style="margin-left: 5px;"><i class="fa fa-get-pocket"></i></button>
              <button class="btn btn-warning btn-sm mb-1" title="Assign Action" type="button"
                (click)="OpenActionModal(assignActionModal,item)" style="margin-left: 5px;"><i class="fa fa-unlock "></i></button>

                <button class="btn btn-primary btn-sm mb-1" title="Accessible Days" type="button"
                (click)="OpenAccessibleModal(accessibleModal,item)" style="margin-left: 5px;"><i class="fa fa-clock-o"></i></button>

              <button class="btn btn-secondary btn-sm mb-1" title="Reset Password" type="button"
                (click)="openReset(resetcontent,item)" style="margin-left: 5px;"><i class="fa fa-key "></i></button>

              <button class="btn btn-info btn-sm mb-1" *ngIf="item.isActive==1" title="InActive User" type="button"
                (click)="openInactive(inactivecontent, item)" (click)="toggleActiveStatus(item)"
                style="margin-left: 5px;">
                <i class="fa fa-ban "></i>
              </button>
              <button class="btn btn-primary btn-sm mb-1" *ngIf="item.isActive==0" title="Active User" type="button"
                (click)="openactive(activecontent, item)" (click)="toggleActiveStatus2(item)" style="margin-left: 5px;">
                <i class="fa fa-check "></i>
              </button>
              <button class="btn btn-success btn-sm mb-1" title="Switched User" type="button"
                (click)="open(userDetails, item)" style="margin-left: 5px;"><i
                  class=" fa fa-solid fa-user"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-md-6" style="text-align: left">
          <span>Showing {{currentItems}} of {{totalItems}} entries</span>
        </div>
        <div class="col-md-6" style="text-align: right">
          <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #assignRoleModal let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">
      <form [formGroup]="formGroupReset">

        <div class="modal-header">
          <h4 class="modal-title">Assign Role to User</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 row" style="padding-left: 100px;">
              <div *ngFor="let item of assignedRoles" class="col-md-12 custom-switch custom-switch-info custom-control-inline mb-1 pb-1 mb-xl-0">
                <input type="checkbox" (change)="onCheckboxChange(item)" class="custom-control-input" id="color-switch-{{item.rolename}}" [checked]="item.status">
                <label class="custom-control-label mr-1" for="color-switch-{{item.rolename}}">
                  <span>{{item.rolename}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="changeRoleOfUser()">Update Role</button>
          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>





<ng-template #assignActionModal let-c="close" let-d="dismiss">
  <div class="modal-dialog" id="myModal">
    <div class="modal-content">
      <form [formGroup]="formGroupReset">

        <div class="modal-header">
          <h4 class="modal-title">Assign Events to User</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 row" style="padding-left: 100px;">
              <div *ngFor="let item of actionUserVm" class="col-md-12 custom-switch custom-switch-info custom-control-inline mb-1 pb-1 mb-xl-0">
                <input type="checkbox" (change)="onCheckboxChange(item)" class="custom-control-input" id="color-switch-{{item.eventName}}" [checked]="item.status">
                <label class="custom-control-label mr-1" for="color-switch-{{item.eventName}}">
                  <span>{{item.eventName}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="ChangeUserEvent()">Update Event</button>
          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>





<ng-template #resetcontent let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">
      <form [formGroup]="formGroupReset">

        <div class="modal-header">
          <h4 class="modal-title">Reset Password</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="userName" class="col-sm-4 col-form-label">User Name</label>
                <div class="col-sm-8">

                  <input type="text" class="form-control" readonly formControlName="userName">
                  <input type="hidden" class="form-control" formControlName="userName">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="password" class="col-sm-4 col-form-label">New Password</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" (keyup)="ValidatePassword($event.target.value)" formControlName="password">
                  <ul>
                    <li *ngFor="let item of passwordValidate" class="text-danger" style="font-size: 12px; font-weight: bold;">{{ item }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group row">
                <label for="confirmPassword" class="col-sm-4 col-form-label">Confirm Password</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword">
                  <div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="ResetSubmit()">Submit</button>

          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>

    </div>
  </div>
</ng-template>

<ng-template #inactivecontent let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">Are you sure to InActive?</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="InActiveSubmit()"
          (click)="toggleActiveStatus(item)">InActive</button>

        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
      </div>

    </div>
  </div>
</ng-template>


<ng-template #activecontent let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">Are you sure to Active?</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-footer" style="margin-right: 17%;">
        <button type="submit" class="btn btn-primary" (click)="ActiveSubmit()"
          (click)="toggleActiveStatus(item)">Active</button>

        <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
      </div>

    </div>
  </div>
</ng-template>


<!-- <ng-template #inactivecontent let-c="close" let-d="dismiss" >
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">

        <div class="modal-header">
          <h4 class="modal-title">Are you sure to InActive?</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="InActiveSubmit()" (click)="toggleActiveStatus(item)">InActive</button>

          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
        </div>

    </div>
  </div>
</ng-template>


<ng-template #activecontent let-c="close" let-d="dismiss" >
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">

        <div class="modal-header">
          <h4 class="modal-title">Are you sure to Active?</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary"(click)="InActiveSubmit()" (click)="toggleActiveStatus(item)">Active</button>

          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
        </div>

    </div>
  </div>
</ng-template> -->

<ng-template #userDetails let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">
      <form [formGroup]="proxyFormList">

        <div class="modal-header">
          <h4 class="modal-title">Proxy User</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="userName" class="col-sm-4 col-form-label">User Name</label>
                <div class="col-sm-8">

                  <input type="text" class="form-control" readonly formControlName="userName">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="securityCode" class="col-sm-4 col-form-label">Security Code</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" formControlName="securityCode">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="col-md-12">
        <div class="form-group row">
          <button class="btn btn-secondary btn-sm" title="Proxy User" type="submit" (click)="proxyUser()"
            style="margin-left: 78%;">Proxy User</button>
        </div>
      </div>

    </div>
  </div>
</ng-template>




<ng-template #accessibleModal let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">
      <form [formGroup]="formGroupReset">

        <div class="modal-header">
          <h4 class="modal-title">User Accessible Days</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 row" style="padding-left: 100px;">
              <div *ngFor="let item of accessibleDays" class="col-md-12 custom-switch custom-switch-info custom-control-inline mb-1 pb-1 mb-xl-0">
                <input type="checkbox" (change)="onCheckboxChange(item)" class="custom-control-input" id="color-switch-{{item.weekDay}}" [checked]="item.status">
                <label class="custom-control-label mr-1" for="color-switch-{{item.weekDay}}">
                  <span>{{item.weekDay}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="ChangeAccessibleDays()">Update</button>
          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
