<!-- <p>ledger-list works!</p> -->

<section>
    <!-- <div class="card" style="width: 100%;height: 230px;" *ngIf="showList==='btnDiv'"> -->

    <div class="card-body" *ngIf="showList==='btnDiv'">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>

                <div class="col-md-4">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title">Internal GL Ledger Group List</h5>
                            <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
                            <p class="card-text">Click the button to see Internal GL ledger Group list.</p>
                            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-primary"
                                (click)="showGrpSubGrpList('GroupList')"> Go to List</button>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-5" style="float:right;margin-left: 8px;">
                        <h5 class="card-title">Group Ledger List</h5>
                        <p class="card-text">Click the button to see Group Ledger List</p>
                        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-primary"
                            (click)="showGrpSubGrpList('GroupList')"> Go List</button>

                    </div> -->

                <div class="col-md-4">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title">Internal GL Ledger Sub-Group List</h5>
                            <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
                            <p class="card-text">Click the button to see ledger sub-group list.</p>
                            <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-primary"
                                (click)="showGrpSubGrpList('subGroupList')"> Go to List</button>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-5">
                        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-primary"
                            (click)="showGrpSubGrpList('subGroupList')" style="float:right; margin-left: 8px;">
                            Sub-Group Ledger List</button>

                    </div> -->
            </div>

        </div>

        <!-- </div> -->

        <!-- <div class="card" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
            </div>
          </div> -->

    </div>
    <div class="card" style="width: 100%;" *ngIf="showList==='GroupList'">

        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title" style="color: black">Internal GL Group Ledger List</h5>
                        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                            (click)="goSaveForm()"
                            style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
                        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                            (click)="showGrpSubGrpList('btnDiv')"
                            style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Group / Sub-Group
                            Card</button>
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6" style="text-align: left;">
                        <select type="number" class="form-control mb-3" style="width: 16%;"
                            (change)="onChangeForShow($event.target.value)">
                            <option value="5">5</option>
                            <option value="10" selected>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col-md-6 form-group" style="text-align: right;">
                        <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchControl"
                            (keyup)="onSearch()">
                    </div>
                </div>
                <table id="example" class="table table-bordered" style="width: 100%;">
                    <thead>
                        <tr>
                            <th style="width: 0px;">SL No.</th>
                            <th>GL Code</th>
                            <th>Ledger Name</th>
                            <th>Group Name</th>
                            <th>Nature</th>
                            <th style="width: 20%;text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of groupList1 | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }; let i = index;">
                            <td>{{ ( currentPage - 1 ) * itemsPerPage + i + 1 }}</td>
                            <td>{{item.accountCode}}</td>
                            <td>{{item.accountName}}</td>
                            <td>{{item.group.groupName}}</td>
                            <td>{{item.group.nature.natureName}}</td>
                            <td style="width: 20%;text-align: center;">
                                <button class="btn btn-sm btn-info" (click)="edit(item.id)"><i
                                        class="fa fa-edit 3x"></i></button>
                                <button [disabled]="isEdit" class="btn btn-sm btn-danger" style="margin-left: 5px;"
                                    (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-md-6" style="text-align: left;">
                      <span>Showing {{currentItems}} of {{totalItems}} entries</span>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                      <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>


    </div>



    <div class="card" style="width: 100%;" *ngIf="showList==='subGroupList'">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title" style="color: black">Ledger List</h5>
                        <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                            (click)="goSaveForm()"
                            style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Add New</button>
                        <!-- <button type="button" data-toggle="tooltip" title="Add new" class="btn btn-secondary"
                            (click)="showGrpSubGrpList('btnDiv')"
                            style="float:right; margin-top:-31px;margin-right:5px;margin-left: 8px;">Group / Sub-Group
                            Card</button> -->
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6" style="text-align: left;">
                        <select type="number" class="form-control mb-3" style="width: 16%;"  (change)="onChangeForShow($event.target.value)">
                            <option value="5">5</option>
                            <option value="10" selected>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col-md-6 form-group" style="text-align: right;">
                        <input type="text" class="form-control" placeholder="Search"  [(ngModel)]="searchControl" (keyup)="onSearch()">
                    </div>
                </div>
                <table id="example" class="table table-bordered " style="width: 100%;">
                    <thead>
                        <tr>
                            <th width="5%">SL No.</th>
                            <th width="10%">Nature</th>
                            <th width="10%">Group</th>
                            <th width="10%">Sub Group</th>
                            <th width="10%">Ledger Code</th>
                            <th width="10%">Ledger Name</th>
                            <th style="width: 15%!important;text-align: center;">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of subGroupList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: subGroupTotalItems }; let i=index;">
                            <td>{{ ( currentPage - 1 ) * itemsPerPage + i + 1 }}</td>
                            <td>{{item.group.nature.natureName}} - {{item.group.nature.natureCode}}</td>
                            <td>{{item.group.parentGroup?.groupName}} - {{item.group.parentGroup?.groupCode}}</td>
                            <td>{{item.group.groupName}} - {{item.group.groupCode}}</td>
                            <td>{{item.accountCode}}</td>
                            <td>{{item.accountName}}</td>
                          
                           
                            <td style="width: 20%;text-align: center;">
                                <button class="btn btn-sm btn-info" (click)="edit(item.id)"><i
                                        class="fa fa-edit 3x"></i></button>
                                <button [disabled]="isEdit" class="btn  btn-sm btn-danger" 
                                    (click)="delete(item.id)"><i class="fa fa-trash-o 3x"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-md-6" style="text-align: left;">
                      <span>Showing {{subGroupCurrentItems}} of {{subGroupTotalItems}} entries</span>
                    </div>
                    <div class="col-md-6" style="text-align: right;">
                      <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>