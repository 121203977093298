import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { Customeraccount } from 'app/customer-account/model/customeraccount';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { FdrdetailsService } from '../fdrdetails.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-fdr-list',
  templateUrl: './fdr-list.component.html',
  styleUrls: ['./fdr-list.component.scss']
})
export class FdrListComponent implements OnInit {

  customerAccountlist: Customeraccount[] = [];
  constructor( private location: Location,
    private _service: CustommeraccountService, 
    private pdfService: PdfService,
    private _fdrService:FdrdetailsService,
    private changeDetect:ChangeDetectorRef
    )
 {
    
  }

  ngOnInit(): void {
    this.getCustomerAccountListInfo();
  }

  getCustomerAccountListInfo() {
    this._fdrService.GetAllFDRAccount().subscribe(res => {
      this.customerAccountlist = res;
      this.changeDetect.detectChanges();
    })
  }
  
  openingCreate() {
    window.open('/fdr-select/fdr-select');
  }
  PrintKyc(id: number) {
    
    this.pdfService.OpenKycPdf(id).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }


  goBack() {
    this.location.back();
  }

}
