import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DpsAccountRoutingModule } from './dps-account-routing.module';
import { DpsCreateComponent } from './dps-create/dps-create.component';
//import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DpsListComponent } from './dps-list/dps-list.component';
import { DpsCollectionComponent } from './dps-collection/dps-collection.component';
import { DpsEncashmentComponent } from './dps-encashment/dps-encashment.component';
import { DpsEncashmentListComponent } from './dps-encashment-list/dps-encashment-list.component';
import { DpsImmatureEncashmentComponent } from './dps-immature-encashment/dps-immature-encashment.component';
import { TodayEncashmentListComponent } from './today-encashment-list/today-encashment-list.component';
import { TodayDpsListComponent } from './today-dps-list/today-dps-list.component';
import { TodayCloseAccountsComponent } from './today-close-accounts/today-close-accounts.component';
import { TodayMissedPaymentComponent } from './today-missed-payment/today-missed-payment.component';
import { EncashmentAlertComponent } from './encashment-alert/encashment-alert.component';
import { DormantAccountComponent } from './dormant-account/dormant-account.component';
import { DocumentInfoListComponent } from './document-info-list/document-info-list.component';
import { DpsMISReportComponent } from './dps-mis-report/dps-mis-report.component';
import { UpcomingMaturedReportComponent } from './upcoming-matured-report/upcoming-matured-report.component';
import { PrematuredEncashmentReportComponent } from './prematured-encashment-report/prematured-encashment-report.component';
import { InterestReportComponent } from './interest-report/interest-report.component';
import { EncashmentReportComponent } from './encashment-report/encashment-report.component';
import { DpsLoanReportComponent } from './dps-loan-report/dps-loan-report.component';
import { TodayPayableDpsComponent } from './today-payable-dps/today-payable-dps.component';
import { DpsTodayReceivcbleComponent } from './dps-today-receivcble/dps-today-receivcble.component';
import { TotalPayableDpsComponent } from './total-payable-dps/total-payable-dps.component';


@NgModule({
  declarations: [
    DpsCreateComponent,
    DpsListComponent,
    DpsCollectionComponent,
    DpsEncashmentComponent,
    DpsEncashmentListComponent,
    DpsImmatureEncashmentComponent,
    TodayEncashmentListComponent,
    TodayDpsListComponent,
    TodayCloseAccountsComponent,
    TodayMissedPaymentComponent,
    EncashmentAlertComponent,
    DormantAccountComponent,
    DpsMISReportComponent,
    UpcomingMaturedReportComponent,
    PrematuredEncashmentReportComponent,
    InterestReportComponent,
    EncashmentReportComponent,
    DpsLoanReportComponent,
    DocumentInfoListComponent,
    TodayPayableDpsComponent,
    DpsTodayReceivcbleComponent,
    TotalPayableDpsComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DpsAccountRoutingModule,
    NgbNavModule
  ]
})
export class DpsAccountModule { }
