 
        <div class="card">
          <div class="card-body">
            <div class="container-fluid">

 
          <div class="row">
            <div class="col-md-9">
              <h5 class="card-title page-heading" style="color: black">
                Role Info
              </h5>
               
            </div>
            <div class="col-md-2">
               
              
            </div>
            <div class="col-md-1">
              <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
                <i class="icon-arrow-left" style="font-size: 17px;"></i>
            </button>
            </div>
          </div>
          <hr style="height: 10%" />

              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <label for="" class="col-sm-3">Role Name</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          [(ngModel)]="model.name"
                          name="name"
                          placeholder="Type Role Name to Create"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3"></div>
                      <div class="col-md-9">
                        <button
                          data-toggle="tooltip"
                          (click)="saveUserMenuAccess()"
                          title="Save"
                          type="button"
                          class="btn btn-success btn-add"
                          style="float: right; margin-top: 5px"
                        >
                          Create
                        </button>
                      </div>
                    </div>
  
  
  
                    <div class="row">
                      <!-- <div class="col-md-12 mb-4" style="display: flex; justify-content: center;">
                        <button
                          class="btn btn-success pull-left" style="border: 1px solid black;"
                          (click)="saveUserMenuAccess()"
                        >
                          Save
                        </button>
                      </div> -->
                      <div class="col-md-12">
                        <form
                          id="form2"
                          data-parsley-validate
                          class="form-horizontal form-label-left"
                        >
                          <div class="nav-container" style="margin-left: 22%">
                            <ul class="navigation">
                              <!-- First level menu -->
                              <li
                                *ngFor="let menuItem of userMenu"
                                [parent]="menuItem.title"
                                [path]="menuItem.path"
                                level="{{ level + 1 }}"
                              >
                                <input
                                  type="checkbox"
                                  (change)="onCheckboxChange($event, menuItem.id)"
                                  [checked]="menuItem.isActive"
                                  name="AllMenuIds"
                                  style="
                                    width: 17px;
                                    height: 17px;
                                    margin-right: 2%;
                                  "
                                />
                                <span class="menu-title" style="font-size: 18px"
                                  ><b>{{ menuItem.title }}</b></span
                                >
  
                                <!-- Second level menu -->
                                <ul
                                  class="menu-content"
                                  *ngIf="menuItem.submenu.length > 0"
                                >
                                  <li
                                    *ngFor="let menuSubItem of menuItem.submenu"
                                    [parent]="menuItem.title"
                                    level="{{ level + 2 }}"
                                  >
                                    <input
                                      type="checkbox"
                                      (change)="
                                        onCheckboxChange($event, menuSubItem.id)
                                      "
                                      [checked]="menuSubItem.isActive"
                                      name="AllMenuIds"
                                      style="
                                        width: 17px;
                                        height: 17px;
                                        margin-right: 2%;
                                      "
                                    />
                                    <span
                                      class="menu-title"
                                      style="font-weight: 600"
                                      >{{ menuSubItem.title }}</span
                                    >
  
                                    <!-- Third level menu -->
                                    <ul
                                      class="menu-content"
                                      *ngIf="menuSubItem.submenu.length > 0"
                                    >
                                      <li
                                        *ngFor="
                                          let menuSubsubItem of menuSubItem.submenu
                                        "
                                        [parent]="menuSubItem.title"
                                        [path]="menuSubsubItem.path"
                                        level="{{ level + 3 }}"
                                      >
                                        <input
                                          type="checkbox"
                                          (change)="
                                            onCheckboxChange(
                                              $event,
                                              menuSubsubItem.id
                                            )
                                          "
                                          [checked]="menuSubsubItem.isActive"
                                          name="AllMenuIds"
                                          style="
                                            width: 17px;
                                            height: 17px;
                                            margin-right: 2%;
                                          "
                                        />
                                        <span class="menu-title">{{
                                          menuSubsubItem.title
                                        }}</span>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </form>
                      </div>
                    </div>
  
  
  
  
  
  
                  </div>
                </div>
              </form>
            </div>
             
          </div>
        </div>

 