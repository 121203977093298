import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { CashPosting } from "../model/cash-posting";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CashPostingService } from "../service/cash-posting.service";
import { LedgerService } from "../service/ledger.service";
import { CountryInfoService } from "app/master-data/basic/basic-service/country-info.service";
import { CustomerService } from "app/customer/customer.service";
import { SharedService } from "app/services/shared.service";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import swal from "sweetalert";
import Swal from "sweetalert2";

@Component({
  selector: "app-own-account-fund-transfer",
  templateUrl: "./own-account-fund-transfer.component.html",
  styleUrls: ["./own-account-fund-transfer.component.scss"],
})
export class OwnAccountFundTransferComponent implements OnInit {
  selectedOption: string;
  customerIdPayment: number;
  customerAccountId: number;
  customerAccountId2: number;
  //currentBalance: number=0;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  currentBalance: number;
  currentBalance2: number;
  accountNo: string = "";
  accountId: number = 0;
  accountNoRecieve: string = "";
  //accountNoFund1: string = '';
  accountNoFund1: number = 0;
  accountNoFund2: string = "";
  totalCurrentBalance: number;
  customerInfo_cashFundTransfer1: any = "";
  customerInfo_cashFundTransfer2: any = "";

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: CashPosting[] = [];
  model = new CashPosting();
  form: FormGroup;
  customerNameControl: FormControl = new FormControl("");
  formControl = new FormControl();
  suggestions: any[] = [];
  hideShow: string = "Show";
  //searchType: string = '';
  glAccountName: any;
  gLGenerateId: number = 0;
  AccountNoList: Observable<any[]>;
  AccountNoList1: Observable<any[]>;
  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,

    private formBuilder: FormBuilder,
    private shared: SharedService
  ) {
    this.frmGroup = new FormGroup({
      id: new FormControl(),
      customerCode: new FormControl(),
      transactionType: new FormControl(),
      customerId: new FormControl(),
      voucherNo: new FormControl(),
      postingType: new FormControl(),
      remarks: new FormControl(),
      currencyId: new FormControl(),
      voucherAmount: new FormControl(),
      gLGenerateId: new FormControl(),
      //customerId: new FormControl(),
    });

    this.form = this.formBuilder.group({
      customerName: this.customerNameControl,
      voucherAmount: [
        "",
        [Validators.required, Validators.pattern("^[0-9,.]*$")],
      ],

      customerCode: ["", Validators.required],
      customerAccountId: [0, Validators.required],
      voucherNo: ["", Validators.required],
      remarks: ["", Validators.required],
      currencyId: [0, Validators.required],
    });
    // this.frmGroup = this.formBuilder.group({

    //   voucherAmount : ['', [Validators.required, Validators.pattern('^[0-9,.]*$')]],

    // });
  }

  ngOnInit(): void {
    this.loadLedgerList();
    this.loadCountryList();
    this.GetCustomerAccountNo();
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((value) =>
          this._LedgerService.getAutocompleteSuggestions(value)
        )
      )
      .subscribe((data) => {
        this.suggestions = data;
        console.log(this.suggestions);
      });
    this.hideShow = "Show";
  }

  selectedSuggestion: any = null;

  selectSuggestion(item: any) {
    this.formControl.setValue(item.bdGenerateCode);
    //this.suggestions = [];
    this.selectedSuggestion = item;
    this.hideShow = "Hide";
  }
  hideAfterSelectValue(value: any) {
    this.hideShow = value;
  }
  // selectSearchType(type: string) {
  //   this.searchType = type;
  // }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getLedgerRelationlist().subscribe((res) => {
      this.LedgerList = of(res);
    });
  }

  countryList: Observable<any[]>;
  loadCountryList() {
    this._CountryInfoService.getlist().subscribe((res) => {
      this.countryList = of(res);
    });
  }
  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

  onsubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Transfer this Amount!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Transfer own Account!",
    }).then((res) => {
      if (res.isConfirmed) {
        if (this.isEdit) {
          this.model.id = this.frmGroup.value.id;
        } else {
          this.model.id = 0;
        }

        this.model.customerCode = this.frmGroup.value.customerCode;
        this.model.transactionType = 3;
        this.model.customerAccountId = this.customerAccountId;
        this.model.customerAccountId2 = this.customerAccountId2;
        this.model.voucherNo = this.frmGroup.value.voucherNo;
        this.model.postingType = this.frmGroup.value.postingType;
        this.model.remarks = this.frmGroup.value.remarks;
        this.model.currencyId = this.frmGroup.value.currencyId;
        this.model.voucherAmount = this.frmGroup.value.voucherAmount
          .toString()
          .replace(/,/g, "");
        this.model.gLGenerateId = this.gLGenerateId;
        // alert( this.model.gLGenerateId );
        // alert( this.gLGenerateId );
        if (this.model.voucherAmount > 0 && this.model.currencyId > 0) {
          if (this.model.id == 0) {
            this._service.save(this.model).subscribe((res: number) => {
              if (res > 0) {
                this.ResetForm();

                this.shared.ShowAlert(
                  "Success",
                  "Save Successfully",
                  "success"
                );
                this.isEdit = false;
              } else {
                this.shared.ShowAlert("Warning", "Failed to Save", "warning");
                this.isEdit = false;
              }
            });
          } else {
            this._service.save(this.model).subscribe((res) => {
              this.ResetForm();

              this.shared.ShowAlert("Success", "Save Successfully", "success");
              //this.getLedgerList();
              this.isEdit = false;
              //this.showSaveForm='saveList';
            });
          }
        }
      } else if (res.isDismissed) {
        this.router.navigate(["/accounting/own-account-fund-transfer"]);
      }
    });
  }

  GetCustomerAccountNo() {
    this._CustomerService.GetCustomerAccountNo().subscribe((res) => {
      this.AccountNoList = of(res);
    });
  }

  // onCountryChange(cId: any): void {
  //   if (cId > 0 || cId != null || cId != "") {
  //     //this.getDivision(cId);
  //   }
  // }

  // loadUserDataByAccountNoFundFirst(accountNoFund1:string) {
  //   this._CustomerService.getCustomerByAccountNo(accountNoFund1).subscribe(res => {
  //     if (res != null) {
  //       if(res.glAccountName==null){
  //         console.log('glName');
  //         console.log(res);
  //       this.currentBalance = res.currentBalance;
  //       this.customerInfo_cashFundTransfer1 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
  //       this.customerIdPayment = res.customersId;

  //       this.customerAccountId = res.id;
  //       this.isBtnValid = true;
  //       this.glAccountName='';
  //       }
  //       else{
  //         this.glAccountName=res.glAccountName;
  //         this.gLGenerateId = res.glAccountId;
  //         this.customerInfo_cashFundTransfer1 ='';
  //         this.isBtnValid = true;
  //         console.log(res);
  //       }

  //       // this.currentBalance = res.currentBalance;
  //       // this.customerInfo_cashFundTransfer1 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
  //       // // console.log(this.customerInfo_cashFundTransfer1);
  //       // this.customerAccountId = res.id;
  //       // this.isBtnValid = true;
  //     }
  //     else {
  //       this.refreshAc();
  //       swal('Warning', 'Invalid Account No', 'warning');
  //     }
  //   })
  // }

  loadUserDataByAccountNoFundFirst(accountId: number) {
    this._CustomerService
      .GetCustomerInfoByAccountNoById(accountId)
      .subscribe((res) => {
        if (res != null) {
          if (res.glAccountName == null) {
            this.currentBalance =
              res.currentBalance.toLocaleString() !== null
                ? res.currentBalance.toLocaleString()
                : 0;
            this.totalCurrentBalance = res.currentBalance.toLocaleString();
            // this.currentBalance = res.currentBalance;
            this.customerInfo_cashFundTransfer1 =
              res.customerNameEng + "; " + res.mobileNo + "; " + res.nidNo;
            this.customerIdPayment = res.customersId;

            this.customerAccountId = res.id;
            this.isBtnValid = true;
            this.glAccountName = "";
          } else {
            this.glAccountName = res.glAccountName;
            this.gLGenerateId = res.glAccountId;
            this.customerInfo_cashFundTransfer1 = "";
            this.isBtnValid = true;
          
          }
        } else {
          this.refreshAc();
          swal("Warning", "Invalid Account No", "warning");
        }
      });
    this._CustomerService
      .GetCustomerAccountNoForChange(accountId)
      .subscribe((res) => {
        const filterAccount = res.filter(
          (item) =>
            item.accountType != "Loan" && item.accountType != "Liquidition"
        );

        this.AccountNoList1 = of(filterAccount);
      });
  }

  // loadUserDataByAccountNoFundLast() {
  //   this._CustomerService.getCustomerByAccountNo(this.accountNoFund2).subscribe(res => {
  //     if (res != null) {
  //       this.customerInfo_cashFundTransfer2 = res.customerNameEng + '; ' + res.mobileNo + '; ' + res.nidNo;
  //       this.customerAccountId2 = res.id;
  //       this.isBtnValid = true;
  //     }
  //     else {
  //       this.refreshAc();
  //       swal('Warning', 'Invalid Account No', 'warning');
  //     }
  //   })
  // }

  loadUserDataByAccountNoFundLast(accountId: number) {
    this._CustomerService
      .GetCustomerInfoByAccountNoById(accountId)
      .subscribe((res) => {
        if (res != null) {
          this.currentBalance2 =
            res.currentBalance !== null ? res.currentBalance : 0;
          // this.currentBalance2 = res.currentBalance;

          this.customerInfo_cashFundTransfer2 =
            res.customerNameEng + "; " + res.mobileNo + "; " + res.nidNo;
          this.customerAccountId2 = res.id;
          this.isBtnValid = true;
        } else {
          this.refreshAc();
          swal("Warning", "Invalid Account No", "warning");
        }
      });
  }

  refreshAc() {
    this.currentBalance2 = 0;
    this.currentBalance = 0;
    this.customerInfo_cashFundTransfer1 = "";
    this.customerInfo_cashFundTransfer2 = "";
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }
  CheckBalance() {
    var balanceAmount = this.currentBalance.toString().replace(/,/g, "");
    var inputAmount = parseInt(
      this.frmGroup.value.voucherAmount.replace(/,/g, ""),
      10
    );

    if (inputAmount > parseInt(balanceAmount, 10)) {
      this.isvalid = false;
    } else {
      this.isvalid = true;
    }
  }

  voucherAmountValue(value: any) {
    var balanceAmount = this.totalCurrentBalance.toString().replace(/,/g, "");
    var inputAmount = parseInt(value.replace(/,/g, ""), 10);

    if (inputAmount > 0) {
      this.currentBalance = parseInt(balanceAmount) - inputAmount;
    } else {
      this.currentBalance = this.totalCurrentBalance;
    }
  }

  ResetForm() {
    this.frmGroup.reset();
    this.customerInfo_cashFundTransfer1 = "";
    this.customerInfo_cashFundTransfer2 = "";
    this.currentBalance = 0;
    this.currentBalance2 = 0;
  }
}
