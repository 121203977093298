
<div class="card">
  <div class="card-body">
    <div class="container-fluid">


        <div class="row">
          <div class="col-md-9">
              <h5 class="card-title page-heading" style="color: black">API Logs</h5>

          </div>
          <div class="col-md-2">


          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;">
              <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
          </div>
        </div>
        <hr style="height: 10%" />



      <div class="row">
        <label for="username" class="col-md-1">User-Name</label>
        <div class="col-md-2">
          <input type="text" class="form-control" [(ngModel)]="username">
        </div>
        <label for="fromDate" class="col-md-1">From Date</label>
        <div class="col-md-2">
          <input type="date" class="form-control" [(ngModel)]="fromDate">
        </div>
        <label for="toDate" class="col-md-1">To Date</label>
        <div class="col-md-2">
          <input type="date" class="form-control" [(ngModel)]="toDate">
        </div>
        <div class="col-md-1">
          <a href="javascript:void(0)" class="btn btn-success" (click)="FilterApiLogs()">Find</a>
        </div>
        <div class="col-md-2">
          <a href="javascript:void(0)" class="btn btn-info" (click)="LoadAllLogs()">Load All</a>
        </div>
      </div>
    </div>

  </div>
  <div class="card-body">
    <div class="container-fluid">
      <div class="row" style="overflow: scroll; max-height: 645px;">
        <table style="width: 100%;" class="table table-bordered table-striped">
          <thead>
            <tr style="background-color: rgb(180, 179, 179)">
              <th class="text-center">Log Date</th>
              <th class="text-center">Username</th>
              <th class="text-center">Method Type</th>
              <th class="text-center">API</th>
              <th class="text-center">Status Code</th>
              <th class="text-center">Status</th>
              <th class="text-center">Channel</th>
              <th class="text-center">Request Body</th>
            </tr>
          </thead>
          <tbody style="background-color: white">
            <tr *ngFor="let item of logs">
              <!-- <td class="text-center">{{ item.logTime | date: dateFormat}}</td> -->
              <td class="text-center">{{ formatDate(item.logTime) }}</td>
              <td class="text-center">{{ item.username }}</td>
              <td class="text-center"><span class="{{item.methodType == 'GET' ? 'get' : 'post'}}">{{ item.methodType }}</span></td>
              <td class="text-left">{{ item.apiUrl }}</td>
              <td class="text-center">{{ item.statusCode }}</td>
              <td class="text-center">{{ item.status }}</td>
              <td class="text-center">{{ item.channel?.channelName }}</td>
              <td class="text-center">
              <span *ngIf="item?.reqBody !== null; else noContent">
                <a href="javascript:void(0)" class="btn btn-add btn-sm"(click)="OpenModal(requestBodyModal,item)">Request Body</a>
              </span>
              <ng-template #noContent>
                <span></span>
              </ng-template>

              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>

<ng-template #requestBodyModal let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-lg" id="myModal">
    <div class="modal-content">
      

        <div class="modal-header">
          <h4 class="modal-title page-heading">Request Body</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">
          <div class="row">
            <div class="col-md-12">
             
                <pre>{{ requestbody | json }}</pre>
                <div *ngIf="copied" class="loader copied-overlay"></div>
                <div *ngIf="copied" class="blur-overlay"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
          <button type="button" class="btn btn-success" (click)="copyToClipboard()">Copy JSON</button>
          <!-- <span *ngIf="copied" class="ml-2 text-success" style="font-size: 16px;font-weight: bold;">Copied........</span> -->
        </div>
      
    </div>
  </div>
</ng-template>