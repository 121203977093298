<section>
  <div class="card" style="width: 100%;">
    <div class="card-body">
      <div class="container-fluid">

        <div class="row">
          <div class="col-md-9">
              <h5 class="card-title page-heading" style="color: black">Assign Action To Role</h5>

          </div>
          <div class="col-md-2">
             
             
          </div>
          <div class="col-md-1">
            <button class="btn btn-secondary btn-back" title="Go Back" (click)="goBack()" style="float:right;"> 
              <i class="icon-arrow-left" style="font-size: 17px;"></i>
          </button>
          </div>
        </div>
        <hr style="height: 10%" />
        <!-- <h5 class="card-title page-heading" style="color: black">Assign Action To Role</h5>
        <hr> -->
        <form>
          <div asp-validation-summary="All" class="text-danger"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="name" class="col-sm-2 col-form-label">Role Name <span style="color: red; font-size: 14px;">*</span></label>
                <div class="col-sm-4">
                  <select class="form-control" (change)="onRoleChange($event)">
                    <option value="">Select</option>
                    <option value="{{item.id}}" *ngFor="let item of roleList">{{item.name}}</option>
                  </select>
                </div>

                <div class="col-md-6 row" style="padding-left: 100px;">
                  <label for="name" class="col-md-3 col-form-label">Events <span style="color: red; font-size: 14px;">*</span></label>
                  <div *ngFor="let item of eventList" class="col-md-9 custom-switch custom-switch-info custom-control-inline mb-1 pb-1 mb-xl-0">
                    <input type="checkbox" (change)="onCheckboxChange(item)" class="custom-control-input" id="color-switch-{{item.actionName}}" [checked]="item.status">
                    <label class="custom-control-label mr-1" for="color-switch-{{item.actionName}}">
                      <span>{{item.actionName}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
    <div class="form-group">
      <button type="button" data-toggle="tooltip" title="Submit" (click)="onSubmit()" class="btn btn-success btn-add"
        style="float:right; margin-top:-31px;margin-right:38px;margin-left: 8px;">Update</button>

    </div>
  </div>

</section>

