import { Component, OnInit } from '@angular/core';
import { SmsEmailFormat } from '../models/sms-email-format';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-smsemail-format',
  templateUrl: './smsemail-format.component.html',
  styleUrls: ['./smsemail-format.component.scss']
})
export class SMSEmailFormatComponent implements OnInit {
  formats: SmsEmailFormat[];
  editingFormat: SmsEmailFormat;
  newFormat: SmsEmailFormat = { id: null, particular: '', subject: '', body: '', type: '', isHTML: false, status: 1, lnCode: 'EN' };
  placeholder: string = "";

  constructor(private formatService: PaymentService) { }

  ngOnInit() {
    this.loadFormats();
    this.placeholder = "";
  }

  changeFormats(val){
    if(val == 'CUSTOMER REG'){
      this.placeholder = '{0} = [OTP Code]';
    }
    else if(val == 'LOAN DISBURSEMENT'){
      this.placeholder = '{0}=[Loan Amount] , {1} = [Account No]';
    }
    else if(val == 'LOAN PAYMENT'){
      this.placeholder = '{0} = [Amount]';
    }
    else if(val == 'LOAN CLOSE'){
      this.placeholder = '{0} = [Loan Account Number]';
    }
    else if(val == 'OVERDUE REMINDER'){
      this.placeholder = '{0} = [Amount] , {1} = [Link]';
    }
    else if(val == 'REPAYMENT REMINDER'){
      this.placeholder = '{0} = [Amount]';
    }
    else if(val == 'APPLICATION APPROVAL'){
      this.placeholder = '{0} = [Credit Limit]';
    }
    else if(val == 'APPLICATION REJECTED'){
      this.placeholder = '';
    }
    else if(val == 'APPLICATION SUBMISSION'){
      this.placeholder = '';
    }
    else{
      this.placeholder = '';
    }
  }

  loadFormats() {
    this.formatService.getFormats().subscribe(formats => {
      this.formats = formats;
    });
  }

  saveFormat() {
    this.formatService.addFormat(this.newFormat).subscribe(() => {
      this.loadFormats();
      this.newFormat = { id: null, particular: '', subject: '', body: '', type: '', isHTML: false, status: 1, lnCode: 'EN' };
    });
  }

  editFormat(format: SmsEmailFormat) {
    this.newFormat = format;
    this.changeFormats(format.particular);
  }

  deleteFormat(id: number) {
    if (confirm('Are you sure you want to delete this format?')) {
      this.formatService.deleteFormat(id).subscribe(() => {
        this.loadFormats();
      });
    }
  }
}
