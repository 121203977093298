
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'app/services/shared.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { Customer } from 'app/customer/model/customer.model';
import { ProductBuilder } from 'app/models/deposit/product-builder/product-builder';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from 'app/payment/payment.service';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import swal from 'sweetalert';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { OccupationService } from 'app/master-data/basic/basic-service/occupation.service';
import { Occupation } from 'app/master-data/basic/basic-model/occupation';

import { LayoutService } from 'app/shared/services/layout.service';
import { th, tr } from 'date-fns/locale';
import { RelationServiceService } from 'app/master-data/basic/basic-service/relation-service.service';
import { DatePipe, Location } from "@angular/common";
@Component({
  selector: "app-dps-create",
  templateUrl: "./dps-create.component.html",
  styleUrls: ["./dps-create.component.scss"],
})
export class DpsCreateComponent implements OnInit {
  isMinor: boolean = false;
  dueAmount: number = 500.0;
  totalShare: number = 0;
  productId: any = 9;
  OpenBalance: any = 0;
  interestAmount: any = 0;

  customerlist: Customer[] = [];
  productlist: ProductBuilder[] = [];
  model: any = {};
  isEdit: boolean = false;
  frmGroup: FormGroup;
  nomineeGroup: FormGroup;
  nolineeList: any[] = [];
  occupationList: Occupation[] = [];
  declearationList: any[] = [];
  decSelectList1: any[] = [];
  decSelectList2: any[] = [];
  riskGradingList: any[] = [];
  nominee: any = {};
  customerAccountNo: any;
  ledgerRelationId: any;
  ledgerRelationName: string;
  relationName: string;
  accountNo: string = "";
  closeResult: string;
  productCode: string;
  //relation: any;

  vActive = "top"; // Vertical Pills
  active = 1; // Basic Navs
  kActive = "Customer Info"; // Keep content
  disabled = true;

  tabs = [1, 2, 3, 4, 5];
  counter = this.tabs.length + 1;

  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;

  dataId: any[] = [];
  dataType: any[] = [];
  dataNumber: any[] = [];
  dataAmount: any[] = [];
  dataMax: any[] = [];
  relationList: Observable<any[]>;

  isAdmin: boolean = false;
  // currentUser: any;

  message = "";
  backPreview = "";
  progress = 0;
  selectedFiles?: FileList;
  currentFile?: File;
  tenurelist: any = [];

  isValidAmount: boolean = true;
  msgLoanAmt: string;
  //interestRate: any;
  ledgerId: any;
  maturityAmnt: any;
  maturityDate: any;
  constructor(
    private _service: CustommeraccountService,
    private _productService: ProductBuilderService,
    private router: Router,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private paymentService: PaymentService,
    private _occupationService: OccupationService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private relationSvc: RelationServiceService,
    private location: Location,
    private datePipe: DatePipe
  ) {
    this.getCustomerListInfo();
    this.getProductList();
    this.LoadRelationList();
    this.nomineeGroup = this.formBuilder.group({
      photo: [""],
    });
  }

  ngOnInit(): void {
    this.populateForm();
    this.populateNomineeForm();
    this.getCustomerListInfo();
    this.getProductList();
    this.getDeclearationList();
    //  this.getRiskGradingList();
    this.getCurrentUserRole();
    this.LoadRelationList();
    this.GetAccountNo(9);
    this.getTenureList();
  }

  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      CustomersInfoId: [null],
      CustomersInfoNumber: [null],
      ProductBuilderId: [""],
      NomineeInfoSId: [null],
      //OpeningBalance: [null],

      OpeningBalance: [0, [Validators.required]],
      AccountTilte: [""],
      AccountTilteBn: [""],
      ledgerRelationId: [null],
      ledgerId: [null],
      customerAccountNo: [null],
      ledgerRelationName: [null],
      customersInfo: [null],
      CustomersId: [null],
      accountCurrency: [null],
      accountPurpose: [""],
      accountMinor: [""],
      accountMinorName: [""],
      accountMinorDOB: [""],
      accountMinorGuardian: [""],
      accountMinorGuardianRelation: [""],
      // riskGrading1: [''],
      monthlyIncome: [null],
      productCode: [""],
      relationName: [null],
      dpsTenureId: [""],
      interestRate: [0],
      dateOfBirth: [null],
      /////////////////////////////////////
      nidNo: [null],
      fatherName: [null],
      motherName: [null],
      gender: [null],
      mobileNo: [null],
      email: [null],
      eTinNo: [null],
      profession: [null],
      taxReturnFile: [null],
    });
  }

  getCurrentUserRole() {
    this._service.GetUserRole().subscribe((userData: any) => {
      if (userData.roles.includes("Admin")) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
        (this.accountNo = userData.userName), this.GetAllCustomersByMobileNo();
      }
    });
  }

  GetAccountNo(pId: any): void {
    this._service.GetAccountNo1(pId).subscribe((res: any) => {
      this.frmGroup.patchValue({
        customerAccountNo: res.code,
      });
    });

    this._productService.getLedgerByProductId(pId).subscribe((res22: any) => {
      this.frmGroup.patchValue({
        interestRate: res22?.interestRate,
        ledgerRelationId: res22?.ledger?.groupId,
        ledgerId: res22?.ledgerId,
        ledgerRelationName:
          res22?.ledger?.accountCode + "-" + res22?.ledger?.accountName,
        accountCurrency: res22?.currency?.currency,
      });
    });

    this.getTenureList();
  }

  getCustomerListInfo() {
    this._service.getCustomerList().subscribe((res) => {
      this.customerlist = res;
    });
  }

  getOccupationList() {
    this._occupationService.getlist().subscribe((res) => {
      this.occupationList = res;
    });
  }

  getProductList() {
    this._productService.getDPSProductList().subscribe((res) => {
      this.productlist = res;
    
      // this.productlist = res.filter(
      //   (product) => product.productCategoryId === 3
      // );
    });
  }

  getTenureList() {
    this._productService.getTenureList(this.productId).subscribe((res) => {
      this.tenurelist = res;
    });
  }

  // calMaturityAmount() {

  //   const dpsAmount = this.OpenBalance;

  //   this.interestAmount = parseFloat(
  //     ((dpsAmount * this.frmGroup.value.interestRate) / 100).toFixed(2)
  //   );
  //   this.maturityAmnt = dpsAmount + this.interestAmount;
  // }

  GetMaturityAmount() {
   
    if (this.isValidAmount) {
      this._service
        .GetMarurityAmountForDPS(
          this.frmGroup.value.productCode,
          this.frmGroup.value.OpeningBalance
        )
        .subscribe((res) => {
         
          this.maturityAmnt = res.maturityAmount;
          this.maturityDate = res.maturityDate;
        });
    }
  }

  GetAllCustomersByMobileNo() {
    this._service.GetAllCustomersByMobileNo(this.accountNo).subscribe((res) => {
      if (res != null) {
     
        this.frmGroup.patchValue({
          customersInfo:
            res.customerRCode + ", " + res.customerNameEng + " - " + res.nidNo,
          CustomersId: res.id,
          CustomersInfoId: res.id,
          CustomersInfoNumber: this.accountNo,
          dateOfBirth: res.dateOfBirth
            ? this.datePipe.transform(res.dateOfBirth,  "yyyy-MM-dd")
            : null,
          AccountTilte: res.customerNameEng,
          AccountTilteBn:
            res.customerNameBn == null
              ? res.customerNameEng
              : res.customerNameBn,
        });
      } else {
        swal("Warning", "Invalid Account No", "warning");
      }
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent, t: number) {
    if (t === 0 && changeEvent.nextId === 1) {
      this.kActive = "Customer Info";
    } else if (t === 0 && changeEvent.nextId === 2 && this.step1) {
      this.kActive = "Product Info";
    } else if (t === 0 && changeEvent.nextId === 3 && this.step2) {
      this.kActive = "Account Info";
    } else if (t === 0 && changeEvent.nextId === 4 && this.step3) {
      this.kActive = "Nominee Info";
    }
    // else if (t === 0 && changeEvent.nextId === 5 && this.step4) {
    //   this.kActive = "Declearation";
    // }
    else if (
      t === 1 &&
      this.frmGroup.value.AccountTilte.length > 1 &&
      this.frmGroup.value.AccountTilteBn.length > 1 &&
      this.frmGroup.value.CustomersInfoId > 0
    ) {
      this.step1 = true;
      this.active = 2;
      this.kActive = "Product Info";
    } else if (
      t === 1 &&
      this.frmGroup.value.AccountTilte.length < 1 &&
      this.frmGroup.value.AccountTilteBn.length < 1 &&
      (this.frmGroup.value.CustomersInfoId < 1 ||
        this.frmGroup.value.CustomersInfoId === null)
    ) {
      this.step1 = false;
      swal("Warning", "Fill up all required feild", "warning");
    } else if (
      t === 2 &&
      (this.step2 ||
        (this.frmGroup.value.accountPurpose.length > 1 &&
          this.frmGroup.value.ProductBuilderId > 0))
    ) {
      this.step2 = true;
      this.active = 3;
      this.kActive = "Account Info";
    } else if (
      t === 2 &&
      (this.frmGroup.value.accountPurpose.length < 1 ||
        this.frmGroup.value.ProductBuilderId === "")
    ) {
      this.step2 = false;
      swal("Warning", "Fill up all required feild", "warning");
    } else if (
      t === 3 &&
      (this.step3 ||
        (this.frmGroup.value.OpeningBalance > 1 && !this.isMinor) ||
        (this.isMinor &&
          this.frmGroup.value.accountMinorName.length > 1 &&
          this.frmGroup.value.accountMinorDOB.length > 1 &&
          this.frmGroup.value.accountMinorGuardianRelation.length > 1 &&
          this.frmGroup.value.OpeningBalance > 1))
    ) {
      this.step3 = true;
      this.active = 4;
      this.kActive = "Nominee Info";
    } else if (
      t === 3 &&
      ((!this.isMinor &&
        (this.frmGroup.value.OpeningBalance === null ||
          this.frmGroup.value.OpeningBalance < 1)) ||
        (this.isMinor &&
          (this.frmGroup.value.accountMinorName.length < 1 ||
            this.frmGroup.value.accountMinorDOB.length < 1 ||
            this.frmGroup.value.accountMinorGuardian.length < 1 ||
            this.frmGroup.value.accountMinorGuardianRelation.length < 1 ||
            this.frmGroup.value.OpeningBalance === null ||
            this.frmGroup.value.OpeningBalance < 1)))
    ) {
      this.step3 = false;
      swal("Warning", "Fill up all required feild", "warning");
    }
    // else if (
    //   t === 4 &&
    //   (this.step4 || this.nolineeList.length > 0) &&
    //   this.totalShare === 100
    // )
    // {
    //   this.active = 5;
    //   this.kActive = "Declearation";
    // }
    else if (t === 4 && this.nolineeList.length < 1) {
      this.step4 = false;
      swal("Warning", "Fill up all required feild", "warning");
    } else if (t === 4 && this.totalShare != 100) {
      this.step4 = false;
      this.shared.ShowAlert(
        "Warning",
        "Total nominee shares should be 100",
        "warning"
      );
    } else {
      changeEvent.preventDefault();
    }
  }

  refresh() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  getLoanAmountValue(event: any) {
    this.OpenBalance = String(event.target.value);
    const dpsAmount = this.OpenBalance;
   
    // if ( value % 500 === 0 &&  (value === 500 || value === 1000 || value === 2000 || value > 2000))
    if (dpsAmount % 500 === 0) {
      this.isValidAmount = true;
      this.msgLoanAmt = "";
    } else {
      this.isValidAmount = false;
      this.msgLoanAmt =
        "Enter a valid DPS Amount (500, 1000, 2000, or a multiple of 500)";
    }

    return this.isValidAmount;
  }

  ValidateShare(event: any) {
    if (parseInt(event.target.value) > 100) {
    
      this.totalShare = 100;
    }
    this.changeDetectorRef.detectChanges();
  }

  editAccount(item: any) {
    this.isEdit = true;
    this.frmGroup.patchValue({
      id: item.Id,
      CustomersInfoId: item.CustomersInfoId,
      NomineeInfoSId: item.NomineeInfoSId,
      ProductBuilderId: item.ProductBuilderId,
      AccountTilte: item.AccountTilte,
      OpeningBalance: item.OpeningBalance,
      ledgerRelationId: item.ledgerRelationId,
      ledgerId: item.ledgerId,
      customerAccountNo: item.customerAccountNo,
    });
  }

  checkMinorOrNot(event: Event) {
    const target = event.target as HTMLInputElement;
    this.step3 = false;
    this.isMinor = target.checked;
  }
  //Nominee
  populateNomineeForm() {
    this.nomineeGroup = this.formBuilder.group({
      nomineeNameSl: [0],
      nomineeName: [""],

      nomineeDOB: [""],
      nomineePresentAddress: [""],

      nomineeOccupation: [""],
      nomineeNID: [""],
      nomineePassport: [""],
      nomineeBirthIdentity: [""],

      nomineeShare: [0],
      relationId: [""],
      relationName: [""],
      photo: [""],
    });
  }

  addNominee() {
    this.totalShare += this.nomineeGroup.value.nomineeShare;
    if (
      this.nomineeGroup.valid &&
      this.nomineeGroup.value.nomineeNameSl === 0 &&
      (this.nomineeGroup.value.nomineePassport != null ||
        this.nomineeGroup.value.nomineeNID != null ||
        this.nomineeGroup.value.nomineeBirthIdentity != null ||
        this.nomineeGroup.value.nomineeETin != null ||
        this.nomineeGroup.value.nomineeDriveLinc != null)
    ) {
      this.nomineeGroup.value.nomineeNameSl = this.nolineeList.length + 1;
      this.nominee.nomineeName = this.nomineeGroup.value.nomineeName;

      this.nominee.nomineeDOB = this.nomineeGroup.value.nomineeDOB;
      this.nominee.nomineePresentAddress =
        this.nomineeGroup.value.nomineePresentAddress;

      this.nominee.nomineeOccupation =
        this.nomineeGroup.value.nomineeOccupation;
      this.nominee.nomineeNID = this.nomineeGroup.value.nomineeNID;
      this.nominee.nomineePassport = this.nomineeGroup.value.nomineePassport;
      this.nominee.nomineeBirthIdentity =
        this.nomineeGroup.value.nomineeBirthIdentity;

      this.nominee.nomineeShare = this.nomineeGroup.value.nomineeShare;
      this.nominee.relationId = this.nomineeGroup.value.relationId;

      this.relationSvc
        .GetRelationById(this.nomineeGroup.value.relationId)
        .subscribe((res: any) => {
          this.nomineeGroup.patchValue({
            relationName: res.name,
          });
        });

      this.nominee.photo = this.backPreview;

      this.nolineeList.push(this.nominee);

      this.populateNomineeForm();
    } else if (
      this.nomineeGroup.value.nomineeNameSl > 0 &&
      this.nomineeGroup.valid &&
      (this.nomineeGroup.value.nomineePassport != null ||
        this.nomineeGroup.value.nomineeNID != null ||
        this.nomineeGroup.value.nomineeBirthIdentity != null ||
        this.nomineeGroup.value.nomineeETin != null ||
        this.nomineeGroup.value.nomineeDriveLinc != null)
    ) {
      let index = this.nolineeList.findIndex(
        (obj) => obj.nomineeNameSl === this.nomineeGroup.value.nomineeNameSl
      );
      this.nominee.nomineeNameSl = this.nomineeGroup.value.nomineeNameSl;
      this.nominee.nomineeName = this.nomineeGroup.value.nomineeName;

      this.nominee.nomineeDOB = this.nomineeGroup.value.nomineeDOB;
      this.nominee.nomineePresentAddress =
        this.nomineeGroup.value.nomineePresentAddress;

      this.nominee.nomineeOccupation =
        this.nomineeGroup.value.nomineeOccupation;
      this.nominee.nomineeNID = this.nomineeGroup.value.nomineeNID;
      this.nominee.nomineePassport = this.nomineeGroup.value.nomineePassport;
      this.nominee.nomineeBirthIdentity =
        this.nomineeGroup.value.nomineeBirthIdentity;
      this.nominee.relationId = this.nomineeGroup.value.relationId;
      this.nominee.photo = this.backPreview;

      this.nominee.name = this.nomineeGroup.value.name;
      this.relationSvc
        .GetRelationById(this.nomineeGroup.value.relationId)
        .subscribe((res: any) => {
          // alert(res.name);

          this.nomineeGroup.patchValue({
            relationName: res.name,
          });
        });

      this.nominee.nomineeShare = this.nomineeGroup.value.nomineeShare;
      this.nolineeList.splice(index, 1);
      this.nolineeList.push(this.nomineeGroup.value);
      this.populateNomineeForm();
    } else {
      swal("Warning", "Input All Required Feild", "warning");
    }
  }

  editNominee(obj: any) {
    this.nomineeGroup.patchValue({
      nomineeNameSl: obj.nomineeNameSl,
      nomineeName: obj.nomineeName,

      nomineeDOB: obj.nomineeDOB,
      nomineePresentAddress: obj.nomineePresentAddress,

      nomineeOccupation: obj.nomineeOccupation,
      nomineeShare: obj.nomineeShare,
      nomineeNID: obj.nomineeNID,
      nomineePassport: obj.nomineePassport,
      nomineeBirthIdentity: obj.nomineeBirthIdentity,
      relation: obj.relation,
      photo: obj.photo,
    });
  }

  deleteNominee(obj: any) {
    this.nolineeList.splice(
      this.nolineeList.findIndex(
        (li) => li.nomineeNameSl === obj.nomineeNameSl
      ),
      1
    );
  }
  //End Nominee
  // Delearation
  getDeclearationList() {
    this._service.GetDeclearationList().subscribe((res) => {
      res.forEach((element) => {
        this.declearationList.push(element);
      });
      res.forEach((element1) => {
        element1.depositNumber1 = 0;
        element1.depositAmount1 = 0;
        element1.depositMaxAmount1 = 0;
        this.decSelectList1.push(element1);
      });
      res.forEach((element2) => {
        element2.depositNumber2 = 0;
        element2.depositAmount2 = 0;
        element2.depositMaxAmount2 = 0;
        this.decSelectList2.push(element2);
      });
    });
  }
  // updateDeclearationList(id: number, p: number, event: any) {
  //   let index = this.decSelectList1.findIndex((obj) => obj.id === id);
  //   const updatedValue = event.target.value;
  //   const fullObject = this.decSelectList1[index];
  //   if (p === 1) {
  //     fullObject.depositNumber1 = updatedValue;
  //     fullObject.depositAmount1 =
  //       fullObject.depositAmount1 == undefined ? 0 : fullObject.depositAmount1;
  //     fullObject.depositMaxAmount1 =
  //       fullObject.depositMaxAmount1 == undefined
  //         ? 0
  //         : fullObject.depositMaxAmount1;
  //   } else if (p === 2) {
  //     fullObject.depositAmount1 = updatedValue;
  //     fullObject.depositNumber1 =
  //       fullObject.depositNumber1 == undefined ? 0 : fullObject.depositNumber1;
  //     fullObject.depositMaxAmount1 =
  //       fullObject.depositMaxAmount1 == undefined
  //         ? 0
  //         : fullObject.depositMaxAmount1;
  //   } else if (p === 3) {
  //     fullObject.depositMaxAmount1 = updatedValue;
  //     fullObject.depositAmount1 =
  //       fullObject.depositAmount1 == undefined ? 0 : fullObject.depositAmount1;
  //     fullObject.depositNumber1 =
  //       fullObject.depositNumber1 == undefined ? 0 : fullObject.depositNumber1;
  //   }
  //   this.decSelectList1.splice(index, 1);
  //   this.decSelectList1.push(fullObject);
  // }
  // updateDeclearationList1(id: number, p: number, event: any) {
  //   let index = this.decSelectList2.findIndex((obj) => obj.id === id);
  //   const updatedValue = event.target.value;
  //   const fullObject = this.decSelectList2[index];
  //   if (p === 1) {
  //     fullObject.depositNumber2 = updatedValue;
  //     fullObject.depositAmount2 =
  //       fullObject.depositAmount2 == undefined ? 0 : fullObject.depositAmount2;
  //     fullObject.depositMaxAmount2 =
  //       fullObject.depositMaxAmount2 == undefined
  //         ? 0
  //         : fullObject.depositMaxAmount2;
  //   } else if (p === 2) {
  //     fullObject.depositAmount2 = updatedValue;
  //     fullObject.depositNumber2 =
  //       fullObject.depositNumber2 == undefined ? 0 : fullObject.depositNumber2;
  //     fullObject.depositMaxAmount2 =
  //       fullObject.depositMaxAmount2 == undefined
  //         ? 0
  //         : fullObject.depositMaxAmount2;
  //   } else if (p === 3) {
  //     fullObject.depositMaxAmount2 = updatedValue;
  //     fullObject.depositAmount2 =
  //       fullObject.depositAmount2 == undefined ? 0 : fullObject.depositAmount2;
  //     fullObject.depositNumber2 =
  //       fullObject.depositNumber2 == undefined ? 0 : fullObject.depositNumber2;
  //   }
  //   this.decSelectList2.splice(index, 1);
  //   this.decSelectList2.push(fullObject);
  // }
  showDetails(id: number) {
    window.open("/customeraccount/accountDetail/" + id, "_blank");
  }
  // Delearation
  // getRiskGradingList() {
  //   this._service.GetRiskGradingList().subscribe((res) => {
  //     res.forEach((element) => {
  //       if (element.type === 1) {
  //         this.riskGradingList.push(element);
  //       }
  //     });
  //   });
  // }
  //End Delearation
  // Modal
  openNomineeModal(content) {
    this.totalShare -= this.nomineeGroup.value.nomineeShare;
    this.getOccupationList();
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  // End Modal
  //Save
  onsubmit() {
    if (this.totalShare != 100) {
      this.shared.ShowAlert(
        "Warning",
        "Total nominee shares should be 100",
        "warning"
      );
      return false;
    }
    if (this.isEdit) {
      this.model.Id = this.frmGroup.value.id;
    }

 
//  const formattedDateOfBirth = this.datePipe.transform(
//    this.frmGroup.value.dateOfBirth,
//    "yyyy-MM-dd"
//  );
    this.model.productCode = this.frmGroup.value.productCode;
    this.model.CustomersInfoId = this.frmGroup.value.CustomersInfoId;
    ///this.model.ProductBuilderId = this.frmGroup.value.ProductBuilderId;
    this.model.NomineeInfoSId = this.frmGroup.value.NomineeInfoSId;
    this.model.AccountTilte = this.frmGroup.value.AccountTilte;
    this.model.AccountTilteBn = this.frmGroup.value.AccountTilteBn;

    this.model.dateOfBirth = this.frmGroup.value.dateOfBirth;
    this.model.nidNo = this.frmGroup.value.nidNo;
     

    this.model.fatherName = this.frmGroup.value.fatherName;
    this.model.motherName = this.frmGroup.value.motherName;
    this.model.gender = this.frmGroup.value.gender;
    this.model.mobileNo = this.frmGroup.value.mobileNo;
    this.model.email = this.frmGroup.value.email;
    this.model.eTinNo = this.frmGroup.value.eTinNo;
    this.model.profession = this.frmGroup.value.profession;
    this.model.taxReturnFile = this.frmGroup.value.taxReturnFile;



    this.model.OpeningBalance = this.frmGroup.value.OpeningBalance;
    this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId;
    this.model.ledgerId = this.frmGroup.value.ledgerId;
    this.model.customerAccountNo = this.frmGroup.value.customerAccountNo;
    this.model.reason = this.frmGroup.value.reason;
    this.model.depositDeclearation = this.decSelectList1;
    this.model.withdrowDeclearation = this.decSelectList2;
    // this.model.riskGrading1=this.frmGroup.value.riskGrading1;
    this.model.monthlyIncome = this.frmGroup.value.monthlyIncome;

    //this.model.dpsTenureId = this.frmGroup.value.dpsTenureId;
    if (this.isMinor === false) {
      this.model.accountMinor = false;
    } else {
      this.model.accountMinor = true;
    }
    this.model.accountMinorName = this.frmGroup.value.accountMinorName;
    this.model.accountMinorDOB = this.frmGroup.value.accountMinorDOB;
    this.model.accountMinorGuardian = this.frmGroup.value.accountMinorGuardian;
    this.model.accountMinorGuardianRelation =
    this.frmGroup.value.accountMinorGuardianRelation;
    //this.model.nominees = this.nolineeList;
    this.model.dpsNominees = this.nolineeList;

 


    this._service.saveDPSAccount(this.model).subscribe((res) => {

         if (res === 1) {
             this.shared.ShowAlert("Success", "Save Successful", "success");
              this.router.navigate(["/customeraccount/customerAcclist"]);
         }
   

      //this.checkPayment();

     
    });
  }

  checkPayment() {
    this.dueAmount = this.frmGroup.value.OpeningBalance;
    if (this.dueAmount > 0) {
      const paymentRequest: PaymentRequestVm = {
        name: [this.frmGroup.value.customerAccountNo],
        price: [this.dueAmount],
        url: ["url1"],
        qty: [1],
        pname: "Account Opening Fee",
        purl: "Account Opening Fee Url",
        pprice: this.dueAmount,
        customer_account: this.frmGroup.value.customerAccountNo,
        card_no: "",
        valueType: "dps",
      };
      this.paymentService.checkoutPayment(paymentRequest).subscribe(
        (response) => {
          if (response.gatewayPageURL) {
            window.location.href = response.gatewayPageURL;
          } else {
            console.error("No gatewayPageURL found in the response");
          }
        },
        (error) => {
          console.error("Payment request failed", error);
        }
      );
    }
  }

  initiatePayment() {
    const backendUrl = "https://localhost:44395/api/Payment/InitiatePayment";

    const paymentData = {
      total_amount: "100.00",
      currency: "BDT",
    };

    this.http.post(backendUrl, paymentData).subscribe(
      (response: any) => {
        if (response && response.GatewayPageURL) {
          window.location.href = response.GatewayPageURL;
        } else {
          console.error("Payment initiation failed");
        }
      },
      (error) => {
        console.error("Error initiating payment:", error);
      }
    );
  }
  //End Save
  //Reporting Start
  PrintKyc(id: number) {
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }
  //Reporting End
  //Extra
  onNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    if (inputValue.startsWith("0")) {
    } else {
      inputValue = inputValue.replace(/\D/g, "");
    }
    inputElement.value = inputValue;
  }
  //End Extra

  //#regionNID

  selectNidBackPic(event: any): void {
    this.message = "";
    this.backPreview = "";
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.backPreview = "";
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.backPreview = e.target.result;
          this.changeDetectorRef.detectChanges();
          const div = document.getElementById("imageBackPreview");
          div.style.width = 90 + "%";
          div.style.height = 90 + "%";
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  relation: string = "select";
  getRelation(value: string) {
    this.relation = value;
  }

  LoadRelationList() {
    this.relationSvc.getRelationList().subscribe((res) => {
      this.relationList = res;
    });
  }
  goBack() {
    this.location.back();
  }
  //#endregion
}
