import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustommeraccountService } from 'app/customer-account/custommeraccount.service';
import { Customer } from 'app/customer/model/customer.model';
import { Occupation } from 'app/master-data/basic/basic-model/occupation';
import { OccupationService } from 'app/master-data/basic/basic-service/occupation.service';
import { ProductBuilder } from 'app/models/deposit/product-builder/product-builder';
import { PaymentService } from 'app/payment/payment.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { SharedService } from 'app/services/shared.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { PaymentRequestVm } from 'app/payment/models/payment-request-vm';
import { FdrdetailsService } from '../fdrdetails.service';
import { DatePipe } from '@angular/common';
import { RelationServiceService } from 'app/master-data/basic/basic-service/relation-service.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-fdr-account',
  templateUrl: './create-fdr-account.component.html',
  styleUrls: ['./create-fdr-account.component.scss']
})
export class CreateFdrAccountComponent implements OnInit {
  isMinor: boolean = false;
  dueAmount: number = 500.00;
  totalShare: number = 0;
  productId: any = 0;
  selectedProductId: number;
  //OpenDates: string;
  //MaturityDates: string;
  customerlist: Customer[] = [];
  //productlist: ProductBuilder[] = [];
  productlist: any[] = [];
  model: any = {};
  isEdit: boolean = false;
  frmGroup: FormGroup;
  nomineeGroup: FormGroup;
  nolineeList: any[] = [];
  occupationList: Occupation[] = [];
  declearationList: any[] = [];
  decSelectList1: any[] = [];
  decSelectList2: any[] = [];
  riskGradingList: any[] = [];
  nominee: any = {};
  customerAccountNo: any;
  ledgerRelationId: any;
  ledgerRelationName: string;
  relationName: string;
  accountNo: string = '';
  closeResult: string;

  vActive = 'top'; // Vertical Pills
  active = 1; // Basic Navs
  kActive = 'Customer Info'; // Keep content
  disabled = true;

  tabs = [1, 2, 3, 4, 5];
  counter = this.tabs.length + 1;

  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;

  dataId: any[] = [];
  dataType: any[] = [];
  dataNumber: any[] = [];
  dataAmount: any[] = [];
  dataMax: any[] = [];
  isAdmin: boolean = false; 
  message = '';
  backPreview = '';
  progress = 0;
  selectedFiles?: FileList;
  currentFile?: File;
  relationList: Observable<any[]>;
  constructor(
    private _fdrservice: FdrdetailsService,
    private _service: CustommeraccountService,
    private _productService: ProductBuilderService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private paymentService: PaymentService,
    private _occupationService: OccupationService,
    private pdfService: PdfService,
    private layoutService: LayoutService,
    private datePipe:DatePipe,
    private relationSvc: RelationServiceService,
  ) { 
    this.LoadRelationList();
    this.nomineeGroup=this.formBuilder.group({
      photo:['']
    });
  }

  ngOnInit(): void {
    this.populateForm();

    this.route.paramMap.subscribe(params => {
      this.productId = parseInt(params.get('Id'), 10);
    });

    this.GetAccountNo(this.productId);
    //this.OpenDates = this.datePipe.transform(this.frmGroup.controls.OpenDate.value, 'yyyy-MM-dd');
    //this.MaturityDates = this.datePipe.transform(this.frmGroup.controls.MaturityDate.value, 'yyyy-MM-dd');

    this.populateNomineeForm();
    this.getCustomerListInfo();
    this.getProductList();
    this.getDeclearationList();
    this.getRiskGradingList();
    //this.loadLedgerList();
    this.getCurrentUserRole();
  }


  populateForm() {
    this.frmGroup = this.formBuilder.group({
      id: [0],
      CustomersInfoId: [null],
      CustomersInfoNumber: [null],
      ProductBuilderId: [''],
      NomineeInfoSId: [null],
      OpeningBalance: [null],
      AccountTilte: [''],
      AccountTilteBn: [''],
      ledgerRelationId: [null],
      customerAccountNo: [null],
      ledgerRelationName: [null],
      customersInfo: [null],
      CustomersId: [null],
      accountCurrency: [null],
      accountPurpose: [''],
      accountMinor: [''],
      accountMinorName: [''],
      accountMinorDOB: [''],
      accountMinorGuardian: [''],
      accountMinorGuardianRelation: [''],
      //riskGrading1: [''],
     // monthlyIncome: [null],
     relationName: [null],
      //Fdr Details
       //tenureId :[null],
       OpenDate: [this.datePipe.transform(new Date(), 'yyyy-MM-dd') ],
       MaturityDate :[''],
      // ClosingDate :[''],
       //ReceiptNo :[''],
       InterestAmount:[null],
       Security:[0],
      checkRenew:[0],
       //SecurityPercentage:[0],
       InterestRate:[0]



    })
  }


  getCurrentUserRole() {
    this._service.GetUserRole().subscribe((userData: any) => {

      if (userData.roles.includes('Admin')) {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
        this.accountNo = userData.userName,
          // customersInfo:res.customerRCode+", "+res.customerNameEng+' - '+res.nidNo,
          this.GetAllCustomersByMobileNo();
      }
      // Display username in an alert
      //alert("Logged in as: " + userData.userName);
    });
  }


  GetAccountNo(productId: number): void {
    this._service.GetAccountNo1(productId).subscribe((res: any) => {
      //this.accountsCode = res.accountCode;
      this.frmGroup.patchValue({
        customerAccountNo: res.code,
      })
    });

    this._productService.GetMaturityDate(productId).subscribe((res2: any) => {
      this.frmGroup.patchValue({
        MaturityDate: this.datePipe.transform(res2, 'yyyy-MM-dd')
      })
    });

    this._productService.getLedgerByProductId(productId).subscribe((res22: any) => {
    
      this.frmGroup.patchValue({
        ledgerRelationId: res22.ledger?.groupId,
        ledgerRelationName: res22.ledger?.accountCode + '-' + res22.ledger?.accountName,

        // ledgerRelationId: res22.accountGroupId,
        // ledgerRelationName: res22.accountGroup?.groupCode + '-' + res22.accountGroup?.groupName,
        accountCurrency: res22.currency?.currency,
        InterestRate: res22.interestRateMax

      })
    });
  }

  calculateInterestAmount() {
    const openingBalance = this.frmGroup.controls.OpeningBalance.value;
    const interestRate = this.frmGroup.controls.InterestRate.value;
    const interestAmount = (openingBalance * interestRate) / 100;

    this.frmGroup.patchValue({
      InterestAmount: interestAmount
    });

  }
  
  getCustomerListInfo() {
    this._service.getCustomerList().subscribe(res => {
      this.customerlist = res;
    })
  }

  getOccupationList() {
    this._occupationService.getlist().subscribe(res => {
      this.occupationList = res;
    })
  }

  getProductList() {
    this._productService.getList().subscribe(res => {
      this.productlist = res.filter(pro => pro.id === this.productId);
    
      if (this.productlist.length > 0) {
        this.selectedProductId = this.productlist[0].id;
      }
    })
  }

  GetAllCustomersByMobileNo() {

    this._service.GetAllCustomersByMobileNo(this.accountNo).subscribe(res => {
      if (res != null) {
        this.frmGroup.patchValue({
          customersInfo: res.customerRCode + ", " + res.customerNameEng + ' - ' + res.nidNo,
          CustomersId: res.id,
          CustomersInfoId: res.id,
          CustomersInfoNumber: this.accountNo,
          AccountTilte: res.customerNameEng,
          AccountTilteBn: (res.customerNameBn == null) ? res.customerNameEng : res.customerNameBn
        })
      }
      else {
        swal('Warning', 'Invalid Account No', 'warning');
      }
    })
  }

  onNavChange(changeEvent: NgbNavChangeEvent, t: number) {
    if (t === 0 && changeEvent.nextId === 1) {
      this.kActive = "Customer Info";
    }
    else if (t === 0 && changeEvent.nextId === 2 && this.step1) {
      this.kActive = "Product Info";
    }
    else if (t === 0 && changeEvent.nextId === 3 && this.step2) {
      this.kActive = "Minor Info";
    }
    else if (t === 0 && changeEvent.nextId === 4 && this.step3) {
      this.kActive = "Nominee Info";
    }
    else if (t === 0 && changeEvent.nextId === 5 && this.step4) {
      this.kActive = "Declearation";
    }
    else if (t === 1 && this.frmGroup.value.AccountTilte.length > 1 && this.frmGroup.value.AccountTilteBn.length > 1 && this.frmGroup.value.CustomersInfoId > 0) {
      this.step1 = true;
      this.active = 2;
      this.kActive = "Product Info";
    }
    else if (t === 1 && this.frmGroup.value.AccountTilte.length < 1 && this.frmGroup.value.AccountTilteBn.length < 1 && (this.frmGroup.value.CustomersInfoId < 1 || this.frmGroup.value.CustomersInfoId === null)) {
      this.step1 = false;
      swal('Warning', 'Fill up all required feild', 'warning');
    }
    else if (t === 2 && (this.step2 || (this.frmGroup.value.accountPurpose.length > 1 && this.frmGroup.value.ProductBuilderId > 0))) {
      this.step2 = true;
      this.active = 3;
      this.kActive = "Minor Info";
    }
    else if (t === 2 && (this.frmGroup.value.accountPurpose.length < 1 || this.frmGroup.value.ProductBuilderId === '')) {
      this.step2 = false;
      swal('Warning', 'Fill up all required feild', 'warning');
    }
    else if (t === 3 && (this.step3 || (this.frmGroup.value.OpeningBalance > 1 && !this.isMinor) || (this.isMinor && this.frmGroup.value.accountMinorName.length > 1 && this.frmGroup.value.accountMinorDOB.length > 1 && this.frmGroup.value.accountMinorGuardianRelation.length > 1 && this.frmGroup.value.OpeningBalance > 1))) {
      this.step3 = true;
      this.active = 4;
      this.kActive = "Nominee Info";
    }
    else if (t === 3 && ((!this.isMinor && (this.frmGroup.value.OpeningBalance === null || this.frmGroup.value.OpeningBalance < 1)) || (this.isMinor && (this.frmGroup.value.accountMinorName.length < 1 || this.frmGroup.value.accountMinorDOB.length < 1 || this.frmGroup.value.accountMinorGuardian.length < 1 || this.frmGroup.value.accountMinorGuardianRelation.length < 1 || this.frmGroup.value.OpeningBalance === null || this.frmGroup.value.OpeningBalance < 1)))) {
      //alert(this.isMinor)
      //alert(this.frmGroup.value.OpeningBalance)
      this.step3 = false;
      swal('Warning', 'Fill up all required feild', 'warning');
    }
    else if (t === 4 && (this.step4 || this.nolineeList.length > 0) && this.totalShare === 100) {
      this.active = 5;
      this.kActive = "Declearation";
    }
    else if (t === 4 && this.nolineeList.length < 1) {
      this.step4 = false;
      swal('Warning', 'Fill up all required feild', 'warning');
    }
    else if (t === 4 && this.totalShare != 100) {
      this.step4 = false;
      this.shared.ShowAlert("Warning", "Total nominee shares should be 100", "warning");
    }
    else {
      changeEvent.preventDefault();
    }
  }

  refresh() {
    this.isEdit = false;
    this.populateForm();
    this.model = {};
  }

  editAccount(item: any) {
    this.isEdit = true;
    this.frmGroup.patchValue({
      id: item.Id,
      CustomersInfoId: item.CustomersInfoId,
      NomineeInfoSId: item.NomineeInfoSId,
      ProductBuilderId: item.ProductBuilderId,
      AccountTilte: item.AccountTilte,
      OpeningBalance: item.OpeningBalance,
      ledgerRelationId: item.ledgerRelationId,
      customerAccountNo: item.customerAccountNo,
    });
  }

  checkMinorOrNot(event: Event) {
    const target = event.target as HTMLInputElement;
    this.step3 = false;
    this.isMinor = target.checked;
  }
  //Nominee
  populateNomineeForm() {
    this.nomineeGroup = this.formBuilder.group({
      nomineeNameSl: [0],
      nomineeName: [''],
      nomineeDOB: [''],
      nomineePresentAddress: [''],
     
      nomineeOccupation: [''],
      nomineeNID: [''],
      nomineePassport: [''],
      nomineeBirthIdentity: [''],
      nomineeShare: [0],
      relationId: [''],
      relationName: [''],
      photo: [''],
    })
  }
  addNominee() {
    this.totalShare += this.nomineeGroup.value.nomineeShare;
    if (this.nomineeGroup.valid && this.nomineeGroup.value.nomineeNameSl === 0 && (this.nomineeGroup.value.nomineePassport != null || this.nomineeGroup.value.nomineeNID != null || this.nomineeGroup.value.nomineeBirthIdentity != null || this.nomineeGroup.value.nomineeETin != null || this.nomineeGroup.value.nomineeDriveLinc != null)) {
      this.nomineeGroup.value.nomineeNameSl = this.nolineeList.length + 1;
      this.nominee.nomineeName = this.nomineeGroup.value.nomineeName;
      this.nominee.nomineeDOB = this.nomineeGroup.value.nomineeDOB;
      this.nominee.nomineePresentAddress = this.nomineeGroup.value.nomineePresentAddress;
      this.nominee.nomineeOccupation = this.nomineeGroup.value.nomineeOccupation;
      this.nominee.nomineeNID = this.nomineeGroup.value.nomineeNID;
      this.nominee.nomineePassport = this.nomineeGroup.value.nomineePassport;
      this.nominee.nomineeBirthIdentity = this.nomineeGroup.value.nomineeBirthIdentity;
      this.nominee.nomineeShare = this.nomineeGroup.value.nomineeShare;
      this.nominee.relationId = this.nomineeGroup.value.relationId;


      this.relationSvc.GetRelationById(this.nomineeGroup.value.relationId).subscribe((res: any) => {
        this.nomineeGroup.patchValue({
          relationName: res.name
        })
      });
      this.nominee.photo = this.backPreview;
      this.nolineeList.push(this.nominee);
      this.populateNomineeForm();
      
    }
    else if (this.nomineeGroup.value.nomineeNameSl > 0 && this.nomineeGroup.valid && (this.nomineeGroup.value.nomineePassport != null || this.nomineeGroup.value.nomineeNID != null || this.nomineeGroup.value.nomineeBirthIdentity != null || this.nomineeGroup.value.nomineeETin != null || this.nomineeGroup.value.nomineeDriveLinc != null)) {
      let index = this.nolineeList.findIndex((obj) => obj.nomineeNameSl === this.nomineeGroup.value.nomineeNameSl);
      this.nominee.nomineeNameSl = this.nomineeGroup.value.nomineeNameSl;
      this.nominee.nomineeName = this.nomineeGroup.value.nomineeName;
     
      this.nominee.nomineeDOB = this.nomineeGroup.value.nomineeDOB;
      this.nominee.nomineePresentAddress = this.nomineeGroup.value.nomineePresentAddress;
     
      this.nominee.nomineeOccupation = this.nomineeGroup.value.nomineeOccupation;
      this.nominee.nomineeNID = this.nomineeGroup.value.nomineeNID;
      this.nominee.nomineePassport = this.nomineeGroup.value.nomineePassport;
      this.nominee.nomineeBirthIdentity = this.nomineeGroup.value.nomineeBirthIdentity;
      this.nominee.relationId = this.nomineeGroup.value.relationId;
      this.nominee.photo = this.backPreview;


      this.nominee.name = this.nomineeGroup.value.name;
      this.relationSvc.GetRelationById(this.nomineeGroup.value.relationId).subscribe((res: any) => {
        this.nomineeGroup.patchValue({
          relationName: res.name
        })
      });
      this.nominee.nomineeShare = this.nomineeGroup.value.nomineeShare;
      this.nolineeList.splice(index, 1);
      this.nolineeList.push(this.nomineeGroup.value);
      this.populateNomineeForm();
    }
    else {
      swal('Warning', 'Input All Required Feild', 'warning');
    }
  }

  editNominee(obj: any) {
    this.nomineeGroup.patchValue({
      nomineeNameSl: obj.nomineeNameSl,
      nomineeName: obj.nomineeName,
      nomineeDOB: obj.nomineeDOB,
      nomineePresentAddress: obj.nomineePresentAddress,
      nomineeOccupation: obj.nomineeOccupation,
      nomineeShare: obj.nomineeShare,
      nomineeNID: obj.nomineeNID,
      nomineePassport: obj.nomineePassport,
      nomineeBirthIdentity: obj.nomineeBirthIdentity,
      relation: obj.relation,
      photo: obj.photo,
    })
  }
  deleteNominee(obj: any) {
    this.nolineeList.splice(this.nolineeList.findIndex((li) => li.nomineeNameSl === obj.nomineeNameSl), 1);
  }
  //End Nominee
  // Delearation  
  getDeclearationList() {
    this._service.GetDeclearationList().subscribe(res => {
      res.forEach(element => {
        this.declearationList.push(element);
      });
      res.forEach(element1 => {
        element1.depositNumber1 = 0;
        element1.depositAmount1 = 0;
        element1.depositMaxAmount1 = 0.00;
        this.decSelectList1.push(element1);
      });
      res.forEach(element2 => {
        element2.depositNumber2 = 0;
        element2.depositAmount2 = 0;
        element2.depositMaxAmount2 = 0;
        this.decSelectList2.push(element2);
      });
    })
  }
  updateDeclearationList(id: number, p: number, event: any) {
    let index = this.decSelectList1.findIndex((obj) => obj.id === id);
    const updatedValue = event.target.value;
    const fullObject = this.decSelectList1[index];
    if (p === 1) {
      fullObject.depositNumber1 = updatedValue;
      fullObject.depositAmount1 = (fullObject.depositAmount1 == undefined) ? 0 : fullObject.depositAmount1;
      fullObject.depositMaxAmount1 = (fullObject.depositMaxAmount1 == undefined) ? 0 : fullObject.depositMaxAmount1;
    }
    else if (p === 2) {
      fullObject.depositAmount1 = updatedValue;
      fullObject.depositNumber1 = (fullObject.depositNumber1 == undefined) ? 0 : fullObject.depositNumber1;
      fullObject.depositMaxAmount1 = (fullObject.depositMaxAmount1 == undefined) ? 0 : fullObject.depositMaxAmount1;
    }
    else if (p === 3) {
      fullObject.depositMaxAmount1 = updatedValue;
      fullObject.depositAmount1 = (fullObject.depositAmount1 == undefined) ? 0 : fullObject.depositAmount1;
      fullObject.depositNumber1 = (fullObject.depositNumber1 == undefined) ? 0 : fullObject.depositNumber1;
    }
    this.decSelectList1.splice(index, 1);
    this.decSelectList1.push(fullObject);
  }
  updateDeclearationList1(id: number, p: number, event: any) {
    let index = this.decSelectList2.findIndex((obj) => obj.id === id);
    const updatedValue = event.target.value;
    const fullObject = this.decSelectList2[index];
    if (p === 1) {
      fullObject.depositNumber2 = updatedValue;
      fullObject.depositAmount2 = (fullObject.depositAmount2 == undefined) ? 0 : fullObject.depositAmount2;
      fullObject.depositMaxAmount2 = (fullObject.depositMaxAmount2 == undefined) ? 0 : fullObject.depositMaxAmount2;
    }
    else if (p === 2) {
      fullObject.depositAmount2 = updatedValue;
      fullObject.depositNumber2 = (fullObject.depositNumber2 == undefined) ? 0 : fullObject.depositNumber2;
      fullObject.depositMaxAmount2 = (fullObject.depositMaxAmount2 == undefined) ? 0 : fullObject.depositMaxAmount2;
    }
    else if (p === 3) {
      fullObject.depositMaxAmount2 = updatedValue;
      fullObject.depositAmount2 = (fullObject.depositAmount2 == undefined) ? 0 : fullObject.depositAmount2;
      fullObject.depositNumber2 = (fullObject.depositNumber2 == undefined) ? 0 : fullObject.depositNumber2;
    }
    this.decSelectList2.splice(index, 1);
    this.decSelectList2.push(fullObject);
  }
  showDetails(id: number) {
    window.open('/customeraccount/accountDetail/' + id, '_blank');
  }
  // Delearation  
  getRiskGradingList() {
    this._service.GetRiskGradingList().subscribe(res => {
      res.forEach(element => {
        if (element.type === 1) {
          this.riskGradingList.push(element);
        }
      });
    })
  }
  //End Delearation
  // Modal
  openNomineeModal(content) {
    this.totalShare -= this.nomineeGroup.value.nomineeShare;
    this.getOccupationList();
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }
  // End Modal
  //Save
  onsubmit() {
    if (this.totalShare != 100) {
      this.shared.ShowAlert("Warning", "Total nominee shares should be 100", "warning");
      return false;
    }
    if (this.isEdit) {
      this.model.Id = this.frmGroup.value.id;
    }
    this.model.CustomersInfoId = this.frmGroup.value.CustomersInfoId;
    this.model.ProductBuilderId = this.frmGroup.value.ProductBuilderId;
    this.model.NomineeInfoSId = this.frmGroup.value.NomineeInfoSId;
    this.model.AccountTilte = this.frmGroup.value.AccountTilte;
    this.model.AccountTilteBn = this.frmGroup.value.AccountTilteBn;
    this.model.OpeningBalance = this.frmGroup.value.OpeningBalance;
    this.model.ledgerRelationId = this.frmGroup.value.ledgerRelationId;
    this.model.customerAccountNo = this.frmGroup.value.customerAccountNo;
    this.model.reason = this.frmGroup.value.reason;
    this.model.depositDeclearation = this.decSelectList1;
    this.model.withdrowDeclearation = this.decSelectList2;
    //this.model.riskGrading1 = this.frmGroup.value.riskGrading1;
    //this.model.monthlyIncome = this.frmGroup.value.monthlyIncome;

    this.model.OpenDate= this.frmGroup.value.OpenDate;
    this.model.MaturityDate= this.frmGroup.value.MaturityDate;
    //this.model.ClosingDate= this.frmGroup.value.ClosingDate;
    this.model.InterestAmount= this.frmGroup.value.InterestAmount;
    this.model.Security= this.frmGroup.value.Security;


    this.model.checkRenew = (this.frmGroup.value.checkRenew === 1) ? true : false;
   // this.model.SecurityPercentage= this.frmGroup.value.SecurityPercentage;
   




    // if (this.isMinor === false) {
    //   this.model.accountMinor = false;
    // }
    // else {
    //   this.model.accountMinor = true;
    // }
    // this.model.accountMinorName = this.frmGroup.value.accountMinorName;
    // this.model.accountMinorDOB = this.frmGroup.value.accountMinorDOB;
    // this.model.accountMinorGuardian = this.frmGroup.value.accountMinorGuardian;
    // this.model.accountMinorGuardianRelation = this.frmGroup.value.accountMinorGuardianRelation;
    
    this.model.nominees = this.nolineeList;

    this._fdrservice.saveFDR(this.model).subscribe(res => {
      this.shared.ShowAlert("Success", "Save Successful", "success");
      //this.getCustomerAccountListInfo();

      this.checkPayment();
      this.router.navigate(['/fdr-select/fdr-list']);
    })
    this.changeDetectorRef.detectChanges();
    this.checkPayment();
  }




  checkPayment() {
    this.dueAmount = this.frmGroup.value.OpeningBalance;
    if (this.dueAmount > 0) {
      const paymentRequest: PaymentRequestVm = {
        name: [this.frmGroup.value.customerAccountNo],
        price: [this.dueAmount],
        url: ['url1'],
        qty: [1],
        pname: 'Account Opening Fee',
        purl: 'Account Opening Fee Url',
        pprice: this.dueAmount,
        customer_account: this.frmGroup.value.customerAccountNo,
        card_no:'',
        valueType:'fdr',
      };
      this.paymentService.checkoutPayment(paymentRequest).subscribe(
        (response) => {
          if (response.gatewayPageURL) {
            // Use the navigateByUrl method to navigate to the specified URL
            window.location.href = response.gatewayPageURL;
          }
          else {
            console.error('No gatewayPageURL found in the response');
          }
        },
        (error) => {
          console.error('Payment request failed', error);
        }
      );
    }
  }

  initiatePayment() {
    const backendUrl = 'https://localhost:44395/api/Payment/InitiatePayment';
    // Replace '100.00' with the actual payment amount from your Angular component
    const paymentData = {
      total_amount: '100.00', // Replace this with the actual payment amount
      currency: 'BDT', // Replace this with the appropriate currency code if needed
      // Add more payment data as needed
    };

    this.http.post(backendUrl, paymentData).subscribe(
      (response: any) => {
        // Handle the response from the backend, e.g., redirect to the payment gateway URL
        if (response && response.GatewayPageURL) {
          window.location.href = response.GatewayPageURL;
        } else {
          // Handle the case when the payment initiation failed
          console.error('Payment initiation failed');
        }
      },
      (error) => {
        // Handle the error if the HTTP request fails
        console.error('Error initiating payment:', error);
      }
    );
  }
  //End Save
  //Reporting Start
  PrintKyc(id: number) {
    this.pdfService.OpenKycPdf(id).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    })
  }
  //Reporting End
  //Extra
  onNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    // If the input starts with '0', keep it as a string
    if (inputValue.startsWith('0')) {
      // Do nothing, keep the value as is
    } else {
      // Remove non-numeric characters
      inputValue = inputValue.replace(/\D/g, '');
    }

    // Update the input value
    inputElement.value = inputValue;
  }

  selectNidBackPic(event: any): void {
    this.message = '';
    this.backPreview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.backPreview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.backPreview = e.target.result;
          this.changeDetectorRef.detectChanges();
          const div = document.getElementById('imageBackPreview');
          div.style.width = 90 + '%';
          div.style.height = 90 + '%';
        };
        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  relation: string = 'select';
  getRelation(value: string) {
    this.relation = value;
  }

  LoadRelationList() {
    this.relationSvc.getRelationList().subscribe(res => {
      this.relationList = res;
    })
  }

}
