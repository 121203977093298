import { Component, OnInit } from '@angular/core';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { Location } from "@angular/common";
@Component({
  selector: "app-profit-loss-report",
  templateUrl: "./profit-loss-report.component.html",
  styleUrls: ["./profit-loss-report.component.scss"],
})
export class ProfitLossReportComponent implements OnInit {
  currentDate = new Date();
  fromDate: string = this.currentDate.toISOString().split('T')[0];
  toDate: string = this.currentDate.toISOString().split('T')[0];
  reportBy: number;
  constructor(private pdfService: PdfService, private location: Location) {}

  ngOnInit(): void {}

  OpenPDF() {
    if(this.reportBy >= 0){
      this.pdfService
      .ProfitLossReport(this.fromDate, this.toDate, 1, 2, this.reportBy)
      .subscribe((res) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        window.open(url);
      });
    }
    else{
      alert('Please select report type');
    }
  }
  goBack() {
    this.location.back();
  }
}
