import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { LoginService } from 'app/pages/content-pages/login/login.service';
import { LoanProductService } from 'app/product/service/loan-product.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

  
  constructor(private loginService: LoginService,private loanProductService: LoanProductService) { }


  public compareValidator(controlToValidate: string, controlToCompare: string): ValidatorFn
  {
    return (formGroup: FormGroup): ValidationErrors | null =>
    {
      const isValid = true;
      if (!formGroup.get(controlToValidate).value){
        return null;
      }

      if (formGroup.get(controlToValidate).value === formGroup.get(controlToCompare).value){
        return null;
      }else {
        formGroup.get(controlToValidate).setErrors({
          compareValidator: {valid: false}
        });
        return {compareValidator: {valid: false}};
      }
    };
  }

  public DuplicateEmailValidator(): AsyncValidatorFn
  {

    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    {
      return this.loginService.getUserByEmail(control.value).pipe(map((existingUser: any) =>
      {
        if (existingUser != null)
        {
          return { uniqueEmail: { valid: false } };
        }
        else
        {
          return null;
        }
      }));
    };

  }

  
  public DuplicateMobileValidator(): AsyncValidatorFn
  {

    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    {
      return this.loginService.getUserByPhone(control.value).pipe(map((existingUser: any) =>
      {
        if (existingUser != null)
        {
          return { uniqueMobile: { valid: false } };
        }
        else
        {
          return null;
        }
      }));
    };

  }


  public DuplicateProductBuilderCode(): AsyncValidatorFn
  {

    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    {
      return this.loanProductService.getLoanProductByCode(control.value).pipe(map((existingData: any) =>
      {
        
        if (existingData != null)
        {
          return { uniqueCode: { valid: false } };
        }
        else
        {
          return null;
        }
      }));
    };

  }

  public DuplicateProductBuilderVersionCode(code): AsyncValidatorFn
  {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
    {
      return this.loanProductService.getLoanProductVersionByCode(control.value,code).pipe(map((existingData: any) =>
      {
        if (existingData != null)
        {
          return { uniqueVersionCode: { valid: false },uniqueCode: { valid: false } };
        }
        else
        {
          return null;
        }
      }));
    };

  }

}
