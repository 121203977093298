import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { FdrdetailsService } from 'app/fdr-account/fdrdetails.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import { DatePipe, Location } from "@angular/common";
import swal from "sweetalert";
@Component({
  selector: "app-dps-encashment-list",
  templateUrl: "./dps-encashment-list.component.html",
  styleUrls: ["./dps-encashment-list.component.scss"],
})
export class DpsEncashmentListComponent implements OnInit {
  dateFormat: string = "";
  dpsEncashmentList: any[] = [];
  customername: string = "";
  customercode: string = "";
  mobileNumber: string = "";
  accountNo: string = "";
  accountName: string = "";
  nidNo: string = "";
  typeId: number = 0;
  customerList: any[] = [];
  dpsList: any[] = [];
  transactionList: any[] = [];

  // ---------------------------------------
  encashmentDate: any;
  sApplyDate: any;
  eApplyDate: any;
  //--------------------------------------------

  DPSInfoTable: boolean = true;
  showaccountNoField: boolean = false;
  showencashmentDateField: boolean = false;
  noDataAvailable: boolean = false;

  selectedFilterType: number = 0;

  ProductId: number;
  productList: any[] = [];

  constructor(
    private pdfService: PdfService,
    private _fdrService: FdrdetailsService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef,
    private location: Location,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
      var thumb = JSON.parse(localStorage.getItem("thumb"));
      this.dateFormat = thumb.dateFormat;
    this.GetAllDPSEncashment();
  }
  onFilterTypeChange(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showaccountNoField = true;
        break;
      case "2":
        this.showencashmentDateField = true;
        break;
    }
  }

  loadDPSRelatedData() {
    const defaultDate = "0001-01-01";
    this._serviceDashboard
      .GetAllDPSEncashmentBySearch(
        this.accountNo,
        this.accountName,
        this.encashmentDate ?? defaultDate
      )
      .subscribe((res) => {
        if (res != null) {
          this.dpsEncashmentList = res;
          this.noDataAvailable = this.dpsEncashmentList.length === 0;
          this.DPSInfoTable = true;
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }
  SearchByParameter() {
    this.typeId;
    this.DPSInfoTable = true;
  }

  GetAllDPSEncashment() {
    this._serviceDashboard.GetAllDPSEncashment().subscribe((res) => {
      if (res.length > 0) {
        this.dpsEncashmentList = res;

        this.changeDetect.detectChanges();
      }
    });
  }

  PrintKyc(id: number) {
    this.pdfService.OpenKycPdf(id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }

  DPSSchedulePDF(accountId: number) {
    this.pdfService.DPSOpenSchedulePdf(accountId).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }

  Approve(id: number) {
    this._serviceDashboard.ApproveDPSEncashment(id).subscribe((res) => {
      if (res != null) {
        this.shared.ShowAlert("Success", "Approved Successful", "success");
        this.GetAllDPSEncashment();
        this.changeDetect.detectChanges();
      }
    });
  }
  deleteFilterTypeFields(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showaccountNoField = false;
        this.accountNo = "";
        break;
      case "2":
        this.showencashmentDateField = false;
        this.eApplyDate = "0001-01-01";
        break;
    }
  }

  goBack() {
    this.location.back();
  }
}
