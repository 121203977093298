import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
// import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Observable, Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { LoginService } from "app/pages/content-pages/login/login.service";
import { LanguageServiceService } from "app/services/language-service.service";
import { RouteInfo } from "./vertical-menu.metadata";
import { UserService } from "app/services/user.service";
import { SharedService } from "app/services/shared.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationUser } from "app/user/user-management/user-model/application-user";
import { PdfService } from "app/pdf-reporting/pdf.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"]
  // animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  @ViewChild("resetcontent") resetContentTemplate;

  public menuItems: any[];
  menuItemInfo: RouteInfo[];
  level: number = 0;
  logoUrl = "assets/img/logo.png";
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  lang: string = "en";
  menus: RouteInfo[] = [];

  /////////////////////////////////////

  users: any[] = [];
  userlist: Observable<any[]>;
  formGroupReset: FormGroup;
  modalRef: NgbModalRef | undefined;
  model = new ApplicationUser();
  //model = {};
  errorMessage: string = "";
  closeResult: string;
  passwordValidate: string[];

  constructor(
    private router: Router,
    // public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private logInService: LoginService,
    private language: LanguageServiceService,
    private pdfService: PdfService,
    private userService: UserService,
    private shared: SharedService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.language.setLanguage();
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  // getMenuTitle(menuId: number): string {
  //   return this.lang === 'en' ? this.menuItemInfo.find(x => x.id == menuId).title : this.menuItemInfo.find(x => x.id == menuId).nameBn;
  // }

  ngOnInit() {
    const data = this.logInService.getNavbars().subscribe((res) => {
      this.menuItemInfo = res;
      //this.menuItems = ROUTES;
      this.changeDetectorRef.detectChanges();

      // this.populateForm();
      // this.checkFirstLogin();
    });

    this.logInService.getNavbars().subscribe((res) => {
      this.menuItemInfo = res;
      this.changeDetectorRef.detectChanges();
    });

    this.lang = localStorage.getItem("lang");
    this.menuItems = ROUTES;
  }

  //
  populateForm() {
    this.formGroupReset = this.formBuilder.group({
      userName: ["", Validators.required],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  checkFirstLogin() {
    const isFirstLogin = localStorage.getItem("isFirstLogin");
    this.openReset();
    // if (!isFirstLogin || isFirstLogin === "true") {
    //   this.openReset("resetcontent");
    //   localStorage.setItem("isFirstLogin", "false");
    // }
  }

  // openReset(resetcontent, item: any) {
  openReset() {
    // this.formGroupReset.patchValue({
    //   userName: item.userName,
    //   password: item.password,
    //   confirmPassword: item.confirmPassword,
    // });
    alert(6);
    this.populateForm();
    this.formGroupReset.patchValue({
      userName: "opus",
      password: "123456",
      confirmPassword: "123456",
    });
    this.modalRef = this.modalService.open(this.resetContentTemplate, {
      backdrop: "static",
      keyboard: false,
    });
  }

  ValidatePassword(event) {
  
    this.userService.validatePassword(event).subscribe(
      (response: string[]) => {
        this.passwordValidate = response;
       
      },
      (error: any) => {
        console.error("Error occurred:", error);
      }
    );
  }

  ResetSubmit() {
    if (this.formGroupReset.invalid) {
      this.shared.ShowAlert(
        "Alert",
        "Please fill in all required fields.",
        "warning"
      );
      return;
    }

    const password = this.formGroupReset.value.password;
    const confirmPassword = this.formGroupReset.value.confirmPassword;
    if (password !== confirmPassword) {
      this.errorMessage = "Password didn't match.";
      return;
    }

    const model = {
      userName: this.formGroupReset.value.userName,
      password: password,
      confirmPassword: confirmPassword,
    };

    this.userService.ResetPassword(model).subscribe(
      (res) => {
      
        this.shared.ShowAlert(
          "Thank You",
          "Password Updated successfully!",
          "success"
        );
        this.closeModal();
        this.router.navigate(["/user"]);
      },
      (err) => {
        this.shared.ShowAlert(
          "Alert",
          "password must be at least 6 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.!",
          "warning"
        );
      }
    );
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
  /////////////////////////////////////////////////////////////////////
  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.changeDetectorRef.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.innerWidth = event.target.innerWidth;
        //this.loadLayout();
      }).bind(this),
      500
    );
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") {
      // Horizontal Menu
      if (this.innerWidth < 1200) {
        // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === "Side") {
      // Vertical Menu{
      this.menuItems = ROUTES;
    }

    if (this.config.layout.sidebar.backgroundColor === "white") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });
    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }



  OpenPDF() {
    this.pdfService.OpenManualPdf().subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);

      window.open(url);
    });
  }


}
