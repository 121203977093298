<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'" >
  <div class="logo clearfix" style="background-color: white !important;left: 0; right: 0;">
    <a  class="logo-text float-left">
      <div class="logo-img" >
        <!-- <img [src]="logoUrl" alt="Opus logo"/> -->
        <img class="" src="./assets/img/logos/asper_logo_blue.png" alt="logo" style="height: 66px;
           width: 190px;" />
          <!-- <span class="text align-middle mr-1">Asper</span> -->
      </div>
      <!-- <span class="text align-middle">D-BANKING</span> -->
    </a>

    <!-- <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
      (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>

    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a> -->



<a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()"  [style.color]="config.layout.sidebar.collapsed ? 'green' : 'initial'" style="color: black;font-size: larger;">
  <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>



</a>
<a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
  <i class="ft-x"></i>
</a>






  </div>
</div>

<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar]  [disabled]="!perfectScrollbarEnable">
  <div class="nav-container">
    <ul appsidebardropdown="" class="navigation">
      <!-- First level menu -->
      <li appSidebarlink *ngFor="let menuItem of menuItemInfo" [parent]="menuItem?.title" [path]="menuItem?.path" level="{{level + 1}}"
       [hasSub]="menuItem.class.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{ lang == 'bn' ? menuItem.nameBn : menuItem.title }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a appSidebarAnchorToggle routerLink="{{menuItem.path}}"
          *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{ lang == 'bn' ? menuItem.nameBn : menuItem.title }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{ lang == 'bn' ? menuItem.nameBn : menuItem.title }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
         <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0 ">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title" [hasSub]="menuSubItem.class.includes('has-sub') ? true: false"
          [path]="menuSubItem.path" level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
            <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{ lang == 'bn' ? menuSubItem.nameBn : menuSubItem.title }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}"
              *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{ lang == 'bn' ? menuSubItem.nameBn : menuSubItem.title }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{ lang == 'bn' ? menuSubItem.nameBn : menuSubItem.title }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <!-- Third level menu -->
            <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
              <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title" [hasSub]="false"
              [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"
                  *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{ lang == 'bn' ? menuSubsubItem.nameBn  : menuSubsubItem.title  }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                    [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{ lang == 'bn' ? menuSubsubItem.nameBn  : menuSubsubItem.title  }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <div class="manual-box text-center" style="margin-top: 50px;">
    <img src="./assets/manual.png" style="cursor: pointer;" width="100" (click)="OpenPDF()" alt="File">
  </div>
</div>
<!-- Sidebar Content Ends -->
 <!-- ------------------------------------------------------------------------------------------------------------------ -->


<ng-template #resetPP let-c="close" let-d="dismiss">
  <div class="modal-dialog modal-md" style="height: 100%!important;">


    <div class="modal-content" style="height: 92%;">



      <form [formGroup]="formGroupResetsss">

        <div class="modal-header">
          <h4 class="modal-title">Reset Password</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label for="userName" class="col-sm-4 col-form-label">User Name</label>
                <div class="col-sm-8">

                  <input type="text" class="form-control" readonly formControlName="userName">
                  <input type="hidden" class="form-control" formControlName="userName">
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group row">
                <label for="password" class="col-sm-4 col-form-label">New Password</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" (keyup)="ValidatePassword($event.target.value)"
                    formControlName="password" placeholder="New Password...">
                  <ul>
                    <li *ngFor="let item of passwordValidate" class="text-danger"
                      style="font-size: 12px; font-weight: bold;">{{ item }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group row">
                <label for="confirmPassword" class="col-sm-4 col-form-label">Confirm Password</label>
                <div class="col-sm-8">
                  <input type="password" class="form-control" formControlName="confirmPassword" id="confirmPassword"
                    placeholder="Confirm Password...">
                  <div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="ResetSubmit()">Submit</button>

          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>
        </div>
      </form>

    </div>
  </div>
</ng-template>
