import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LedgerService } from 'app/accounting/service/ledger.service';
import { CountryInfoService } from 'app/master-data/basic/basic-service/country-info.service';
import { CapitalizationRule } from 'app/master-data/products/model/capitalizationRule';
import { AccuredService } from 'app/master-data/products/service/accured.service';
import { BalancetypeService } from 'app/master-data/products/service/balancetype.service';
import { CapitalizationRuleService } from 'app/master-data/products/service/capitalization-rule.service';
import { CapitalizeFrequencyService } from 'app/master-data/products/service/capitalize-frequency.service';
import { ChargeService } from 'app/master-data/products/service/charge.service';
import { CommissionService } from 'app/master-data/products/service/commission.service';
import { DaySizeService } from 'app/master-data/products/service/day-size.service';
import { ExciseDutyService } from 'app/master-data/products/service/excise-duty.service';
import { InterestCalculationService } from 'app/master-data/products/service/interest-calculation.service';
import { SlabService } from 'app/master-data/products/service/slab.service';
import { TaxKeyService } from 'app/master-data/products/service/tax-key.service';
import { ProductBuilderService } from 'app/product/service/product-builder.service';
import { Location } from "@angular/common";
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { PdfService } from 'app/pdf-reporting/pdf.service';
const apiUrlDoc = environment.baseUrl + '/api/Reporting/';
@Component({
  selector: "app-product-builder-list",
  templateUrl: "./product-builder-list.component.html",
  styleUrls: ["./product-builder-list.component.scss"],
})
export class ProductBuilderListComponent implements OnInit {
  dateFormat:string = "";

  list: any[] = [];
  model: any = {};
  closeResult: string;
  productDetails: any;
  isEdit: boolean = false;
  frmGroup: FormGroup;
  capRuleSelectList: CapitalizationRule[] = [];
  userName: string;

  constructor(
    private _service: ProductBuilderService,
    private _CommissionService: CommissionService,
    private _blanaceTypeService: BalancetypeService,
    private _exciseDutyService: ExciseDutyService,
    private _capitaliseRuleService: CapitalizationRuleService,
    private _intCalculationService: InterestCalculationService,
    private _accuredService: AccuredService,
    private _capitaliseFrequencyService: CapitalizeFrequencyService,
    private _daySizeService: DaySizeService,
    private _taxKeyService: TaxKeyService,
    private _countryService: CountryInfoService,
    private _slabService: SlabService,
    private _chargeService: ChargeService,
    private _ledgerService: LedgerService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.dateFormat;
    this.userName = localStorage.getItem('userName');
    this.getProductBuilderInfoList();
  }

  getProductBuilderInfoList() {
    this._service.getList().subscribe((res) => {
      this.list = res;
    });
  }

  getProductDetails(id: any) {
    this._service.ProductBuilderDetailsByProId(id).subscribe(
      (data: any) => {
     
        this.productDetails = data;
      },
      (error) => {
        console.error("Error fetching product details:", error);
      }
    );
  }

  edit(item: any) {
    this.isEdit = true;
   // this.router.navigate(['/product/product-builder/'+item.id]);
    this.router.navigate(["/product/product-builder-edit/" + item.id]);
    // this.frmGroup.patchValue({
    //   id: item.id,
    //   Code: item.code,
    //   Name: item.name,
    //   Version: item.version,
    //   Description: item.description,
    //   InterestRateMin: item.interestRateMin,
    //   InterestRateMax: item.interestRateMax,
    //   Currency: item.currency,
    //   DaySizeId: item.daySizeId,
    //   taxKeyId: item.taxKeyId,
    //   exciseDutyId: item.exciseDutyId,
    //   InterestCalculationTypeId: item.interestCalculationTypeId,
    //   InterestCalculationFrequencyId: item.interestCalculationFrequencyId,
    //   CapitalizationRuleId: item.iapitalizationRuleId,
    //   BalanceTypeId: item.balanceTypeId,
    //   inputorId: item.inputorId,
    //   authorizerId: item.authorizerId,
    //   AccuredId: item.accuredId,
    //   status: item.status,
    // });
    // if (item.id > 0) {
    //   this.findAddMultipleCapRuleMulti(item.id);
    // }
  }

  findAddMultipleCapRuleMulti(id: any) {
    this._capitaliseRuleService
      .GetProductWiseIntaCalculationRuleId(id)
      .subscribe((resData: any) => {
        this.capRuleSelectList = resData;
      });
  }
  //#region Detail

  openModal(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }

  openBuilderDetailsModal(content) {
    let options: any = {
      size: "xl",
      centered: false,
    };
    this.modalService.open(content, options).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      }
    );
  }
  delete(id:number,s:boolean){

    Swal.fire({
      title: 'Are you sure?',
      text: (s==true)?'You want to active!':'You want to deactive!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: (s==true)?'Active!':'Deactive!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this._service.deActive(id,s).subscribe(
          (data: any) => {
            Swal.fire(
              'Deactivated!',
              (s==true)?'Activated!':'Deactivated!',
              'success'
            ).then((result) => {
              this.getProductBuilderInfoList();
            });
          },
          (error) => {
            Swal.fire(
              'Failed!',
              (s==true)?'Unable to active!':'Unable to deactive!',
              'warning'
            )
          }
        );
      }
    });
    //this.shared.ShowAlert("Warnibg", "Unable to submit", "warning");
  }
  //#endregion
  download(){
    this.pdfService.DpsProducInfo(this.productDetails?.productInfo?.id).subscribe((res) => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      window.open(url);
    });
    // const fileUrl = `${apiUrlDoc}ProductPdf?id=${this.productDetails?.productInfo?.id}`;
    // window.open(fileUrl, '_blank');
  }
  goBack() {
    this.location.back();
  }
}
