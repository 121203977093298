import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { id } from '@swimlane/ngx-datatable';
import { th } from 'date-fns/locale';

const apiUrl = environment.baseUrl;

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomerByAccountNo(accountNo: string): Observable<any> {
    return this.http.get<any>(
      apiUrl + "/get-customer-by-accountNo/" + accountNo
    );
  }
  GetCustomerById(id: any): Observable<any> {
    return this.http.get(apiUrl + "/api/Customers/GetCustomerById?id=" + id);
  }
  getlist(): Observable<any> {
    return this.http.get(apiUrl + "/api/Customers/GetAllCustomer");
  }
  getNomineeList(): Observable<any> {
    return this.http.get(apiUrl + "/api/CustomerAccount/GetNomineeInfo");
  }
  GetNomineeById(Id: any): Observable<any> {
    return this.http.get(
      apiUrl + "/api/CustomerAccount/GetNomineeInfoById" + Id
    );
  }

  getCustomerListBySearchAndPagination(
    page: number,
    pageSize: number,
    search: any
  ) {
    return this.http.get(
      apiUrl +
        "/api/Ledger/GetCustomerListWithSearchAndPagination?page=" +
        page +
        "&pageSize=" +
        pageSize +
        "&search=" +
        search
    );
  }

  // getCustomerListBySearchAndPagination(page: number, pageSize: number, search: any) {
  //   return this.http.get(apiUrl + '/api/Customers/GetCustomerListBySearchAndPagination?page=' + page + '&pageSize=' + pageSize + '&search=' + search);
  // }

  gettransactionList(): Observable<any> {
    return this.http.get(apiUrl + "/api/Customers/GetAllCashTransaction");
  }

  getFundTransferList(): Observable<any> {
    return this.http.get(apiUrl + "/api/Customers/GetFundTransferTransaction");
  }

  getCustomerByMobile(nid: string, mobile: string): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetCustomerByMobile?nid=" +
        nid +
        "&mobile=" +
        mobile
    );
  }

  allCashTransactionForAdminByAccNo(accountNo: string): Observable<any> {
    return this.http.get<any>(
      apiUrl + "/GetAllCashTransactionForAdminByAccNo?accountNo=" + accountNo
    );
  }

  ///
  getAllCashTransactionForAdminByAccNoAndDate(
    accountNo: string,
    startDate: Date,
    EndDate: Date
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/GetCashTransactionForAdminByAccNoDate?accountNo=" +
        accountNo +
        "&startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  getAllStatementViewByAccNoAndDate(
    accountNo: string,
    startDate: Date,
    EndDate: Date
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/AccountStatementViewByAccNoDate?accountNo=" +
        accountNo +
        "&startDate=" +
        startDate +
        "&EndDate=" +
        EndDate
    );
  }

  GetGenderList(): Observable<any> {
    return this.http.get(apiUrl + "/api/Gender/GetAllGenders");
  }
  GetDistrtList(): Observable<any> {
    return this.http.get(apiUrl + "/api/District/GetAllDistrict");
  }

  GetUpazilaList(): Observable<any> {
    return this.http.get(apiUrl + "/api/Upazila/GetAllUpazila");
  }

  GetUpazilaListByDistrictId(dId: number) {
    return this.http.get(
      apiUrl + "/api/Upazila/GetUpazilaByDistrictId?dId=" + dId
    );
  }

  save(model: any) {
    // console.log('url: ' + apiUrl + '/api/Customer/ekyc/insertcustomer');

    return this.http.post(apiUrl + "/api/Customers/ekyc/insertcustomer", model);
  }

  saveCustomerInfo(model: any) {
    sessionStorage.removeItem("token");

    return this.http.post<any>(
      apiUrl + "/api/Customers/ekyc/insertcustomer",
      model
    );
  }

  update(model: any) {
    return this.http.post(apiUrl + "/api/Customers/ekyc/insertcustomer", model);
  }

  delete(model: any) {
    return this.http.delete(apiUrl + "DeleteCustomer" + id);
  }

  DeleteCustomerById(Id: any) {
    return this.http.delete(
      apiUrl + "/api/Customers/DeleteCustomerById?Id=" + Id
    );
  }

  GetCustomerAccountList(userName: string): Observable<any> {
    return this.http.get<any>(
      apiUrl + "/GetCustermAccountListForCustomer/" + userName
    );
  }

  CustomerAccountListInfo(): Observable<any> {
    return this.http.get<any>(apiUrl + "/GetCustermAccountListForCustomer/");
  }

  GetContactByCustomerId(id: any): Observable<any> {
    return this.http.get(
      apiUrl + "/api/LoanApplication/GetContactByCustomerId?id=" + id
    );
  }
  GetCustomerDetails(id: any) {
    return this.http.get(apiUrl + "/api/Customers/GetCustomerDetails?id=" + id);
  }

  GetLoanDetails(id: any): Observable<any> {
    //alert(id);
    return this.http.get(
      apiUrl +
        "/api/CustomerAccount/GetCustomerAccountLoanByCustomerId?customerId=" +
        id
    );
  }



  GetDPSDetail(id: any): Observable<any> {
    return this.http.get(
      apiUrl +
        "/api/CustomerAccount/GetDPSDetailByCustomerId?customerId=" +
        id
    );
  }

  // GetCustomerById(id: any):Observable<any>{
  //   return this.http.get(apiUrl + '/api/Customers/GetCustomerById?id='+ id );
  // }

  GetCustomerLoanAccountList(Id: number): Observable<any> {
    return this.http.get<any>(
      apiUrl + "/GetCustomerLoanAccountListById?Id=" + Id
    );
  }
  //#region
  GetAllCustomerAccount(): Observable<any> {
    return this.http.get(apiUrl + "/api/Customers/GetAllCustomerAccountList");
  }
  //#endregion

  //region
  getCustomerInfoBySearch(
    customername: string,
    genderId: number,
    typeId: number,
    mobileNo: string,
    nidNo: string,
    sBirthDate: any,
    eBirthDate: any,
    sCreateDate: any,
    eCreateDate: any,
    sModifiedDate: any,
    eModifiedDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetCustomerInformationBySearch?customerName=" +
        customername +
        "&genderId=" +
        genderId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sBirthDate=" +
        sBirthDate +
        "&eBirthDate=" +
        eBirthDate +
        "&sCreateDate=" +
        sCreateDate +
        "&eCreateDate=" +
        eCreateDate +
        "&sModifiedDate=" +
        sModifiedDate +
        "&eModifiedDate=" +
        eModifiedDate
    );
  }

  getAllLoanRelatedInfoBySearch(
    customername: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    sCreateDate: any,
    eCreateDate: any,
    sDisburseDate: any,
    eDisburseDate: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetAllLoanRelatedInfoBySearch?customerName=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&sCreateDate=" +
        sCreateDate +
        "&eCreateDate=" +
        eCreateDate +
        "&sDisburseDate=" +
        sDisburseDate +
        "&eDisburseDate=" +
        eDisburseDate +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  getLoanRelatedAllInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sDisburseDate: any,
    eDisburseDate: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetLoanInfoBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sDisburseDate=" +
        sDisburseDate +
        "&eDisburseDate=" +
        eDisburseDate +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  // getAllGuarantorBySearch(guarantorId: number, sCreateDate:any,eCreateDate:any, sModifiedDate:any,eModifiedDate:any): Observable<any> {
  //   return this.http.get<any>(apiUrl + '/api/Customers/GetAllGuarantorById?Id=' + guarantorId  + '&sCreateDate=' + sCreateDate + '&eCreateDate=' + eCreateDate + '&sModifiedDate=' + sModifiedDate + '&eModifiedDate=' + eModifiedDate );
  // }

  getAllGuarantorBySearch(nidNo: string, mobile: string): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetGuarantorListByMobileAndNid/" +
        nidNo +
        "/" +
        mobile
    );
  }

  getAllNomineeBySearch(nidNo: string, birthNo: string): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetNomineeListBybirthNoAndNid/" +
        nidNo +
        "/" +
        birthNo
    );
  }

  getTransactionInfoByDateSearch(
    accountNo: any,
    fromDate: any,
    toDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/GetAllTransactionForAdminSearchByAccNoDate?accountNo=" +
        accountNo +
        "&startDate=" +
        fromDate +
        "&EndDate=" +
        toDate
    );
  }

  GetfilterList(): Observable<any> {
    return this.http.get(apiUrl + "/GetAllFilterType");
  }

  getBNPLStatementAllInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sDisburseDate: any,
    eDisburseDate: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/GetBNPLStatementBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sDisburseDate=" +
        sDisburseDate +
        "&eDisburseDate=" +
        eDisburseDate +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  getDpsStatementInfoBySearch(
    customername: string,
    customercode: string,
    accountNo: string,
    ProductId: number,
    typeId: number,
    mobileNo: any,
    nidNo: any,
    sApplyDate: any,
    eApplyDate: any
  ): Observable<any> {
    return this.http.get<any>(
      apiUrl +
        "/api/Customers/DpsStatementInfoBySearch?customerName=" +
        customername +
        "&customercode=" +
        customercode +
        "&accountNo=" +
        accountNo +
        "&ProductId=" +
        ProductId +
        "&typeId=" +
        typeId +
        "&mobileNo=" +
        mobileNo +
        "&nidNo=" +
        nidNo +
        "&sApplyDate=" +
        sApplyDate +
        "&eApplyDate=" +
        eApplyDate
    );
  }

  GetDpsInfoDetails(id: any): Observable<any> {
    //alert(id);
    return this.http.get(
      apiUrl +
        "/api/CustomerAccount/GetCustomerDPSByCustomerId?customerId=" +
        id
    );
  }

  //region
  getProfileUpdatePercentage(): Observable<any> {
    return this.http.get(
      apiUrl + "/api/Customers/GetCustomerProfileUpdatePercentage"
    );
  }

  //WhiteListSave
  saveWhiteListCustomerInfo(model: any) {
    return this.http.post<any>(
      apiUrl + "/api/CustomerAccount/SaveWhiteListCustomer",
      model
    );
  }
  ///////////
  DeleteCustomerAllInfoByCustId(Id: any) {
    return this.http.delete(
      apiUrl + "/api/Customers/DeleteSingleCustomerAllInfoByCustId?Id=" + Id
    );
  }
}
