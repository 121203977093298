import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardCustomService } from '../dashboard-custom.service';
import {  Location } from "@angular/common";


@Component({
  selector: "app-today-collection-list",
  templateUrl: "./today-collection-list.component.html",
  styleUrls: ["./today-collection-list.component.scss"],
})
export class TodayCollectionListComponent implements OnInit {
  dataList: any[] = [];

  constructor(
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.GetTodayCollectionList();
  }

  goBack() {
    this.location.back();
  }
  GetTodayCollectionList() {
    this._serviceDashboard.GetTodayCollectionList().subscribe((res: any[]) => {
      this.dataList = res;
    
      this.changeDetectorRef.detectChanges();
    });
  }
}
