<section>

    <div class="card col-12">

        <div class="card-header row">
            

            <div class="col-6">
                <h6 class="m-0 font-weight-bold text-primary">Today Receivable List</h6>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
                 
                    <i class="icon-arrow-left" style="font-size: 17px;"> </i>
                </button>
            </div>
            <hr>
        </div>
        <div class="card-body">
            <div class="table-container" style="max-height: 700px; overflow: auto;">
    
            <table id="example" class="table table-striped table-bordered scrollable-table"
                style="width:100%">
                <thead style="position: sticky; top:0; z-index: 1; background-color: #f8f9fa;">
                    <tr>
                        <th style="width:5%">SL No.</th>
                        <th style="width:10%">Account No</th>
                        <th style="width:10%">Customer Name</th>
                        <th style="width:10%">Customer Code</th>
                        <th style="width:10%">Disb. Date</th>
                        <th style="width:10%">Loan Amount</th>
                        <th style="width:10%">Ins. Size</th>
                        <th style="width:10%">Outstanding</th>
                        <th style="width:10%">Overdue</th>
                        <!-- <th>Last Coll. Date</th>
                        <th>Last Coll. Amount</th>
                        <th>Total Coll. Amount</th> -->
                                        
                    </tr>
                </thead>
    
                <tbody>
    
                    <tr *ngFor="let item of dataList; let i = index">
                        <td  style="width:5%">{{i+1}}.</td>
                        <td  style="width:10%">{{item.accounT_NO}}</td>
                        <td  style="width:10%">{{item.customeR_NAME_ENG}}</td>
                        <td  style="width:10%">{{item.customeR_CODE}}</td>
                        <td  style="width:10%">{{item.disbursementDate}}</td>
                        <td  style="width:10%">{{item.loanAmount}}</td>
                        <td  style="width:10%">{{item.installmentSize}}</td>
                        <td  style="width:10%">{{item.outStanding}}</td>
                        <td  style="width:10%">{{item.overDue}}</td>
                    </tr>
                </tbody>
            </table>
    
        </div>
    </div>
    </div>
</section>

