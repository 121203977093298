import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
   
  private customerUrl = environment.customerurl;
  private url = environment.baseUrl;

  constructor(private http: HttpClient) {
   }

  getCustomer(): Observable<any> {
    const url = `${this.customerUrl}`;
    return this.http.get<any>(url);
  }


  getCustomerByAccountNo(accountNo:string): Observable<any> {

    return this.http.get<any>(this.url + '/get-customer-by-accountNo/' + accountNo);
  }

  GetCustomerInfoByAccountNoById(accountId:number): Observable<any> {
   return this.http.get<any>(this.url + '/GetCustomerInfoByAccountNoById/' + accountId);
  }

  getTransactionLimitInfoAccountNo(accountNo: any): Observable<any> {
    return this.http.get<any>(this.url + '/GetTransactionLimitInfoAccountNo/' + accountNo);
  }

  getTransactionLimitByAccountId(accountId: any): Observable<any> {
    return this.http.get<any>(this.url + '/GetTransactionLimitAccountID/' + accountId);
  }

  getTransactionCountBalanceId(accountId: any,typeId:any): Observable<any> {
    return this.http.get<any>(this.url + '/GetTransactionCountBalanceByAccountId/' + accountId + '/' + typeId);
  }



  GetGLGenerateByCode(glCode:any): Observable<any>{
    //alert(0)
    return this.http.get(this.url + 'GetGLGenerateByCode?glCode='+ glCode);
   }

   GetCustomerGenerateCode(): Observable<any>{
    //alert(0)
    return this.http.get(this.url + 'GetCustomerGenerateCode');
   }
   //#region 
   getList(): Observable<any> {
    return this.http.get(this.url + 'GetClientinfo');
   }
   GetCustomerById(id: any){
    return this.http.get(this.url + 'GetCustomerById/'+ id );
  }
//    save(model: any){
//     return this.http.post(this.url +'PostCustomerInfo' , model);
//  }
 savebasic(model: any){
  return this.http.post(this.url +'/api/LoanApplication/PostBasicCustomerInfo' , model);
}
 GetLeadGenerationByLeadNoforBasic(leadId:number,customersId:number,loanProductsId:number): Observable<any> {
  //return this.http.get(this.url + '/api/LoanApplication/GetLeadGenerationByLeadNo?leadId=' + leadId);
  return this.http.get(this.url + '/api/LoanApplication/GetLeadGenerationByLeadNoForBasic?leadId=' + leadId + '&customersId=' + customersId + '&loanProductsId=' + loanProductsId);
 }
 GetLeadGenerationByLeadNo(leadId:number): Observable<any> {
  return this.http.get(this.url + '/api/LoanApplication/GetLeadGenerationByLeadNo?leadId=' + leadId);
 
 }

 getlist(): Observable<any> {
  return this.http.get(this.url + '/api/Gender/GetAllGenders');
}

   //#endregion

   //#region 
   getContactList(): Observable<any> {
    return this.http.get(this.url + '/api/LoanApplication/GetAllContact');
   }
   Contactsave(model: any){
     return this.http.post(this.url +'/api/LoanApplication/PostContactInfo' , model);
  }
  GetContactByCustomerId(id: any){
    return this.http.get(this.url + '/api/LoanApplication/GetContactByCustomerId/'+ id );
  }
  GetLeadGenerationByLeadNoforContact(customersId:number): Observable<any> {
    return this.http.get(this.url + '/api/LoanApplication/GetLeadGenerationByLeadNoforContact?customersId=' + customersId );
   }
   GetLeadGenerationByLeadNoforCustomer(customersId:number): Observable<any> {
    return this.http.get(this.url + '/api/LoanApplication/GetLeadGenerationByLeadNoforCustomer?customersId=' + customersId );
   }
   //#endregion

   //#region 
   getLeadDocumentTypeList(): Observable<any> {
    return this.http.get(this.url + '/api/LoanApplication/GetDocumentType');
   }
   GetCustomerDetailsForEdit(){
    return this.http.get(this.url + '/api/Customers/GetCustomerDetailsForEdit' );
  }

  GetCustomerDetailsForAdminEdit(id:number){
    return this.http.get(this.url + '/api/Customers/GetCustomerDetailsForEditForAdmin?customerId=' + id );
  }

  GetCustomerAccountNoForChange(id: any): Observable<any> {
     
    return this.http.get(this.url + '/api/Customers/GetCustomerAccountNoForChange/'+ id );
  }

  GetCustomerAccountNo(): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetCustomerAccountNo');
  }
  GetCustomerDPSAccountNo(): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetCustomerDPSAccountNo');
  }

  GetMobileBanking(accountType:string):Observable<any>{
    return this.http.get(this.url + '/GetMobileBankingInfo/' + accountType);
  }

  GetDPSPaymentTypes(): Observable<any> {
    return this.http.get(this.url + '/api/GetDPSPaymentTypes');
  }


  // GetMobileBankingById(id:number):Observable<any>{
  //   return this.http.get(this.url + '/GetMobileBankingByMobileNo/' + id);
  // }


  GetMobileBankingById(id: number, accountType: string): Observable<any> {
    return this.http.get(`${this.url}/GetMobileBankingByMobileNo/${id}/${accountType}`);
  }
  

  SaveMobileBanking(model: any) {
    return this.http.post(this.url + '/api/CashTransaction/SaveMobileBankingInfo', model);
  }

  getAutocompleteSuggestions(searchTerm: string): Observable<any[]> {
    return this.http.get<any[]>(this.url +'/api/CashTransaction/GetMobileNumberAutoComplete', { params: { searchTerm } });
}

  GetCreditCustomerNo():Observable<any>{
    return this.http.get(this.url + '/api/CustomerAccount/GetCreditCustomerAccount');
  }
  GetBankAccountNo(): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetBankBeneficiaryAccountNo');
  }

  GetBankBeneficiaryAccountByUser(): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetBankBeneficiaryAccountByUser');
  }

  
  GetBankBeneficiaryAccountNoById(accountId:number): Observable<any> {
    return this.http.get<any>(this.url + '/GetBankBeneficiaryAccountNoById/' + accountId);
   }
 
     //#endregion
  getProfileUpdatePercentage(): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetCustomerProfileUpdatePercentage');
  }

  getProfileUpdatePercentageByAdmin(id : number): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetCustomerProfileUpdatePercentageByAdmin?customerId=' + id);
  }


  GetMobileRechargeInfo():Observable<any>{
     
    return this.http.get(this.url + '/GetMobileRechargeInfo/');
  }
  
  GetMobileRechargeByMobileNo(mobileNo:string):Observable<any>{
    
    return this.http.get(this.url + '/GetMobileRechargeByMobileNo/' + mobileNo);
  }

  GetMobileOperator():Observable<any>{
     
    return this.http.get(this.url + '/GetMobileOperatorInfoAll/');
  }
  GetMobileOperatorsList(operatorType:string):Observable<any>{
     
    return this.http.get(this.url + '/GetMobileOperatorsList/' + operatorType);
  }

  getCustomerByMobile(nid: string, mobile: string): Observable<any> {
    return this.http.get<any>(this.url + '/api/Customers/GetCustomerByMobile?nid=' + nid + '&mobile=' + mobile);
  }


  ////WhiteList

  getlistWhiteListedCustomer(): Observable<any> {
    return this.http.get(this.url + '/api/CustomerAccount/GetAllWhiteListedCustomer');
  }

  UploadWhiteListCustomer(model: any) {
   
    return this.http.post(this.url + '/api/CustomerAccount/WhiteListCustomerUpload', model);
  }
  GetDPSCustomerInfoByAccountNoById(accountId:number): Observable<any> {
    return this.http.get<any>(this.url + '/GetCustomerDPSAccountsByCustomerAccountId/' + accountId);
   }
   GetDPSBalanceByAccountId(accountId:number): Observable<any> {
    return this.http.get<any>(this.url + '/GetDPSBalanceByAccountId/' + accountId);
   }
   GetDPSOSByAccountId(accountId:number): Observable<any> {
    return this.http.get<any>(this.url + '/GetDPSOSByAccountId/' + accountId);
   }


   SaveDPSCollection(model: any) {
    return this.http.post(this.url + '/api/CashTransaction/SaveDPSCollection', model);
  }



  GetCustomerDPSInfoForEncashment(accountId:number): Observable<any> {
    return this.http.get<any>(this.url + '/GetCustomerDPSInfoForEncashment/' + accountId);
  }


  SaveDPSEncashment(model: any) {
    return this.http.post(this.url + '/api/CashTransaction/SaveDPSEncashment', model);
  }


  
  GetDPSOS(accountId:number): Observable<any> {
    return this.http.get<any>(this.url + '/GetDPSOS/' + accountId);
   }


   
  GetCustomerDPSAccountNoWithoudOD(): Observable<any> {
    return this.http.get(this.url + '/api/Customers/GetCustomerDPSAccountNoForEncashment');
  }

  //Terms And Condition

 saveTerms(model: any): Observable<any> {
alert(1)
alert(this.url)
  return this.http.post(this.url +'/api/CustomerAccount/SaveTermsAndConditions' , model);
}

}
