import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DashboardCustomService } from 'app/dashboard/dashboard-custom.service';
import { FdrdetailsService } from 'app/fdr-account/fdrdetails.service';
import { PdfService } from 'app/pdf-reporting/pdf.service';
import { SharedService } from 'app/services/shared.service';
import { DatePipe, Location } from "@angular/common";
import swal from "sweetalert";
@Component({
  selector: "app-dormant-account",
  templateUrl: "./dormant-account.component.html",
  styleUrls: ["./dormant-account.component.scss"],
})
export class DormantAccountComponent implements OnInit {
  dateFormat: string = "";

  DormantAccountList: any[] = [];

  ////////////////////

  dpsEncashmentList: any[] = [];
  customername: string = "";
  customercode: string = "";
  mobileNumber: string = "";
  accountNo: string = "";
  accountName: string = "";
  nidNo: string = "";
  typeId: number = 0;
  customerList: any[] = [];
  dpsList: any[] = [];
  transactionList: any[] = [];

  // ---------------------------------------
  dormantDate: any;
  sApplyDate: any;
  eApplyDate: any;
  //--------------------------------------------

  DPSInfoTable: boolean = true;
  showaccountNoField: boolean = false;
  showencashmentDateField: boolean = false;
  noDataAvailable: boolean = false;

  selectedFilterType: number = 0;

  ProductId: number;
  productList: any[] = [];

  constructor(
    private pdfService: PdfService,
    private _fdrService: FdrdetailsService,
    private _serviceDashboard: DashboardCustomService,
    private changeDetect: ChangeDetectorRef,
    private location: Location,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    var thumb = JSON.parse(localStorage.getItem("thumb"));
    this.dateFormat = thumb.dateFormat;
    this.GetAllDormantAccountList();
  }

  GetAllDormantAccountList() {
    this._serviceDashboard.GetAlldormantAccount().subscribe((res) => {
      if (res.length > 0) {
        this.DormantAccountList = res;

        this.changeDetect.detectChanges();
      }
    });
  }

  Approve(id: number) {
    this._serviceDashboard.ApproveDormantAccount(id).subscribe((res) => {
      if (res != null) {
        this.shared.ShowAlert("Success", "Approved Successful", "success");
        location.reload();
      }
    });
  }
  goBack() {
    this.location.back();
  }

  onFilterTypeChange(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showaccountNoField = true;
        break;
      case "2":
        this.showencashmentDateField = true;
        break;
    }
  }

  loadDormantRelatedData() {
    const defaultDate = "0001-01-01";
    this._serviceDashboard
      .GetAlldormantAccountBySearch(
        this.accountNo,
        this.accountName,
        this.dormantDate ?? defaultDate
      )
      .subscribe((res) => {
        if (res != null) {
          this.DormantAccountList = res;
          this.noDataAvailable = this.DormantAccountList.length === 0;
          this.DPSInfoTable = true;
        } else {
          swal("Warning", "Invalid Account No", "warning");
          this.noDataAvailable = true;
        }
      });
  }
  SearchByParameter() {
    this.typeId;
    this.DPSInfoTable = true;
  }

  deleteFilterTypeFields(type: number): void {
    this.selectedFilterType = type;
    switch (this.selectedFilterType.toString()) {
      case "1":
        this.showaccountNoField = false;
        this.accountNo = "";
        break;
      case "2":
        this.showencashmentDateField = false;
        this.eApplyDate = "0001-01-01";
        break;
    }
  }
}
