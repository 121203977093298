import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PaymentRequestVm } from './models/payment-request-vm';
import { TransactionLog } from './models/transaction-log';
import { SmsEmailFormat } from './models/sms-email-format';
import { ThumbRule } from './models/thumb-rule';
import { UserInfos } from './models/UserInfos';

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private apiUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  checkoutPaymentNagad(paymentRequest: PaymentRequestVm): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/api/Payment/PaymentNagad`,
      paymentRequest
    );
  }

  checkoutPaymentShurjo(paymentRequest: PaymentRequestVm): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/api/Payment/CheckoutPayment`,
      paymentRequest
    );
  }

  checkoutPayment(paymentRequest: PaymentRequestVm): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/api/Payment/CheckoutPaymentSSL`,
      paymentRequest
    );
  }

  getTransactionLogByTransactionId(
    transId: string
  ): Observable<TransactionLog> {
    const apiUrl = `${this.apiUrl}/api/Payment/TransactionLogByTransactionId/${transId}`;
    return this.http.get<TransactionLog>(apiUrl);
  }

  getTransactionLogs(): Observable<TransactionLog[]> {
    const apiUrl = `${this.apiUrl}/api/Payment/GetAllPaymentLogs`;
    return this.http.get<TransactionLog[]>(apiUrl);
  }

  getTransactionLogsByDate(
    startDate: any,
    endDate: any,
    customername: any,
    accountNo: any,
    mobileNo: any
  ): Observable<any> {
    return this.http.get(
      environment.baseUrl +
        "/api/Payment/GetAllPaymentLogsByDate?startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&customername=" +
        customername +
        "&accountNo=" +
        accountNo +
        "&mobileNo=" +
        mobileNo
    );
  }

  getTransactionLogsById(Id: any): Observable<any> {
    return this.http.get(
      environment.baseUrl + "/api/Payment/GetPaymentDetailsById?id=" + Id
    );
  }

  grantToken(): Observable<any> {
    const grantTokenUrl = `${this.apiUrl}/api/GrantToken`;
    return this.http.post<any>(grantTokenUrl, null);
  }

  createPayment(
    senderBkashNo: string,
    amount: string,
    token: string
  ): Observable<any> {
    const createPaymentUrl = `${this.apiUrl}/api/CreatePayment`;
    const body = JSON.stringify({
      senderBkashNo: senderBkashNo,
      amount: amount,
      token: token,
    });
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post<any>(createPaymentUrl, body, { headers });
  }

  grantTokenAndCreatePayment(
    senderBkashNo: string,
    amount: string
  ): Observable<any> {
    return new Observable((observer) => {
      // First, call grantToken method
      this.grantToken().subscribe(
        (grantTokenResponse) => {
          // Extract token from grantTokenResponse
          const token = grantTokenResponse.id_token;

          // Call createPayment method with extracted token
          this.createPayment(senderBkashNo, amount, token).subscribe(
            (createPaymentResponse) => {
              // If createPayment succeeds, emit the combined response
              observer.next({
                grantTokenResponse: grantTokenResponse,
                createPaymentResponse: createPaymentResponse,
              });
              observer.complete();
            },
            (error) => {
              // If createPayment fails, emit the error
              observer.error(error);
            }
          );
        },
        (error) => {
          // If grantToken fails, emit the error
          observer.error(error);
        }
      );
    });
  }

  getPaymentInfo(paymentId: string): Observable<any> {
    const url = `${this.apiUrl}/api/GetPaymentInfoByPaymentId?paymentId=${paymentId}`;
    return this.http.get<any>(url);
  }

  executePayment(paymentId: string): Observable<any> {
    const url = `${this.apiUrl}/api/ExecutePayment?paymentId=${paymentId}`;
    return this.http.get<any>(url);
  }

  //Nagad
  nagadInitialize(senderBkashNo: string, amount: string): Observable<any> {
    return new Observable((observer) => {
      // First, call grantToken method
      this.initializeNagadPayment().subscribe(
        (res) => {
          // Extract token from grantTokenResponse

        
        },
        (error) => {
          // If grantToken fails, emit the error
          observer.error(error);
        }
      );
    });
  }

  initializeNagadPayment(): Observable<any> {
    const grantTokenUrl = `${this.apiUrl}/api/initializeNagadPayment`;
    return this.http.post<any>(grantTokenUrl, null);
  }

  getFormats(): Observable<SmsEmailFormat[]> {
    return this.http.get<SmsEmailFormat[]>(
      `${this.apiUrl}/api/GetDPSSmsEmailFormats`
    );
  }

  addFormat(format: SmsEmailFormat): Observable<SmsEmailFormat> {
    return this.http.post<SmsEmailFormat>(
      `${this.apiUrl}/api/saveformats`,
      format
    );
  }

  updateFormat(format: SmsEmailFormat): Observable<SmsEmailFormat> {
    return this.http.put<SmsEmailFormat>(
      `${this.apiUrl}/api/formats/${format.id}`,
      format
    );
  }

  deleteFormat(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/api/deleteFormat/${id}`);
  }

  GetThumbRule(): Observable<ThumbRule> {
    return this.http.get<ThumbRule>(`${this.apiUrl}/api/GetThumRule`);
  }

  GetUserInfos(): Observable<UserInfos[]> {
    return this.http.get<UserInfos[]>(`${this.apiUrl}/api/GetUserInfos`);
  }

  SaveThumbRule(thumb: ThumbRule): Observable<ThumbRule> {
    return this.http.post<ThumbRule>(`${this.apiUrl}/api/SaveThumbRule`, thumb);
  }
}
