import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { DashboardCustomService } from "../dashboard-custom.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-advance-received-list",
  templateUrl: "./advance-received-list.component.html",
  styleUrls: ["./advance-received-list.component.scss"],
})
export class AdvanceReceivedListComponent implements OnInit {
  dataList: any[] = [];

  constructor(
    private _serviceDashboard: DashboardCustomService,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.GetAdvanceCollectionList();
  }

  goBack() {
    this.location.back();
  }
  GetAdvanceCollectionList() {
    this._serviceDashboard.AdvanceCollectionList().subscribe((res: any[]) => {
      this.dataList = res;
      this.changeDetectorRef.detectChanges();
    });
  }
}
