import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BatchDetail } from 'app/models/batch-detail';
import { BatchService } from 'app/services/batch.service';

@Component({
  selector: 'app-batch-detail-add',
  templateUrl: './batch-detail-add.component.html',
  styleUrls: ['./batch-detail-add.component.scss']
})
export class BatchDetailAddComponent implements OnInit {
  batchData: BatchDetail = {
    id: 0,
    name: '',
    apiName: '',
    cobJobProcessType: '',
    processTime: "",
    isAutoProcess: 0,
    readyState: false,
    batchMasterId: 0,
    cobJobInfoId: 1
  };

  cobJobInfo:any;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private batchService: BatchService, private changeDetectorRef: ChangeDetectorRef,) { }
  
  @Input() masterId: any;

  myForm: FormGroup;

  ngOnInit(): void {
   
    this.batchData.batchMasterId = this.masterId;
    this.changeDetectorRef.detectChanges();

    this.getAllJobInfo();

  }

  closeModal(): void {
    this.activeModal.close('Modal closed');
  }


  InsertBatchDetail(){
    this.batchData.batchMasterId = this.masterId;
   
    this.batchService.InsertBatchDetail(this.batchData).subscribe(
      response => {
        if(response > 0){
          alert('Inserted Successfully!');
          this.closeModal();
        }
      },
      error => {
        alert('Insert Failed!')
      }
    );
  }

  getAllJobInfo() {
    this.batchService.getJobInfo().subscribe(
      (data) => {
        this.cobJobInfo = data;
      },
      error => {
        console.error('Error fetching batch list:', error);
      }
    );
  }


  ChangeJobInfo(id: number) {
    const selectedJob = this.cobJobInfo.find(item => item.id == id);
    
    if (selectedJob) {
        this.batchData.name = selectedJob.jobName;
        this.batchData.apiName = selectedJob.api;
        this.changeDetectorRef.detectChanges();
    }
}

  
}
