import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DpsCreateComponent } from './dps-create/dps-create.component';
import { DpsListComponent } from './dps-list/dps-list.component';
import { DpsCollectionComponent } from './dps-collection/dps-collection.component';
import { DpsEncashmentComponent } from './dps-encashment/dps-encashment.component';
import { DpsEncashmentListComponent } from './dps-encashment-list/dps-encashment-list.component';
import { DpsImmatureEncashmentComponent } from './dps-immature-encashment/dps-immature-encashment.component';
import { TodayEncashmentListComponent } from './today-encashment-list/today-encashment-list.component';
import { TodayDpsListComponent } from './today-dps-list/today-dps-list.component';
import { TodayCloseAccountsComponent } from './today-close-accounts/today-close-accounts.component';
import { TodayMissedPaymentComponent } from './today-missed-payment/today-missed-payment.component';
import { EncashmentAlertComponent } from './encashment-alert/encashment-alert.component';
import { DormantAccountComponent } from './dormant-account/dormant-account.component';
import { DocumentInfoListComponent } from './document-info-list/document-info-list.component';
import { DpsMISReportComponent } from './dps-mis-report/dps-mis-report.component';
import { UpcomingMaturedReportComponent } from './upcoming-matured-report/upcoming-matured-report.component';
import { PrematuredEncashmentReportComponent } from './prematured-encashment-report/prematured-encashment-report.component';
import { InterestReportComponent } from './interest-report/interest-report.component';
import { EncashmentReportComponent } from './encashment-report/encashment-report.component';
import { DpsLoanReportComponent } from './dps-loan-report/dps-loan-report.component';
import { TodayPayableDpsComponent } from './today-payable-dps/today-payable-dps.component';
import { DpsTodayReceivcbleComponent } from './dps-today-receivcble/dps-today-receivcble.component';
import { TotalPayableDpsComponent } from './total-payable-dps/total-payable-dps.component';

const routes: Routes = [
  {
    path: "dps-create",
    component: DpsCreateComponent,
    data: {
      title: "dps-create",
    },
  },

  {
    path: "dps-list",
    component: DpsListComponent,
    data: {
      title: "dps-list",
    },
  },

  {
    path: "today-dps-list",
    component: TodayDpsListComponent,
    data: {
      title: "today-dps-list",
    },
  },
  {
    path: "close-account-list",
    component: TodayCloseAccountsComponent,
    data: {
      title: "close-account-list",
    },
  },
  {
    path: "missed-payment-list",
    component: TodayMissedPaymentComponent,
    data: {
      title: "missed-payment-list",
    },
  },
  {
    path: "dps-collection",
    component: DpsCollectionComponent,
    data: {
      title: "dps-collection",
    },
  },
  {
    path: "dps-encashment",
    component: DpsEncashmentComponent,
    data: {
      title: "dps-encashment",
    },
  },
  {
    path: "dps-encashment-list",
    component: DpsEncashmentListComponent,
    data: {
      title: "dps-encashment-list",
    },
  },
  {
    path: "dps-immature-encashment",
    component: DpsImmatureEncashmentComponent,
    data: {
      title: "dps-immature-encashment",
    },
  },
  {
    path: "today-encashment-list",
    component: TodayEncashmentListComponent,
    data: {
      title: "today-encashment-list",
    },
  },
  {
    path: "encashment-alert",
    component: EncashmentAlertComponent,
    data: {
      title: "encashment-alert",
    },
  },
  {
    path: "dps-MIS-report",
    component: DpsMISReportComponent,
    data: {
      title: "dps-MIS-report",
    },
  },
  {
    path: "document-info-list",
    component: DocumentInfoListComponent,
    data: {
      title: "document-info-list",
    },
  },
  {
    path: "dormant-account",
    component: DormantAccountComponent,
    data: {
      title: "dormant-account",
    },
  },
  {
    path: "upcoming-matured-report",
    component: UpcomingMaturedReportComponent,
    data: {
      title: "upcoming-matured-report",
    },
  },
  {
    path: "prematured-encashment-report",
    component: PrematuredEncashmentReportComponent,
    data: {
      title: "prematured-encashment-report",
    },
  },
  {
    path: "interest-report",
    component: InterestReportComponent,
    data: {
      title: "interest-report",
    },
  },
  {
    path: "encashment-report",
    component: EncashmentReportComponent,
    data: {
      title: "encashment-report",
    },
  },
  {
    path: "dps-loan-report",
    component: DpsLoanReportComponent,
    data: {
      title: "dps-loan-report",
    },
  },

  {
    path: "today-payable-dps",
    component: TodayPayableDpsComponent,
    data: {
      title: "today-payable-dps",
    },
  },

  {
    path: "dps-today-receivcble",
    component: DpsTodayReceivcbleComponent,
    data: {
      title: "dps-today-receivcble",
    },
  },

  {
    path: "total-payable-dps",
    component: TotalPayableDpsComponent,
    data: {
      title: "total-payable-dps",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DpsAccountRoutingModule { }
