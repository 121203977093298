import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, NgForm,FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { UserRoleAssign } from '../../user-model/user-role-assign';
import { UserRoleAssignService } from '../../user-service/user-role-assign.service';
import { DatePipe, Location } from "@angular/common";

@Component({
  selector: 'app-user-role-assign',
  templateUrl: './user-role-assign.component.html',
  styleUrls: ['./user-role-assign.component.scss']
})
export class UserRoleAssignComponent implements OnInit {

  isEdit: boolean = false;
  countryForm: FormGroup;
  list:UserRoleAssign[]=[];
  model = new UserRoleAssign();

  constructor(
    private _service:UserRoleAssignService,
    private changeDetectorRef: ChangeDetectorRef,
    private router:Router,
    private formBuilder: FormBuilder,
    private location: Location
  )
   {
   
   this.GetAllUserRole();
   this.loadRoleList();
   this.loadUserList();
    this.buildForm();
    }

  ngOnInit(): void {
    
    this.GetAllUserRole();
    this.loadRoleList();
    this.loadUserList();
  }

 

  GetAllUserRole(){
    this._service.GetAllUserRole().subscribe(res=>
    {
     this.list=res;
    })
  }

  buildForm() {
    this.countryForm = this.formBuilder.group({
      COUNTRY_CODE: ['', Validators.required],
      COUNTRY_Name: ['', Validators.required]
      
    });
  }

  RoleList: Observable<any[]>;
  loadRoleList() {
    this._service.GetAllRole().subscribe(res => {

      this.RoleList = of(res);
    });
  } 

  UserList: Observable<any[]>;
  loadUserList() {
    this._service.GetAllUser().subscribe(res => {

      this.UserList = of(res);
    });
  }


  onsubmit() {

  
    this._service.save(this.model).subscribe(res => {
     
      if(res!=''){
        alert('Save successfully');
        this.GetAllUserRole();
      }


    })
    this.changeDetectorRef.detectChanges();
  }

  goBack() {
    this.location.back();
  }
}
