<section id="navs">
    <div class="card">
      <div class="row card-header  d-flex flex-row align-items-center">
        <div class="col-6">
          <h3 class="card-title" style="color: black;">DPS ACCOUNT</h3>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">
            <i class="icon-arrow-left" style="font-size: 17px;"> </i>
          </button>
        </div>
      </div>

      <hr>
      <div class="card-body">
        <form [formGroup]="frmGroup">
          <ul ngbNav #basicnav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event,0)" class="nav-tabs mr-3">

            <li [ngbNavItem]="1">
              <a ngbNavLink>Account Info</a>
              <ng-template ngbNavContent>

                <div class="col-md-12 row">
                  <div class="col-md-6 form-group row">
                    <label for="" class="col-sm-4 col-form-label">Account Title <span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="AccountTilte"
                        placeholder="Account Title ....">
                    </div>
                  </div>

                  <div class="col-md-6 form-group row">
                    <label for="" class="col-sm-4 col-form-label">NID No<span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="nidNo" placeholder="NID No....">
                    </div>
                  </div>
                </div>
                
                <div class="col-md-12 row">
                  <div class="col-md-6  form-group row">
                    <label for="" class="col-sm-4 col-form-label">Father Name</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="fatherName" placeholder="Father Name....">
                    </div>
                  </div>

                  <div class="col-md-6 form-group row">
                    <label for="" class="col-sm-4 col-form-label">Mother Name</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="motherName" placeholder="Mother Name....">
                    </div>
                  </div>
                </div>

               
                  <div class="col-md-12 row">
                    <div class="col-md-6 form-group row">
                      <label for="" class="col-sm-4 col-form-label">Mobile No</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="mobileNo" placeholder="Mobile No....">
                      </div>
                    </div>


                    <div class="col-md-6 form-group row">
                      <label for="" class="col-sm-4 col-form-label">Email</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="email" placeholder="Email....">
                      </div>
                    </div>
                  </div>

               

                  <div class="col-md-12 row ">
                    <div class="col-md-6 form-group row">
                      <label for="" class="col-sm-4 col-form-label">e-TinNo</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="eTinNo" placeholder="e-TinNo....">
                      </div>
                    </div>

                    <div class="col-md-6 form-group row">
                      <label for="" class="col-sm-4 col-form-label">Profession</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" formControlName="Profession" placeholder="Profession....">
                      </div>
                    </div>
                  </div>

               
                <!-- <div class="col-md-12" style="display: none;">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Account Title (Bangla) <span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="AccountTilteBn"
                        placeholder="Account Title Bn....">
                    </div>
                  </div>
                </div> -->
                <div class="col-md-12 row">
                  <div class="col-md-6 form-group row">
                    <label for="productCode" class="col-sm-4 col-form-label">Product <span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <select class="form-control"  (change)="GetAccountNo($event.target.value)"  formControlName="productCode" placeholder="">
                        <option value="">Choose Product</option>
                        <option *ngFor="let item of productlist" [value]="item.productCode">{{item.productCode}}-{{item.tenure}}</option>
                      </select>
                    </div>
                  </div>

                    <div class="col-md-6 form-group row">
                      <label for="dateOfBirth" class="col-sm-4 col-form-label">Date of Birth</label>
                      <div class="col-sm-8">
                        <input type="date"  class="form-control" formControlName="dateOfBirth" >
                      </div>
                    </div>

                </div>
                
                <div class="col-md-12 d-none">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">GL Code</label>
                    <div class="col-sm-8">


                      <input type="text" readonly class="form-control" formControlName="ledgerRelationName"
                        placeholder="GL">
                      <input type="hidden" readonly class="form-control" name="ledgerRelationId"
                        formControlName="ledgerRelationId">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-none">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Account No</label>
                    <div class="col-sm-8">
                      <input type="text" readonly class="form-control" formControlName="customerAccountNo"
                        placeholder="Account No">

                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-none">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Currency</label>
                    <div class="col-sm-8">
                      <input type="text" readonly class="form-control" formControlName="accountCurrency" placeholder="Currency ....">
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-none">
                  <div class="form-group row">

                    <label for="interestRate" class="col-sm-4 col-form-label">Interest Rate</label>

                    <div class="col-sm-8">
                      <input class="form-control" formControlName="interestRate" style="text-align: right;" readonly>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 d-none">
                  <div class="form-group row">
                    <label for="name" class="col-sm-4 col-form-label">Tenure <span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <select class="form-control" formControlName="dpsTenureId" placeholder="">
                        <option value="">Select</option>
                        <!-- <option *ngFor="let item of tenurelist" [value]="item.id">{{item.monthName}}</option> -->
                          <ng-container *ngFor="let item of tenurelist">
                             <option  [value]="item.id">{{item.monthName}}</option>
                          </ng-container>

                          </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 row">
                  <div class="col-md-6 form-group row">
                    <label for="OpeningBalance" class="col-sm-4 col-form-label">DPS Amount <span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <input type="text" style="text-align: right;" class="form-control" (keyup)="getLoanAmountValue($event)"  formControlName="OpeningBalance"
                      (keyup)="GetMaturityAmount()">
                        <div *ngIf="!isValidAmount" style="color:red;">{{msgLoanAmt}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 form-group row">
                    <label for="" class="col-sm-4 col-form-label">Maturity Amount </label>
                    <div class="col-sm-8">
                      <input type="text" style="text-align: right;" class="form-control" readonly value="{{maturityAmnt}}">
                      <span style="font-size: 10px;"><strong>Maturity Date: {{maturityDate | date}}</strong></span>
                    </div>
                  </div>
                </div>

              
                <!-- <div class="col-md-12">
                  <div class="form-group row">
                    <label for="" class="col-sm-4 col-form-label">Monthly Income<span class="redColor"> *</span></label>
                    <div class="col-sm-8">
                      <input type="text" style="text-align: right;" class="form-control" formControlName="monthlyIncome">
                    </div>
                  </div>


                </div> -->
                <div class="col-md-12 row">
                  <div class="col-md-6 form-group row">
                    <label for="" class="col-sm-4 col-form-label">Purpose</label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control" formControlName="accountPurpose" placeholder="Purpose ....">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group row pull-right">
                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,3)">Next</button>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>Nominee Info</a>
              <ng-template ngbNavContent>
                <div class="col-md-12">
                  <div class="form-group row">

                    <form [formGroup]="nomineeGroup">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Name <span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" formControlName="nomineeName" placeholder="Nominee Name....">
                              <input type="hidden" formControlName="nomineeNameSl" value="0">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's DOB <span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <input type="date" class="form-control" formControlName="nomineeDOB" placeholder="Nominee Date Of Birth....">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Present Address <span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" formControlName="nomineePresentAddress" placeholder="Nominee Present Address....">
                            </div>
                          </div>

                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">NID Picture <span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <input type="file" accept="image/*" id="image" (change)="selectNidBackPic($event)"formControlName="photo"
                                          style="display: none;" #fileInputBack />

                                      <div style="height: 125px; width: 150px; cursor: pointer;"
                                          (click)="fileInputBack.click()">
                                          <img src="{{ backPreview ? backPreview : 'assets/img/gallery/upload_image.png' }}"
                                              id="image" style="width: 100%; height: 100%;" class="proPreview"
                                              title="Click here to upload photo" />
                                      </div>
                                    </div>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Relation<span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <select class="form-control" formControlName="relationId" (change)="getRelation($event.target.value)">



                                  <option value="">Select</option>
                                  <option *ngFor="let item of relationList" [value]="item.id">{{item.name}}</option>
                                 </select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Share <span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <input type="number" class="form-control" min="1" max="100" formControlName="nomineeShare" (keyup)="ValidateShare($event)" value="{{totalShare}}" placeholder="Nominee's Share....">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="" class="col-sm-4 col-form-label">Nominee's Identifier <span style="color: red;"> *</span></label>
                            <div class="col-sm-8">
                              <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Nominee's NID</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" (input)="onNumberInput($event)" formControlName="nomineeNID" placeholder="Nominee NID....">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Nominee's Passport</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" formControlName="nomineePassport" placeholder="Nominee Passport.....">
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="" class="col-sm-4 col-form-label">Nominee's Birth Identity No</label>
                                <div class="col-sm-8">
                                  <input type="text" class="form-control" formControlName="nomineeBirthIdentity" placeholder="Nominee Birth Identity No.....">
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                      <button type="button" class="btn btn-success" (click)="addNominee()">Add</button>


                    </form>

                  </div>
                  <div class="form-group row">
                    <label for="" class="col-sm-2 col-form-label"></label>
                    <div class="col-sm-10">
                      <table class="table table-bordered">
                        <tbody>
                          <tr *ngFor="let item of nolineeList ;let i = index">
                            <td style="width: 10%;">{{i+1}}</td>
                            <td>
                              <ul>
                                <li><span style="font-weight: bold;">Nominee Name </span>: {{item.nomineeName}}</li>

                                <li><span style="font-weight: bold;">Nominee DOB</span> : {{item.nomineeDOB}}</li>
                                <li><span style="font-weight: bold;">Present Address </span> : {{item.nomineePresentAddress}}</li>

                                <li><span style="font-weight: bold;">Occupation</span>  : {{item.nomineeOccupation}}</li>
                                <li><span style="font-weight: bold;">NID</span>  : {{item.nomineeNID}}</li>
                                <li><span style="font-weight: bold;">Passport</span>  : {{item.nomineePassport}}</li>
                                <li><span style="font-weight: bold;">Birth Identity No </span> : {{item.nomineeBirthIdentity}}</li>

                                <li><span style="font-weight: bold;">Share</span>  : {{item.nomineeShare}}</li>
                                <li>
                                  <span style="font-weight: bold;">Relation</span>  : {{ nomineeGroup.value.relationName }}
                                  <span style="display: none;" >  {{item.relationId}} </span>


                                </li>
                                <li>
                                  <span style="font-weight: bold;">NID Picture</span>:

                                  <img class="proPreview"  src="{{backPreview}}" alt="proPreview" height="35" width="35" />
                                </li>
                              </ul>
                            </td>
                            <td>
                              <a class="btn btn-sm btn-info mr-2" (click)="editNominee(item)"><i class="fa fa-edit"></i></a>
                              <a class="btn btn-sm btn-danger" (click)="deleteNominee(item)"><i class="fa fa-trash"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <!-- <div class="form-group row pull-right">
                    <button class="btn btn-sm btn-info" (click)="onNavChange($event,4)">Next</button>
                  </div> -->
                </div>
                <button  [disabled]="!frmGroup.valid" data-toggle="tooltip" title="Save" type="submit" (click)="onsubmit()" value="Submit" class="btn btn-success" style="float:right; margin-top:5px;">
                    <span>{{ isEdit ? 'Update' : 'Save' }}</span>
                  </button>
                  <button type="button" data-toggle="tooltip" title="Refresh" (click)="refresh()" class="btn btn-secondary" style="float:right; margin-top:5px;margin-right:5px;margin-left: 8px;">Refresh</button>
              </ng-template>
            </li>

          </ul>
          <div [ngbNavOutlet]="basicnav" class="mt-2"></div>

        </form>
      </div>
    </div>
  </section>
