import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { CashPosting } from "../model/cash-posting";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CashPostingService } from "../service/cash-posting.service";
import { LedgerService } from "../service/ledger.service";
import { CountryInfoService } from "app/master-data/basic/basic-service/country-info.service";
import { CustomerService } from "app/customer/customer.service";
import { SharedService } from "app/services/shared.service";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { VoucherInfoService } from "../service/voucher-info.service";
import { AccountingreportService } from "app/accounting-reports/accountingreport.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-receive-voucher",
  templateUrl: "./receive-voucher.component.html",
  styleUrls: ["./receive-voucher.component.scss"],
})
export class ReceiveVoucherComponent implements OnInit {
  selectedOption: string;
  customerIdPayment: number;
  customerAccountId: number;
  customerAccountId2: number;
  //currentBalance: number=0;
  isvalid: boolean = true;
  isBtnValid: boolean = false;
  currentBalance: number;
  currentBalance2: number;
  accountNo: string = "";
  accountId: number = 0;
  accountNoRecieve: string = "";
  //accountNoFund1: string = '';
  accountNoFund1: number = 0;
  accountNoFund2: string = "";
  totalCurrentBalance: number;
  customerInfo_cashFundTransfer1: any = "";
  customerInfo_cashFundTransfer2: any = "";

  isEdit: boolean = false;
  frmGroup: FormGroup;
  list: CashPosting[] = [];
  model: any = {};
  form: FormGroup;
  customerNameControl: FormControl = new FormControl("");
  formControl = new FormControl();
  suggestions: any[] = [];
  hideShow: string = "Show";
  //searchType: string = '';
  glAccountName: any;
  gLGenerateId: number = 0;
  TblStatus: string = "hide";
  subledgerStatus: string = "hide";
  SaveStatus: string = "hide";
  addButtonStatus: string = "Show";
  subledgerId: any = [];
  ledgerId: any = [];
  showTableBody: boolean = true;

  date: string;
  amount: number;
  drLrId: number;
  SubledgerId: number;
  crLrId: number;
  isposted: number;
  voucherTypeId: number;
  refNo: string;
  remarks: string;
  haveSubledger: number;
  AccountNoList: Observable<any[]>;
  AccountNoList1: Observable<any[]>;
  particularList: any[] = [];
  particularName: any = {};
  SubparticularName: any = {};
  CrparticularName: any = {};
  debitInfo: any = {};
  postVoucher: any = {};
  AccountSubLedgerList: Observable<any[]>;
  editingIndex: number = -1;
  constructor(
    private router: Router,
    private _service: CashPostingService,
    private _voucherService: VoucherInfoService,
    private _LedgerService: LedgerService,
    private _CountryInfoService: CountryInfoService,
    private _CustomerService: CustomerService,
    private changeDetectorRef: ChangeDetectorRef,
    private accountingreportService: AccountingreportService,
    private location: Location,
    private formBuilder: FormBuilder,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.paymentForm();
    this.loadLedgerList();
    this.loadCountryList();
    this.GetParticularLedgerForRecieveVoucher();
    this.GetMainLedgerForPaymentOrReceiveVouhcer();
    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((value) =>
          this._LedgerService.getAutocompleteSuggestions(value)
        )
      )
      .subscribe((data) => {
        this.suggestions = data;
      });
    this.hideShow = "Show";
  }

  selectedSuggestion: any = null;

  selectSuggestion(item: any) {
    this.formControl.setValue(item.bdGenerateCode);
    //this.suggestions = [];
    this.selectedSuggestion = item;
    this.hideShow = "Hide";
  }
  hideAfterSelectValue(value: any) {
    this.hideShow = value;
  }
  // selectSearchType(type: string) {
  //   this.searchType = type;
  // }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  LedgerList: Observable<any[]>;
  loadLedgerList() {
    this._LedgerService.getLedgerRelationlist().subscribe((res) => {
      this.LedgerList = of(res);
    });
  }

  countryList: Observable<any[]>;
  loadCountryList() {
    this._CountryInfoService.getlist().subscribe((res) => {
      this.countryList = of(res);
    });
  }
  formatNumber(value: string): string {
    const formattedValue = value
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
  }

  onsubmit() {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save this Contact Information!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Save Contact!",
    }).then((res) => {
      if (res.isConfirmed) {
        this.debitInfo.remarks = this.frmGroup.value.remarks;
        //this.model.TermsId = this.frmGroupTerms.value.TermsId;
        this.postVoucher.master = this.debitInfo;
        this.postVoucher.details = this.particularList;

       
        this._voucherService
          .SaveVoucher(this.postVoucher)
          .subscribe((res: number) => {
            if (res > 0) {
              this.ResetForm();
              this.shared.ShowAlert("Success", "Save Successfully", "success");
              this.postVoucher = {};
              this.particularList = [];
              this.debitInfo = {};
              this.TblStatus = "hide";
              this.SaveStatus = "hide";

              //this.router.navigate(['/accounting/payment-voucher']);
            } else {
              this.shared.ShowAlert("Warning", "Failed to Save", "warning");
              this.isEdit = false;
            }
          });
      }
    });
  }

  GetParticularLedgerForRecieveVoucher() {
    this._LedgerService
      .GetParticularLedgerForRecieveVoucher()
      .subscribe((res) => {
        this.AccountNoList = of(res);
      });
  }

  GetMainLedgerForPaymentOrReceiveVouhcer() {
    this._LedgerService
      .GetMainLedgerForPaymentOrReceiveVouhcer()
      .subscribe((res) => {
        this.AccountNoList1 = of(res);
      });
  }

  refreshAc() {
    this.currentBalance2 = 0;
    this.currentBalance = 0;
    this.customerInfo_cashFundTransfer1 = "";
    this.customerInfo_cashFundTransfer2 = "";
    this.customerIdPayment = 0;
    this.customerAccountId = 0;
    this.isBtnValid = false;
  }
  CheckBalance() {
    var balanceAmount = this.currentBalance.toString().replace(/,/g, "");
    var inputAmount = parseInt(
      this.frmGroup.value.voucherAmount.replace(/,/g, ""),
      10
    );

    if (inputAmount > parseInt(balanceAmount, 10)) {
      this.isvalid = false;
    } else {
      this.isvalid = true;
    }
  }

  voucherAmountValue(value: any) {
    var balanceAmount = this.totalCurrentBalance.toString().replace(/,/g, "");
    var inputAmount = parseInt(value.replace(/,/g, ""), 10);

    if (inputAmount > 0) {
      this.currentBalance = parseInt(balanceAmount) - inputAmount;
    } else {
      this.currentBalance = this.totalCurrentBalance;
    }
  }
  getSubLedgerList(id: number) {
    this._LedgerService.GetAllSubLedgerbyLrId(id).subscribe((res) => {
      this.AccountSubLedgerList = of(res);
    });
  }

  ResetForm() {
    this.frmGroup.reset();
  }

  paymentForm() {
    this.frmGroup = this.formBuilder.group({
      date: [""],
      amount: [0],
      crLrId: [0], //
      SubledgerId: [0],
      drLrId: [0],
      isposted: [0],
      voucherTypeId: [0],
      refNo: [""],
      remarks: [""],
      particularName: [""],
      SubparticularName: [""],
      CrparticularName: [""],
      particularListSl: [0],
      haveSubledger: [0],
    });
  }

  AddParticular() {
    if (
      this.frmGroup.value.drLrId === undefined ||
      this.frmGroup.value.drLrId == 0
    ) {
      this.shared.ShowAlert(
        "Warning",
        "Please Select Receive Account",
        "warning"
      );
      return false;
    }
    if (
      this.frmGroup.value.crLrId === undefined ||
      this.frmGroup.value.crLrId == 0
    ) {
      this.shared.ShowAlert(
        "Warning",
        "Please Select Particular Account",
        "warning"
      );
      return false;
    }

    if (
      this.frmGroup.value.amount === undefined ||
      this.frmGroup.value.amount < 0
    ) {
      this.shared.ShowAlert("Warning", "Please Insert Valid Amount", "warning");
      return false;
    }

    if (this.editingIndex === -1) {
      let index = this.particularList.findIndex(
        (obj) => obj.particularListSl == this.frmGroup.value.particularListSl
      );

      this.frmGroup.value.particularListSl = this.particularList.length + 1;
      this.model.crLrId = this.frmGroup.value.crLrId; //
      this.model.SubledgerId = this.frmGroup.value.SubledgerId;
      this.model.drLrId = this.frmGroup.value.drLrId;
      this.model.amount = this.frmGroup.value.amount;

      if (
        this.frmGroup.value.SubledgerId !== undefined &&
        this.frmGroup.value.SubledgerId !== 0
      ) {
        this.model.crLrId = this.frmGroup.value.SubledgerId; //
        debugger;

        this.AccountSubLedgerList.subscribe((res) => {
          const SubAccount = res.find(
            (item) => item.id == this.frmGroup.value.SubledgerId
          );

          this.frmGroup.value.SubparticularName =
            SubAccount?.subLedger?.accountName || "";
        });

        this.AccountNoList.subscribe((accountList) => {
          const selectedAccount = accountList.find(
            (item) => item.id == this.frmGroup.value.crLrId //
          );

          this.frmGroup.value.particularName =
            selectedAccount?.ledger?.accountName || "";
          this.particularList.splice(index, 0);
          this.particularList.push({
            id: this.model.crLrId, //
            particularName: this.frmGroup.value.particularName,
            SubparticularName: this.frmGroup.value.SubparticularName,
            drAmount: 0,
            crAmount: this.model.amount,
            trMode: 2,
          });
        });
      } else {
        this.AccountNoList.subscribe((accountList) => {
          const selectedAccount = accountList.find(
            (item) => item.id == this.frmGroup.value.crLrId //
          );
          this.frmGroup.value.particularName =
            selectedAccount?.ledger?.accountName || "";
          this.particularList.splice(index, 0);
          this.particularList.push({
            id: this.model.crLrId, //
            particularName: this.frmGroup.value.particularName,
            SubparticularName: "",
            drAmount: 0,
            crAmount: this.model.amount,
            trMode: 2,
          });
        });
      }

      this.AccountNoList1.subscribe((accountList) => {
        const selectedAccount = accountList.find(
          (item) => item.id == this.frmGroup.value.drLrId
        );
        this.frmGroup.value.CrparticularName =
          selectedAccount?.ledger?.accountName || "";
        this.particularList.splice(index, 0);
        this.debitInfo.drLrId = this.model.drLrId;
        this.debitInfo.particularName = this.frmGroup.value.CrparticularName;
        this.debitInfo.voucherTypeId = 1;
        this.debitInfo.amount = this.particularList.reduce(
          (total, item) => total + item.crAmount,
          0
        );

        this.TblStatus = "Show";
        this.SaveStatus = "Show";
      });
    } else {
      debugger;
      this.particularList[this.editingIndex] = {
        id: this.crLrId, //
        particularName: this.particularName,
        SubparticularName: this.SubparticularName,
        drAmount: 0,
        crAmount: this.amount,
        trMode: 2,
      };
      this.debitInfo.amount = this.particularList.reduce(
        (total, item) => total + item.crAmount,
        0
      );
      this.editingIndex = -1;
    }
  }

  onLedgerChange(Id: any): void {
    this.AccountNoList.subscribe((accountList) => {
      const selectedAccount = accountList.find(
        (item) => item.id == this.frmGroup.value.crLrId //
      );
      this.frmGroup.value.haveSubledger =
        selectedAccount?.ledger?.haveSubledger;
      if (this.frmGroup.value.haveSubledger == 1) {
        if (Id > 0 || Id != null || Id != "") {
          this.ledgerId = Id;
          this.frmGroup.patchValue({
            SubledgerId: 0,
          });
          this.getSubLedgerList(Id);
        }
        this.subledgerStatus = "Show";
        this.addButtonStatus = "hide";
      } else {
        this.subledgerStatus = "hide";
        this.addButtonStatus = "Show";
      }
    });
  }

  deleteAccount(index: any) {
    this.particularList.splice(index, 1);
    this.debitInfo.amount = this.particularList.reduce(
      (total, item) => total + item.crAmount,
      0
    );
    if (index === this.editingIndex) {
      this.editingIndex = -1;
    }

    if (index == 0) {
      this.debitInfo = [""];
      this.TblStatus = "hide";
      this.SaveStatus = "hide";
    }
  }

  editAccount(index: number) {
    this.editingIndex = index;
    this.amount = this.particularList[index].crAmount;
    this.particularName = this.particularList[index].particularName;
    this.SubparticularName = this.particularList[index].SubparticularName;
    this.crLrId = this.particularList[index].id; //
    this.subledgerId = this.particularList[index].subledgerId;
    this.debitInfo.amount = this.particularList.reduce(
      (total, item) => total + item.crAmount,
      0
    );
  }

  onSubLedgerChange(Id: any): void {
    if (Id > 0) {
      this.addButtonStatus = "Show";
    } else {
      this.addButtonStatus = "hide";
    }
  }

  goBack() {
    this.location.back();
  }
}
