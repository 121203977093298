
<div class="row " >
  <div class="col-6 mt-3">
    <h5 class="m-0 font-weight-bold text-primary">Accounting-Report</h5>
  </div>
  <div class="col-6 mt-3">
    <button class="btn btn-secondary pull-right" title="Go Back" (click)="goBack()">

      <i class="icon-arrow-left" style="font-size: 17px;"> </i>
    </button>
  </div>
</div>


<hr>

    <div class="col-12 row">

      <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <div class="card cuscard " (click)="onWaiting(5)" style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                <div class="black customIcon">
                  <i class="fa fa-print"></i>

                </div>
                <div class="black ml-2 mt-3">
                  <span>Chart Of Account</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <div class="card  cuscard" (click)="onWaiting(6)" style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                <div class="black customIcon">

                  <i class="fa fa-print"></i>
                </div>
                <div class="black ml-2 mt-3">
                  <span>Day Book</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <div class="card cuscard" (click)="onWaiting(1)" style="cursor: pointer; height: 100px;">
          <div class="card-content ">
            <div class="card-body py-0">
              <div class="media pb-1">
                <div class="black customIcon">
                  <i class="fa fa-print"></i>

                </div>
                <div class="black ml-2 mt-3">
                  <span>Cash Book</span>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-3 col-md-4 col-12 col-sm-12">
        <div class="card  cuscard" (click)="onWaiting(2)" style="cursor: pointer;background-color:#526BA0 ;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-4">
                <div class="media-body  text-left mt-4 pl-4">
                  <i class="fa fa-print font-large-2"></i>

                </div>
                <div class="media-right  text-right mt-4 ">
                  <span style="font-size: 16px;">Bank Book</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div> -->









      <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <a routerLink="/accounting-reports/bank-book">
        <div class="card  cuscard" style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                <div class="black  customIcon">
                  <i class="fa fa-print"></i>

                </div>
                <div class="black ml-2 mt-3">
                  <span>Bank Book</span>
                </div>
              </div>
            </div>

          </div>
        </div>
        </a>
      </div>

      <!-- <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <div class="card cuscard" (click)="onWaiting(4)" style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                  <div class="black customIcon">
                      <i class="fa fa-print"></i>
                  </div>
                  <div class="black ml-2 mt-2">
                      <span>Sub-Ledger Book</span>
                  </div>
              </div>
          </div>

          </div>
        </div>
      </div> -->
    </div>

<hr>
    <!-- ------------------------------------------------------------- -->

    <div class="col-12 row">
      <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <div class="card cuscard " (click)="onWaiting(3)" style="cursor: pointer; height: 100px;">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1 align-self-center">
                <div class="black customIcon">
                  <i class="fa fa-print"></i>

                </div>
                <div class="black ml-2 mt-3">
                  <span>Ledger Book</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12 col-sm-12">
        <a routerLink="/accounting-reports/trial-balance-report">
          <div class="card cuscard"  style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon">

                    <i class="fa fa-print"></i>

                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Trial Balance</span>

                  </div>

                </div>
              </div>

            </div>
          </div>

        </a>

      </div>
      <div class="col-lg-3 col-md-3 col-12 col-sm-12">


        <a routerLink="/accounting-reports/profit-loss-report">
          <div class="card cuscard"  style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon">

                    <i class="fa fa-print"></i>

                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Income Statement</span>

                  </div>

                </div>
              </div>

            </div>
          </div>

        </a>

      </div>
      <div class="col-lg-3 col-md-3 col-12 col-sm-12">
        <a routerLink="/accounting-reports/balannce-sheet-report">
          <div class="card cuscard"  style="cursor: pointer; height: 100px;">
            <div class="card-content">
              <div class="card-body py-0">
                <div class="media pb-1 align-self-center">
                  <div class="black customIcon">

                    <i class="fa fa-print"></i>

                  </div>
                  <div class="black ml-2 mt-3">
                    <span>Financial Position </span>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </a>

      </div>




    </div>


<hr>

 <!-- ------------------------------------------------------------- -->

 <div class="col-12 row">

  <div class="col-lg-3 col-md-6 col-12 col-sm-12">
    <a routerLink="/accounting-reports/group-summary">
      <div class="card cuscard"  style="cursor: pointer; height: 100px;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1 align-self-center">
              <div class="black customIcon">

                <i class="fa fa-print"></i>

              </div>
              <div class="black ml-2 mt-3">
                <span>Group Summary</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </a>

  </div>
  <div class="col-lg-3 col-md-6 col-12 col-sm-12">
    <a routerLink="/accounting-reports/sub-ledger-book">
      <div class="card cuscard"  style="cursor: pointer; height: 100px;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1 align-self-center">
              <div class="black customIcon">

                <i class="fa fa-print"></i>

              </div>
              <div class="black ml-2 mt-3">
                <span>Sub-ledger-book</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </a>

  </div>
<div class="col-lg-3 col-md-6 col-12 col-sm-12">
  <a routerLink="/accounting-reports/income-expense-report">
    <div class="card cuscard" style="cursor: pointer; height: 100px;">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1 align-self-center">
            <div class="black customIcon">

              <i class="fa fa-print"></i>

            </div>
            <div class="black ml-2 mt-3">
              <span>Income-Expense-Report</span>
            </div>
          </div>
        </div>

      </div>
    </div>

  </a>

</div>

  <!-- <div class="col-lg-3 col-md-3 col-12 col-sm-12">
    <a routerLink="/accounting-reports/statement-report">
      <div class="card cuscard"  style="cursor: pointer; height: 100px;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1 align-self-center">
              <div class="black customIcon">

                <i class="fa fa-print"></i>

              </div>
              <div class="black ml-2 mt-2">
                <span>Statement Report</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </a>

  </div> -->



  <!-- <div class="col-lg-3 col-md-3 col-12 col-sm-12">
    <a routerLink="/accounting-reports/account-statment-report">
      <div class="card cuscard"  style="cursor: pointer; height: 100px;">
        <div class="card-content">
          <div class="card-body py-0">
            <div class="media pb-1 align-self-center">
              <div class="black customIcon">

                <i class="fa fa-print"></i>

              </div>
              <div class="black ml-2 mt-1">
                <span>Account-Statement Report</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </a>

  </div> -->

</div>


<hr>

